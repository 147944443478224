import axios from 'axios';
import { appointmentServiceApi } from "../../../config/config";
// import CardViewMock from "../../../mock/card-view.json";
import { setupInterceptorsTo } from "../interceptors/axios.interceptos";
const axiosInstance = setupInterceptorsTo(axios.create());

class ReportService {

    async getPayment({ partyId,Date }: any): Promise<any> {
        try {
            const { data } = await axiosInstance.get(`${appointmentServiceApi.baseUrl}/api/v1/report/online-payment/summary/report`, {
                params: {
                    partyId:partyId,
                    date: Date
               
                }
            });
            return data;
        } catch (error) {
            console.log('Error in Get PaymentDetail',error)
            return {
                data: []
            }
        }
    }
    async getWhatsapp({ partyId,Date }: any): Promise<any> {
        try {
            const { data } = await axiosInstance.get(`${appointmentServiceApi.baseUrl}/api/v1/report/whats-app/summary/report`, {
                params: {
                    partyId:partyId,
                    date: Date
               
                }
            });
            return data;
        } catch (error) {
            console.log('Error in Get WhatsappReport',error)
            return {
                data: []
            }
        }
    }

    
}
const reportService = new ReportService();
export default reportService;