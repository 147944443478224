import React, { useContext } from 'react';
import {Box, Grid,Stack, TableContainer, Typography, Paper, Table, TableRow, TableCell, TableBody, Button, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ApptContext } from '../../contexts/appointment.context';
import withHooks from '../../../../utils/withHooks';
import { useTheme } from '@emotion/react';
import { PracticeSesContext } from '../../../practice-session/contexts/practice-session.context';
import { useLocation } from 'react-router-dom';
import reportService from '../../../../services/http/reports/reports.service';
import { getCookie } from 'typescript-cookie';
import jwtDecode from 'jwt-decode';


interface PaymentDetailsData {
  apptId: string;
  phone: string;
  fees: string;
  referenceId: string;
  status: string;
  paymentMode: string;
  message: string;
  paidAt: string;
}

class OnlinePaymentDetails extends React.Component<any, any> {
  constructor(props:any){
    super(props);
    this.state={
      paymentDetails: {},
    }
  }
   componentDidMount() {
   
    const queryParams = new URLSearchParams(this.props.location.search);
    
    const date=  queryParams.get('date');
    const token = getCookie('SCHEDULA_TOKEN');
    if (token) {
      const decodedToken: any = jwtDecode(token);
      const partyId = decodedToken.partyId;
      this.setState({ partyId }, () => {
        this.fetchOnlineReport(this.state.partyId,date); 
      });
    }
    
    
  }
  fetchOnlineReport = async (partyId:any,date:any) => {
    try {
       
       const queryParams = new URLSearchParams(this.props.location.search);
       const apptId = Number(queryParams.get('apptId'));

       
       const paymentReport = await reportService.getPayment({ partyId, Date: date });
       console.log("apptId",typeof apptId)
        paymentReport.forEach((data:any)=>{
          if(data.apptId===apptId)
            {
              this.setState({paymentDetails:data})
            }
        })

    } catch (error) {
        console.error('Error fetching template:', error);
    }
  }
  capitalizeFirstLetter = (str: string) => {
    if(str==="utr")
      {
        return str.toUpperCase();
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    
  };

  handleWhatsAppClick = () => {
    const number=this.state.paymentDetails.phone.slice(1)
    const whatsappUrl = `https://wa.me/+91${number}`;
    window.open(whatsappUrl, '_blank');
  }

  render() {
    const { paymentDetails } = this.state;
    const {isMd}=this.props;
    const paymentDetailKeys = Object.keys(paymentDetails);
    
    return (
      <div>
        <Button startIcon={<ArrowBackIosIcon />} onClick={() => window.history.back()}>Back</Button>
        <Stack sx={{ marginTop: '20px', padding: '20px' }}>
          <Typography variant="h5" style={{ marginBottom: '15px' }}>
            Payment Details
          </Typography>
          <Paper elevation={2} style={{ padding: '20px' }}>
          <TableContainer>
  <Table sx={{ minWidth: 650 }} aria-label="payment details table">
    <TableBody>
      {paymentDetailKeys.map((key, index) => (
        <TableRow key={key} sx={{ bgcolor: index % 2 === 0 ? 'grey.200' : 'white' }}>
          <TableCell>
            {isMd ? (
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <strong>{this.capitalizeFirstLetter(key)}</strong>
                </Grid>
                <Grid item xs={9}>
                  {paymentDetails[key as keyof PaymentDetailsData]}
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container spacing={16}>
                <Grid item xs={3}>
                    <strong>{this.capitalizeFirstLetter(key)}</strong>
                </Grid>
                <Grid item xs={9}>
                    <Box display="block" justifyContent="center">
                    {paymentDetails[key as keyof PaymentDetailsData]}
                    </Box>
                </Grid>
                </Grid>
              </>
            )}
          </TableCell>
        </TableRow>
      ))}
      <TableRow sx={isMd ? { bgcolor: 'grey.200' } : { bgcolor: 'grey.200' }}>
        <TableCell>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <strong>WhatsApp</strong>
            </Grid>
            <Grid item xs={9}>
              <Button onClick={this.handleWhatsAppClick}>
                <WhatsAppIcon style={{ color: 'green' }} />
              </Button>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
</TableContainer>

</Paper>



        </Stack>
      </div>
    );
  }
}

export default withHooks(OnlinePaymentDetails, [
    ['theme', useTheme],
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
    ['apptContext', useContext, [ApptContext]],
    ['practiceSesContext', useContext, [PracticeSesContext]],
    ['location', useLocation]
])
