import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button } from "@mui/material";
import { AiFillFolder } from "react-icons/ai";
import { FaFolderOpen, FaRegCreditCard, FaWhatsapp } from "react-icons/fa";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

interface YourClassComponentState {
  expandedOuter: string | false;
  expandedInner: string | false;
  activeButton: string | null;
  activeAccordionOuter: string | null;
  activeAccordionInner: string | null;
}

export default class SideExpandMenuBar extends React.Component<any, any> {
  constructor(props: YourClassComponentState) {
    super(props);
    this.state = {
      expandedOuter: false,
      expandedInner: false,
    };
  }

  handleChangeOuter = (isExpanded: boolean) => {
    this.setState({ expandedOuter: isExpanded ? "panel2" : false });
    if (!isExpanded) {
      this.setState({ expandedInner: false });
    }
    this.setState({ activeAccordionOuter: isExpanded ? "Reports" : null });
  };

  handleChangeInner = (isExpanded: boolean) => {
    this.setState({ expandedInner: isExpanded ? "panel1" : false });
    this.setState({ activeAccordionInner: isExpanded ? "Whats App" : null });
  };

  handleButtonClick = (buttonName: string) => {
    this.setState({ activeButton: buttonName });
  };

  render() {
    const { expandedOuter, expandedInner, activeButton, activeAccordionOuter, activeAccordionInner } = this.state;
    return (
      <div>
        <Accordion
          expanded={expandedOuter === "panel2"}
          onChange={(event, isExpanded) => this.handleChangeOuter(isExpanded)}
          disableGutters
          sx={{
            border: "0",
            boxShadow: "none",
            backgroundColor: "transparent",
            fontSize: "14px",
            paddingLeft: "2px",
            color: activeAccordionOuter === "Reports" ? "#fff" : "neutral.400",
            whiteSpace: "nowrap",
          }}
        >
          <AccordionSummary
            id="panel1-header"
            aria-controls="panel1-content"
            sx={{
              backgroundColor:
                activeAccordionOuter === "Reports" ||
                activeAccordionInner === "Whats App"
                  ? "#1c1b1b"
                  : "",
              borderRadius: "8px",
              "&.expanded": {
                marginTop: "10px",
              },
            }}
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  transform:
                    activeAccordionOuter === "Reports"
                      ? "rotate(180deg)"
                      : "rotate(90deg)",
                }}
              />
            }
          >
            <FaFolderOpen size="17" />
            <Typography sx={{ marginLeft: "1rem" }}>Reports</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0", paddingLeft: "5px" }}>
            <Link to="reports/payment-details">
              <Button
                onClick={() => this.handleButtonClick("Payment Details")}
                sx={{
                  padding: "4px 52% 4px 20px",
                  color: activeButton === "Payment Details" ? "#fff" : "neutral.400",
                  backgroundColor: "transparent",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "#282f3d",
                  },
                }}
                startIcon={<FaRegCreditCard size="17" />}
              >
                Payment Details
              </Button>
            </Link>
            <br />
            <Link to="reports/sms">
              <Button
                onClick={() => this.handleButtonClick("Sms")}
                sx={{
                  padding: "4px 84% 4px 20px",
                  color: activeButton === "Sms" ? "#fff" : "neutral.400",
                  backgroundColor: "transparent",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "#282f3d",
                  },
                }}
                startIcon={<BsFillFileEarmarkTextFill size="17" />}
              >
                Sms
              </Button>
            </Link>
            <Accordion
              expanded={expandedInner === "panel1"}
              onChange={(event, isExpanded) => this.handleChangeInner(isExpanded)}
              disableGutters
              sx={{
                border: "0",
                boxShadow: "none",
                backgroundColor: "transparent",
                fontSize: "14px",
                color: activeAccordionInner === "Whats App" ? "#fff" : "neutral.400",
                whiteSpace: "nowrap",
              }}
            >
              <AccordionSummary
                id="panel1-header"
                aria-controls="panel1-content"
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      transform:
                        activeAccordionInner === "Whats App"
                          ? "rotate(180deg)"
                          : "rotate(90deg)",
                    }}
                  />
                }
              >
                <FaFolderOpen size="17" />
                <Typography sx={{ marginLeft: "1rem" }}>Whats App</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0", paddingLeft: "1rem" }}>
                <Link to="reports/all-messages">
                  <Button
                    onClick={() => this.handleButtonClick("All Messages")}
                    sx={{
                      padding: "4px 55% 4px 20px",
                      marginLeft: "4px",
                      backgroundColor: "transparent",
                      color: activeButton === "All Messages" ? "#fff" : "neutral.400",
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: "#282f3d",
                      },
                    }}
                    startIcon={<FaWhatsapp size="17" />}
                  >
                    All Messages
                  </Button>
                </Link>
                <br />
                <Link to="reports/online-payment-link-messages">
                  <Button
                    startIcon={<AiFillFolder size="17px" />}
                    onClick={() => this.handleButtonClick("Online Payment Link Messages")}
                    sx={{
                      padding: "4px 4px 4px 20px",
                      color: activeButton === "Online Payment Link Messages" ? "#fff" : "neutral.400",
                      marginLeft: "4px",
                      backgroundColor: "transparent",
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: "#282f3d",
                      },
                    }}
                  >
                    Online Payment Link Messages
                  </Button>
                </Link>
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}


 