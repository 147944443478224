import { useContext } from "react";
import { Box, Card, Grid, Typography, CardContent } from '@mui/material';
import { ApptCardViewContext } from '../contexts/card-view.context';

type MainCardProps = {
}

export default function MainCard(props: MainCardProps) {
    const cardViewCnxt = useContext(ApptCardViewContext);
    const { cardView }: any = cardViewCnxt;
    const { orgName, area, queueType, date, totalApppointments, totalInQueue, totalCheckedOut, totalNoShows } = cardView;

    return (
        <Grid container>
            <Grid item xs={12} md={9} sx={{ mt: '1rem' }}>
                <Card sx={{ padding: '0px' }}>
                    <Grid container spacing={2} sx={{ pt: '0px !important' }}>
                        <Grid item xs={12} md={12}>
                            <Box sx={{
                                padding: '1rem !important'
                            }}>
                                <Typography variant="h6" component="span">
                                    {orgName}, {area}
                                </Typography>
                                <Typography variant="caption" component="div">
                                    {date} ({queueType})
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mb: '1rem' }}>
                        <Grid item xs={3} md={5} lg={3} sm container sx={{ pt: '0px !important' }}>
                            <CardContent sx={{
                                flex: '1 0 auto',
                                justifyContent: 'center',
                                padding: '0px 0px 0rem 1rem !important',
                                lineHeight: 0
                            }}>
                                <Typography variant="caption" color="text.secondary" component="div">
                                    Appointments
                                </Typography>
                                <Typography variant="subtitle1" display="block" sx={{ margin: 0 }}>
                                    {totalApppointments}
                                </Typography>
                            </CardContent>
                        </Grid>
                        <Grid item xs={3} md={5} lg={3} sm container sx={{ pt: '0px !important' }}>
                            <CardContent sx={{
                                flex: '1 0 auto',
                                justifyContent: 'center',
                                padding: '0px 0px 1rem 1rem !important',
                                lineHeight: 0
                            }}>
                                <Typography variant="caption" color="text.secondary" component="div">
                                    In-Queue
                                </Typography>
                                <Typography variant="subtitle1" display="block" sx={{ margin: 0 }}>
                                    {totalInQueue}
                                </Typography>
                            </CardContent>
                        </Grid>
                        <Grid item xs={3} md={5} lg={3} sm container sx={{ pt: '0px !important' }}>
                            <CardContent sx={{
                                flex: '1 0 auto',
                                justifyContent: 'center',
                                padding: '0px 0px 0rem 1rem !important',
                                lineHeight: 0
                            }}>
                                <Typography variant="caption" color="text.secondary" component="div">
                                    Checked-Out
                                </Typography>
                                <Typography variant="subtitle1" display="block" sx={{ margin: 0 }}>
                                    {totalCheckedOut}
                                </Typography>
                            </CardContent>
                        </Grid>
                        <Grid item xs={3} md={5} lg={3} sm container sx={{ pt: '0px !important' }}>
                            <CardContent sx={{
                                flex: '1 0 auto',
                                justifyContent: 'center',
                                padding: '0px 0px 1rem 1rem !important',
                                lineHeight: 0
                            }}>
                                <Typography variant="caption" color="text.secondary" component="div">
                                    No-Shows
                                </Typography>
                                <Typography variant="subtitle1" display="block" sx={{ margin: 0 }}>
                                    {totalNoShows}
                                </Typography>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}
