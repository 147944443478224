import { Component, ReactNode, useContext } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { common } from "../../config/config";
import { AuthContext } from "../../contexts/auth-context";
import useAuth from "../../hooks/use-auth";
import withHooks from "../../utils/withHooks";

class Login extends Component<any, any> {

    handleLogin = () => {
        this.props.authContext.login().then(() => {
            this.props.navigate("/appointments");
        });
    };

    render(): ReactNode {
        return <>
            {
                this.props.authContext && this.props.authContext.isLogin === false && window.location.replace(common.schedulaLegacyAppUrl as string)
            }   
        </>
    }

}

export default withHooks(Login, [
    ['searchParams', useSearchParams],
    ['auth', useAuth],
    ['location', useLocation],
    ['navigate', useNavigate],
    ['authContext', useContext, [AuthContext]]
]);