import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog, DialogContent, useMediaQuery,
    useTheme
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import withHooks from '../../../../utils/withHooks';
import AddAppointmentForm from './AddAppointmentFormComp';
import { ApptContext } from '../../contexts/appointment.context';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AddAppointmentModal extends React.Component<any, any, {}> {

    state: any;


    constructor(props: any) {
        super(props);

        this.state = {
            open: props.open ?? false,
        }
    }

    get apptContext() {
        return this.props.apptContext;
    }

    render(): React.ReactNode {
        const { setApptModal, openAddApptModal } = this.apptContext;
        return <>
            <Dialog
                fullScreen={this.props.isMd}
                open={openAddApptModal}
                TransitionComponent={Transition}
            >
                <AppBar sx={{
                    width: `${!this.props.isMd ? '500px' : 'auto'}`,
                    position: 'relative',
                    background: 'none',
                    boxShadow: 'none !important',
                    color: 'neutral.900',
                }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            Add Appointment
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            onClick={() => setApptModal(!openAddApptModal)}

                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <AddAppointmentForm />
                </DialogContent>
            </Dialog>
        </>
    }
}

export default withHooks(AddAppointmentModal, [
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
    ['apptContext', React.useContext, [ApptContext]],
    ['theme', useTheme]
]);