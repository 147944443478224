import React, { useEffect, useContext, useState } from "react"
import { Box, Typography, Container, Card, Grid, CardContent, Stack, Button } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import callLogSerive from "../../../services/http/call-logs/call-logs.services";
import { useLocation, useNavigate } from "react-router-dom";
import CallLogsHeader from "../header/header";
// import { getAttributeFromToken } from "../../../hooks/use-auth";
import { ApptCallLogsContext } from "../context/call-logs.context";
import { addHours, addMinutes, format,isSameDay } from "date-fns";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { callLogsNewApiService } from "../../../config/config";


type CallLog = {
    id: number;
    callSid: string;
    callerPhoneNumber: string;
    createdTime: string;
    apptReqStatus?: string;
    apptNumber?: string;
  };

export default function CallLogsDetails() {
    const navigation = useNavigate();
    const callLogCntx: any = useContext(ApptCallLogsContext);
    // const { callHistory, date, setCallHistory, setDate } = callLogCntx;
    const {  date, setCallHistory, setDate } = callLogCntx;
    const { state } = useLocation();
    const [apiData, setApiData] = useState<CallLog[]>([]);
    useEffect(() => {
        getCallLogsDetails();
        setDate(state?.date ?? new Date());
        return () => { }
        // eslint-disable-next-line
    }, []);

    const formatTime = (createdTime: string) => {
        // Function to format the time and adjust for the time difference
        const adjustedTime = addMinutes(addHours(new Date(createdTime), -5), -30); // Subtract 5 hours and 30 minutes
        return format(adjustedTime, "hh:mm a");
      };
    /**
     * Get call logs history for the selected session
     */
    const getCallLogsDetails = async () => {
        try {
            // const partyId = getAttributeFromToken('partyId')
            // const { practiceSessionId, logType } = state;
            // const resp = await callLogSerive.getCallLogsDetails(partyId, format(state?.date, "yyyy-MM-dd"), practiceSessionId, logType);
            // setCallHistory(resp);


            const response = await fetch(`${callLogsNewApiService.baseUrl}/v5/call-logs`);
            if (!response.ok) {
            throw new Error("Network response was not ok");
            }
    
            const data: CallLog[] = await response.json();
            setApiData(data);
        } catch (error) {
            setCallHistory([]);
            console.error("Erro in getCallLogsDetails", error);
        }
    }


          //   count the number appointment confirmed and pending
        const filteredData = apiData.filter((his) => {
        // Convert his.createdTime to the format of your date and adjust for the time zone
        const formattedCreatedTime = format(
          addMinutes(addHours(new Date(his.createdTime), -5), -30),
          "yyyy-MM-dd"
        );
    
        // Convert selectedDate to the same format and adjust for time zone
        const formattedDate = format(new Date(date), "yyyy-MM-dd");
        return isSameDay(new Date(formattedCreatedTime), new Date(formattedDate));
      });
    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 2,
                    '.MuiContainer-root': {
                        margin: 0
                    }
                }}>
                <Container maxWidth="xl">
                    <CallLogsHeader header="Call History" type="call-details" />

                    <Grid container>
                        <Grid item xs={12} md={9} sx={{ mt: '.5rem' }}>
                            <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                                <Typography variant="subtitle1" sx={{ marginTop: "0px !important", textTransform: "capitalize" }}>
                                    {state?.logType}
                                </Typography>
                                <Button startIcon={<ArrowBackIcon />} onClick={() => navigation("/call-logs-wip", { state: { date } })}>
                                    Back
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>

                    {
                        filteredData?.length ? (<React.Fragment>
                            {
                                filteredData
                                .slice()
                                .reverse()
                                .map((his: any, indx: number) => {
                                    return (
                                        <Grid container key={indx}>
                                            <Grid item xs={12} md={9} sx={{ mt: '.5rem' }}>
                                                <Card sx={{ padding: '0px' }}>
                                                    <Grid container spacing={2} sx={{ pt: '0px !important' }}>
                                                        <Grid item xs={12} md={12}>
                                                            <Stack
                                                                direction={"row"}
                                                                alignItems="center"
                                                                sx={{ padding: '1rem 1rem 0.5rem 1rem !important' }}
                                                            >
                                                                <WhatsAppIcon style={{ color: "#ffffff", background: "#008000", padding: "2px", borderRadius: "4px" }} />
                                                                <Typography variant="h6" component="span" sx={{ paddingInlineStart: "5px" }}>
                                                                    {his?.callerPhoneNumber}
                                                                </Typography>
                                                                <Typography variant="caption" component="span" style={{ 'textTransform': "capitalize", paddingInlineStart: "5px" }}>
                                                                    ({formatTime(his?.createdTime)})
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        state?.logCategory !== "Others" && <Grid container sx={{ mb: '.75rem' }}>
                                                            <Grid item xs={6} md={6} lg={6} sm container
                                                                sx={{ pt: '0px !important' }}
                                                            >
                                                                <CardContent
                                                                    sx={{
                                                                        flex: '1 0 auto',
                                                                        justifyContent: 'center',
                                                                        padding: '0px 1rem 0rem 1rem !important',
                                                                        lineHeight: 0
                                                                    }}
                                                                >
                                                                    <Stack spacing={1}>
                                                                        <Typography variant="caption" color="text.secondary" component="div">
                                                                            Request Status
                                                                        </Typography>
                                                                        <Typography variant="subtitle1" sx={{ marginTop: "0px !important", textTransform: "capitalize", color: `${his?.apptReqStatus?.toLowerCase() !== "confirmed" ? "red" : ""}` }}>
                                                                            {his?.apptReqStatus?.toLowerCase() === "confirmed" ? his?.apptReqStatus : 'Not Confirmed'}
                                                                        </Typography>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Grid>
                                                            <Grid item xs={6} md={6} lg={6} sm container
                                                                sx={{ pt: '0px !important' }}
                                                            >
                                                                <CardContent
                                                                    sx={{
                                                                        flex: '1 0 auto',
                                                                        justifyContent: 'center',
                                                                        padding: '0px 1rem 0rem 1rem !important',
                                                                        lineHeight: 0
                                                                    }}
                                                                >
                                                                    <Stack spacing={1}>
                                                                        <Typography variant="caption" color="text.secondary" component="div">
                                                                            Message
                                                                        </Typography>
                                                                        <Typography variant="subtitle1" sx={{ marginTop: "0px !important", textTransform: "capitalize" }}>
                                                                             Before_booking_begin
                                                                            {his?.apptReqStatus?.toLowerCase() === "confirmed" ? `Appt number ${his?.apptNumber}` : his?.apptReqStatus}
                                                                        </Typography>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </React.Fragment>) : (
                            <Typography variant="subtitle1" component="div" textAlign={"center"} sx={{ my: 2 }}>
                                No Data
                            </Typography>
                        )
                    }

                </Container>
            </Box >
        </React.Fragment >
    )
}