import CloseIcon from '@mui/icons-material/Close';
import {
    Button, Dialog, DialogActions, DialogContent, useMediaQuery,
    useTheme
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import React, { useContext } from 'react';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import practiceSessionService from '../../../../services/http/practice-sessions/practice-session.service';
import withHooks from '../../../../utils/withHooks';
import { ApptContext } from '../../../appointments/contexts/appointment.context';
import { PracticeSesContext } from '../../../practice-session/contexts/practice-session.context';
import SlotListCard from '../SlotListCard';
import SlotListCardSkeleton from '../SlotListCardSkeleton';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ManageSlotModal extends React.Component<any, any, {}> {

    state: any;

    constructor(props: any) {
        super(props);
        this.state = {
            confirmDialog: false
        }
    }

    get slots() {
        return this.props.practiceSesContext.selectedPracticeSession?.slots || [];
    }

    get practiceSesContext() {
        return this.props.practiceSesContext;
    }

    onToggleSlot(slot: any, slotIdx: number) {
        this.practiceSesContext.setSelectedPracticeSession({
            ...this.practiceSesContext.selectedPracticeSession,
            slots: this.slots.map((s: any, i: number) => ({ ...s, status: slotIdx === i ? !slot.status : s.status }))
        });
    }

    changedSlots() {
        return (this.slots || []).filter((s: any) => s?.original?.status !== s.status);
    }

    closeModal = () => {
        this.resetSlots();
        this.props.practiceSesContext.setContextState({ isOpenManageSlotModal: false })
    }

    saveMangeSlots = async () => {
        const request = {
            slots: this.changedSlots(),
            date: format(this.props.apptContext.filter.scheduleOn, 'yyyy-MM-dd'),
            sessionId: this.props.apptContext.filter.practiceSessionId,
            practiceSession: this.props.practiceSesContext.selectedPracticeSession
        }
        await practiceSessionService.saveManageSlots(request);

        const { practiceSessionId } = this.practiceSesContext.selectedPracticeSession;

        const { data } = await practiceSessionService.getSlots({
            practiceSessionId,
            date: request.date
        });
        await this.props.practiceSesContext.setSlots(practiceSessionId, data);

        this.props.practiceSesContext.setContextState({ isOpenManageSlotModal: false });
    }

    resetSlots() {
        if (!this.slots) return;
        this.practiceSesContext.setSelectedPracticeSession({
            ...this.practiceSesContext.selectedPracticeSession,
            slots: this.slots.map((s: any) => ({ ...s, status: s.original.status }))
        });
    }

    render(): React.ReactNode {
        return <>
            <Dialog
                fullScreen={this.props.isMd}
                fullWidth={true}
                open={this.props.open}
                maxWidth={'sm'}
                TransitionComponent={Transition}
            >
                <AppBar sx={{
                    position: 'relative',
                    background: 'none',
                    boxShadow: 'none !important',
                    color: 'neutral.900',
                }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Manage Slots
                        </Typography>

                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            onClick={() => this.closeModal()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <DialogContent>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {
                            !this.practiceSesContext.isSlotLoading && this.slots && this.slots.map((slot: any, index: number) => (
                                <SlotListCard key={index} {...slot} onToggleSlot={() => {
                                    this.onToggleSlot(slot, index)
                                }} />
                            ))
                        }
                        {
                            this.practiceSesContext.isSlotLoading
                                ? [1, 2, 3, 4, 5].map((key: number) => (<SlotListCardSkeleton key={key}/>))
                                : ''
                        }
                    </List>
                    <DialogActions>
                        <Button size="small" variant="outlined" onClick={() => this.closeModal()}>Cancel</Button>
                        <Button size="small" variant="outlined" onClick={() => {
                            const isSlotChanged = this.changedSlots().length > 0
                            this.setState({ confirmDialog: isSlotChanged })
                            this.props.practiceSesContext.setContextState({ isOpenManageSlotModal: !!isSlotChanged });
                        }}>Save</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            <ConfirmDialog
                title={`Manage Slots`}
                body="Would you like to save the changes?"
                open={this.state.confirmDialog}
                buttonProps={
                    [{
                        label: 'No, Thanks'
                    },
                    {
                        label: 'Ok'
                    }]
                }
                onClose={() => {
                    this.resetSlots();
                    this.setState({ confirmDialog: false });
                    this.props.practiceSesContext.setContextState({ isOpenManageSlotModal: false });
                }}
                confirmCallback={() => {
                    this.setState({ confirmDialog: false });
                    this.saveMangeSlots()
                    window.location.reload();



                }} />
        </>
    }
}

export default withHooks(ManageSlotModal, [
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
    ['practiceSesContext', useContext, [PracticeSesContext]],
    ['apptContext', useContext, [ApptContext]],
    ['theme', useTheme]
]);
