import { useContext } from 'react';
import { Typography, Grid, Stack, useMediaQuery } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { format } from "date-fns";
import { ApptCallLogsContext } from '../context/call-logs.context';
import { getAttributeFromToken } from '../../../hooks/use-auth';
import callLogSerive from '../../../services/http/call-logs/call-logs.services';
import { useLocation } from 'react-router-dom';

interface CallLogsHeaderProps {
    header: string,
    type: string
}

export default function CallLogsHeader(props: CallLogsHeaderProps) {
    const { header, type } = props;
    const isMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
    const callLogCntx: any = useContext(ApptCallLogsContext);
    const { date, setDate, setSessionCallLogs, setCallHistory } = callLogCntx;
    const { state } = useLocation();

    /**
     * Filter date
     * @param selectedDate 
     */
    const onChangeDate = (selectedDate: any) => {
        try {
            setDate(selectedDate);
            if (type === "call-summary") getSessionCallLogs(selectedDate);
            if (type === "call-details") getCallLogsDetails(selectedDate);

        } catch (error) {
            console.error("Error in onChangeDate", error);
        }
    }

    /**
     * Get call log details for the selected date
     */
    const getSessionCallLogs = async (selectedDate: any) => {
        try {
            const partyId = getAttributeFromToken('partyId')
            const resp = await callLogSerive.getCallLogs(partyId, format(selectedDate, "yyyy-MM-dd"));
            setSessionCallLogs(resp);
        } catch (error) {
            console.error("Erron in getSessionCallLogs", error);
            setCallHistory([]);
        }
    }


    /**
     * Get call logs history for the selected session
     * @param selectedDate 
     */
    const getCallLogsDetails = async (selectedDate: any) => {
        try {
            const partyId = getAttributeFromToken('partyId')
            const { practiceSessionId, logType } = state;
            const resp = await callLogSerive.getCallLogsDetails(partyId, format(selectedDate, "yyyy-MM-dd"), practiceSessionId, logType);
            setCallHistory(resp);
        } catch (error) {
            console.error("Erro in getCallLogsDetails", error);
            setCallHistory([]);
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} md={9}>
                <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ '.MuiInputBase-root': { borderColor: "#E5E7EB !important", boxShadow: "none !important" } }}>
                    <Typography variant={isMd ? 'h6' : 'h5'} component="div" sx={{ flexGrow: 1, mt: 'auto', mb: 'auto' }}>
                        {header}
                    </Typography>
                    <DemoItem label="">
                        <MobileDatePicker
                            sx={{ 'input': { padding: "5%", maxWidth: "110px" } }}
                            closeOnSelect={true}
                            value={date}
                            maxDate={new Date()}
                            onChange={(date) => onChangeDate(date)}
                            format="dd-MM-yyyy"
                        />
                    </DemoItem>
                </Stack>
            </Grid>
        </Grid>
    )
}