import React, {  ChangeEvent, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getCookie } from 'typescript-cookie';
import jwtDecode from 'jwt-decode';
import campaignService from '../../../../services/http/campaign/bulk-whatsapp.services';
import { PracticeSesContext } from '../../../practice-session/contexts/practice-session.context';
import withHooks from '../../../../utils/withHooks';


interface Props {}
const defaultTheme = createTheme();

class BulkWhatsApp extends React.Component<any, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      phoneNumbers:'',
      phoneNumber:[],
      option: '',
      message: '',
      partyId:null,
      phoneNumberError: '',
      templateError:'',
      loader: false, 
      

    };
  }
  async componentDidMount() {   
    const token = getCookie('SCHEDULA_TOKEN');
    if (token) {
      const decodedToken: any = jwtDecode(token);
      const partyId = decodedToken.partyId;
      this.setState({ partyId }, () => {
        this.fetchTemplateData(this.state.partyId); 
      });
    }
    await this.practiceSesContext.loadPracticeSessions();
}

get practiceSesContext() {
  return this.props.practiceSesContext;
}
sendMessage = async () => {
  const { phoneNumber } = this.state;
  try {
    const { practiceSessions } = this.props.practiceSesContext
      const payload = {
        
          "phoneNumbers": phoneNumber,
          "authAssignmentId":practiceSessions[0].id,
          "sessionId":practiceSessions[0].practiceSessionId,
          "templateName":this.state.option
      }  
        await campaignService.sendMessage(payload);
     
  } catch (error: any) {
     console.log(error)
  }
}
fetchTemplateData = async (partyId:any) => {
  try {
      const templateData = await campaignService.getTemplate(partyId);
      const data=templateData.data;
      this.setState({ data });
  } catch (error) {
      console.error('Error fetching template:', error);
  }
}
  handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ phoneNumberError: '' });
    this.setState({ phoneNumbers: event.target.value }, () => {
      const phoneNumbers = this.state.phoneNumbers.split(',').map((numberString:any) => +numberString.trim());
      this.setState({ phoneNumber: phoneNumbers });
      
  });
  event.preventDefault();
  };

  handleOptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    this.setState({templateError:''})
    const selectedOption = event.target.value as string;
    let message = '';
    this.state.data.forEach((item:any)=>{
      if(item.templateName===selectedOption)
        {
          message=item.message
        }

    })
    this.setState({ option: selectedOption, message });
    event.preventDefault();
    
  };

  handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ message: event.target.value });
    event.preventDefault();
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ loader: true }); 
    let confirmation=true;
    if(this.state.option==='')
      {
          confirmation=false;
          this.setState({templateError:'Required'})
      }
      if(this.state.phoneNumber.length===0)
        {
          confirmation=false;
          setTimeout(() => {
            this.setState({
              loader: false,
            });
          }, 1500);

          this.setState({phoneNumberError:'Required'})
        }
    
    this.state.phoneNumber.forEach((number:any, index:any) => {
      let numberString=number+'';
      if(numberString.length<10||numberString.length>10||isNaN(number))
        {
          confirmation=false;
          this.setState({ phoneNumberError: 'Phone number is not valid' });
        }

       
      
  });
    if(confirmation)
    {
       this.sendMessage()
       setTimeout(() => {
        this.setState({
          loader: false,
          message: '',
          phoneNumber: [],
          option: '',
        });
      }, 1500);
      
    }
    else
    {
      setTimeout(() => {
        this.setState({
          loader: false,
        });
      }, 1500);
    }
    
    

  };

  render() {
    const { phoneNumbers, option, message,data,phoneNumberError,loader,templateError } = this.state;
    const { practiceSessions } = this.props.practiceSesContext
    console.log("data",practiceSessions)
    return (
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'green' }}>
              <WhatsAppIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
            To Send BulkWhatsApp
            </Typography>
            <Box component="form" noValidate onSubmit={this.handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="tel"
                    name="phoneNumber"
                    required
                    fullWidth
                    id="phoneNumber"
                    label="Phone Number"
                    value={phoneNumbers}
                    onChange={this.handlePhoneChange}
                    error={!!phoneNumberError}
                    helperText={phoneNumberError}

                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    required
                    fullWidth
                    id="option"
                    label="Choose Template"
                    value={option}
                    onChange={this.handleOptionChange}
                    SelectProps={{
                      native: true,
                    }}
                    error={!!templateError}
                    helperText={templateError}
                  >
                    <option value="">Choose Template</option>
                    {data&&data.length > 0 ? (
                      <>
                        {data.map((template: any, index: any) => (
                          <React.Fragment key={index}>

                            <option value={`${data[index].templateName}`}>{data[index].templateName}</option>
                          </React.Fragment>
                        ))}
                      </>
                    ) : null}

                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="message"
                    label="Message"
                    multiline
                    rows={6}
                    value={message}
                    onChange={this.handleMessageChange}
                    disabled
                  />
                </Grid>

              </Grid>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                {loader ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Send'}
              </Button>

            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}
export default withHooks(BulkWhatsApp, [
  ['practiceSesContext', useContext, [PracticeSesContext]],
]);