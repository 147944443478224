import React, { useState, useContext } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, Card, Grid,
    Typography, CardContent, IconButton, Stack
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import "../styles/connect-timing-main-card.scss";
import CustomSwitch from '../../../components/CustomSwitch';
import { weekDaysMapping } from '../../leave/components/ApplyLeave';
import advancedSetttingServiceSerive from '../../../services/http/advanced-settings/advanced-settings.services';
import { AppContext } from '../../../contexts/app.context';

type MainCardProps = {
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>,
    setSelectedSes: React.Dispatch<React.SetStateAction<number>>,
    getConnectTimingDet: Function
    connectTimings: any[]
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MainCard(props: MainCardProps) {
    const appContext: any = useContext(AppContext)
    const { setOpenModal, setSelectedSes, getConnectTimingDet, connectTimings } = props;
    const [confirmDialog, setConfirmDialog] = useState<boolean>(false);

    /**
     * Delete the session
     * @param status 
     * @returns 
     */
    const handleAction = (status: boolean) => {
        try {
            if (!status) return
            console.log("delete the timing");
        } catch (error) {
            console.error("Error in handleAction", error);
        } finally {
            setConfirmDialog(false);
        }
    }

    /**
     * Update connect timing
     * @param e 
     * @param data 
     */
    const updateConnectTimingMenu = async (e: React.ChangeEvent<HTMLInputElement>, data: any): Promise<void> => {
        const { callConnectTimingId } = data;
        try {
            const payLoad = {
                connect: e.target.checked
            }
            const { message } = await advancedSetttingServiceSerive.updateConnectTiming(callConnectTimingId, payLoad);
            getConnectTimingDet();
            appContext.toast({ open: true, message, severity: "success" });
        } catch (error: any) {
            appContext.toast({ open: true, message: error?.message, severity: "warning" });
            console.error("Error in updateConnectTiming", error);
        }
    }


    const ConfirmDialog = () => {
        return (
            <Dialog
                open={confirmDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleAction(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure to delete the selected connect timinig?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleAction(false)}>Cancel</Button>
                    <Button onClick={() => handleAction(true)}>Okay</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <React.Fragment>
            {
                connectTimings.map((item: any, indx: number) => {
                    const { orgName, area, connect_days, callConnectFromTime, callConnectToTime, checked } = item;
                    const days = weekDaysMapping(connect_days ? connect_days.split(',') : []);
                    return (
                        <Grid container className="connect-timing-card-root" key={indx}>
                            <Grid item xs={12} md={12} >
                                <Card sx={{ padding: '0px' }}>
                                    <Grid container spacing={2} sx={{ pt: '0px !important' }}>
                                        <Grid item xs={12} md={12}>
                                            <Box sx={{
                                                padding: '1rem 0.5rem 1rem 1rem !important'
                                            }}>
                                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                                    <Typography variant="h6" component="span">
                                                        {orgName}, {area}
                                                    </Typography>
                                                    <CustomSwitch
                                                        checked={checked}
                                                        onChange={(e) => updateConnectTimingMenu(e, item)}
                                                    />
                                                </Stack>
                                                <Typography variant="caption" component="div">
                                                    {days}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={4} md={3} lg={3} sm container sx={{ pt: '0px !important' }}>
                                            <CardContent sx={{
                                                flex: '1 0 auto',
                                                justifyContent: 'center',
                                                padding: '0px 0px 0rem 1rem !important',
                                                lineHeight: 0
                                            }}>
                                                <Typography variant="caption" color="text.secondary" component="div">
                                                    From Time
                                                </Typography>
                                                <Typography variant="subtitle1" display="block" sx={{ margin: 0 }}>
                                                    {callConnectFromTime}
                                                </Typography>
                                            </CardContent>
                                        </Grid>
                                        <Grid item xs={4} md={3} lg={3} sm container sx={{ pt: '0px !important' }}>
                                            <CardContent sx={{
                                                flex: '1 0 auto',
                                                justifyContent: 'center',
                                                padding: '0px 0px 1rem 0rem !important',
                                                lineHeight: 0
                                            }}>
                                                <Typography variant="caption" color="text.secondary" component="div">
                                                    To Time
                                                </Typography>
                                                <Typography variant="subtitle1" display="block" sx={{ margin: 0 }}>
                                                    {callConnectToTime}
                                                </Typography>
                                            </CardContent>
                                        </Grid>
                                        <Grid item xs={2} md={3} lg={3} sm container sx={{ pt: '0px !important' }}>
                                            <CardContent sx={{
                                                flex: '1 0 auto',
                                                justifyContent: 'center',
                                                padding: '0px 0px 1rem 0rem !important',
                                                lineHeight: 0
                                            }}>
                                                <Typography variant="caption" color="text.secondary" component="div">
                                                    Edit
                                                </Typography>
                                                <IconButton className="delete-btn" onClick={() => {
                                                    setOpenModal(true);
                                                    setSelectedSes(indx);
                                                }}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </CardContent>
                                        </Grid>
                                        <Grid item xs={2} md={3} lg={3} sm container sx={{ pt: '0px !important' }}>
                                            <CardContent sx={{
                                                flex: '1 0 auto',
                                                justifyContent: 'center',
                                                padding: '0px 0px 1rem 0rem !important',
                                                lineHeight: 0
                                            }}>
                                                <Typography variant="caption" color="text.secondary" component="div">
                                                    Delete
                                                </Typography>
                                                <IconButton color="error" className="delete-btn" onClick={() => setConfirmDialog(true)} disabled>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid >

                            {/**Delete confirmation dialog */}
                            <ConfirmDialog />
                        </Grid >
                    )
                })
            }
        </React.Fragment>
    )
}
