import CloseIcon from '@mui/icons-material/Close';
import {
    Button, Dialog, DialogActions, DialogContent, useMediaQuery,
    useTheme
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import withHooks from '../../../../utils/withHooks';
import { ApptContext } from '../../contexts/appointment.context';
import AppointmentFilter from './AppointmentFilter';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AppointmentFilterModal extends React.Component<any, any, {}> {

    state: any;


    constructor(props: any) {
        super(props);

        this.state = {
            open: props.open ?? false,
            filterValues: {

            }
        }
    }


    render(): React.ReactNode {
        return <>
            <Dialog
                fullScreen={this.props.isMd}
                open={this.props.open}
                TransitionComponent={Transition}
            >
                <AppBar sx={{
                    position: 'relative',
                    background: 'none',
                    boxShadow: 'none !important',
                    color: 'neutral.900',
                }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Filter
                        </Typography>

                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            onClick={() => this.props.onClose()}

                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <DialogContent>
                    <AppointmentFilter filterValues={this.props.filterValues}
                        onFilter={() => {
                            this.props.apptContext.loadAppointments()
                        }}
                        onEmitValues={(e: any, isInit: boolean) => {
                            this.setState({ filterValues: e })
                        }} />
                    <DialogActions sx={{mt:'1rem'}}>
                        <Button size="small" variant="outlined" onClick={() => this.props.onClose()}>Cancel</Button>
                        <Button size="small" variant="outlined" onClick={() => {
                            // this.props.onFilter(this.state.filterValues)
                            this.props.onClose();
                        }}>Ok</Button>
                    </DialogActions>
                </DialogContent>

            </Dialog>
        </>
    }
}

export default withHooks(AppointmentFilterModal, [
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
    ['apptContext', React.useContext, [ApptContext]],
    ['theme', useTheme]
]);