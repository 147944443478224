const withHooks = (Comp: any, hookLists: any[]) => (props: any) => {
	const _hooks:any = {};
	hookLists.map(hook => {
		let params = []
		if (hook[2]) {
			params = hook[2]
		}
		_hooks[hook[0]] = hook[1](...params);
		return hook;
	});
	return <Comp {...props} {..._hooks} />;
}

export default withHooks;
