import { useEffect, useState } from "react";
import { getCookie, setCookie } from "typescript-cookie";
import { common } from "../config/config";
import jwtDecode from 'jwt-decode';
import settingService from "../services/http/settings/setting.service";

export default function useAuth() {
	const [isLogin, setIsLogin] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [partyId, setPartyId] = useState('');

	useEffect(() => {
		// Update the document title using the browser API
		if (common.schedulaToken) {
			setCookie('SCHEDULA_TOKEN', common.schedulaToken)
		}
		const token = getCookie('SCHEDULA_TOKEN');
		if (token) {
			sessionStorage.setItem('SCHEDULA_TOKEN', token)
			setIsLogin(token ? true : false);
		}

		async function preferedUiSetup() {
			const { preferedUi } = await settingService.getPreferedUi(getAttributeFromToken('partyId'));
			if (preferedUi === 'legacy') {
				window.location.replace(common.schedulaLegacyAppUrl as string);
			}
		}
		preferedUiSetup()

		setIsLoading(true);
	}, []);

	return {
		isLogin,
		isLoading,
		partyId,
		login() {
			return new Promise((resolve: Function) => {
				setIsLogin(true);
				window.localStorage.setItem('SCHEDULA_TOKEN', 'true');
				resolve();
			});
		},
		logout() {
			return new Promise((resolve: Function) => {
				setIsLogin(false);
				resolve();
			});
		},
		setPartyId(val: string) {
			return new Promise((resolve: Function) => {
				setPartyId(val);
				window.localStorage.setItem('SCHEDULA_PARTY_ID', val);
				resolve();
			});
		},
	};
}

export const getAttributeFromToken = (key: string) => {
	const token = getCookie('SCHEDULA_TOKEN') as string;
	if (token) {
		const decodeToken: any = jwtDecode(token);
		return decodeToken[key]
	}
}
