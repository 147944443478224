import React, { useState, useEffect, useContext } from "react";
import {
    Grid, TextField, MenuItem, Stack,
    Checkbox, Box, Button, FormControl
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { weekDaysMapping } from "../../leave/components/ApplyLeave";
import practiceSessionService, { ListAllSessionRequest } from "../../../services/http/practice-sessions/practice-session.service";
import { getAttributeFromToken } from "../../../hooks/use-auth";
import { timeStrFormat } from "../../../utils/common";
import dayjs from "dayjs";
import { format } from "date-fns";
import advancedSetttingServiceSerive from "../../../services/http/advanced-settings/advanced-settings.services";
import { AppContext } from "../../../contexts/app.context";



interface ConnectTimingModalProps {
    connectTimings: any[];
    selectedSes: number;
    isNewTiming: boolean;
    getConnectTimingDet: Function;
    setIsNewTiming: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}


function ConnectTimingModal(props: ConnectTimingModalProps) {
    const appContext: any = useContext(AppContext);
    const { connectTimings, selectedSes, isNewTiming, setOpenModal, setIsNewTiming, getConnectTimingDet } = props;
    const [sessionDetails, setSessionDetails] = useState<any>({ selectedDays: [] });
    const [practiceSessions, setPracticeSessions] = useState<any[]>([]);
    const [render, setRender] = useState<boolean>(false);
    const { selectedDays, practiceSessionId,
        callConnectFromTime, callConnectToTime, callConnectTimingId } = sessionDetails;
    const days = weekDaysMapping([1, 2, 3, 4, 5, 6, 7]).split("-");

    useEffect(() => {
        const dateNow = format(new Date(), 'yyyy-MM-dd');
        const sessionInfo = connectTimings[selectedSes];

        if (!isNewTiming) {
            setSessionDetails({
                orgName: sessionInfo?.orgName,
                selectedDays: sessionInfo?.connect_days ? sessionInfo?.connect_days.split(",").map((num: any) => parseInt(num)) : [],
                practiceSessionId: sessionInfo?.practiceSessionId,
                callConnectFromTime: dayjs(format(new Date(`${dateNow} ${sessionInfo?.fromTime}`), "yyyy-MM-dd'T'HH:mm")),
                callConnectToTime: dayjs(format(new Date(`${dateNow} ${sessionInfo?.toTime}`), "yyyy-MM-dd'T'HH:mm")),
                callConnectTimingId: sessionInfo?.callConnectTimingId
            })
        } else {
            setSessionDetails({
                orgName: "",
                selectedDays: [],
                practiceSessionId: sessionInfo?.practiceSessionId,
                callConnectFromTime: dayjs(format(new Date(`${dateNow} 09:00:00`), "yyyy-MM-dd'T'HH:mm")),
                callConnectToTime: dayjs(format(new Date(`${dateNow} 17:00:00`), "yyyy-MM-dd'T'HH:mm")),
                callConnectTimingId: ""
            })
        }

        getPracticeSessions();
        return () => { }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {

    }, [render])

    /**
     * Get all practice sessions
     */
    const getPracticeSessions = async () => {
        const request: ListAllSessionRequest = {
            partyId: getAttributeFromToken('partyId'),
            onlyActiveSessions: true,
            dayFilter: false
        }
        const { data: practiceSessions } = (await practiceSessionService.listAllSessions(request)).data;
        setPracticeSessions(practiceSessions);
    }

    /**
     * On Change time
     * @param attr 
     * @param value 
     */
    const onChangeTime = (attr: string, value: any) => {
        setSessionDetails((prevState: any) => {
            prevState[attr] = value;
            return prevState
        });
        setRender(!render);
    }


    /**
     * On Change selected day
     * @param e 
     * @param indx 
     */
    const onSelectDays = (e: any, indx: number) => {
        const day = ++indx;
        const checked = e?.target?.checked;
        let _selectedDays = selectedDays;

        if (checked) _selectedDays = [..._selectedDays, day]

        if (!checked) {
            const index = _selectedDays.indexOf(day);
            if (index > -1) _selectedDays.splice(index, 1);
        }

        setSessionDetails((prevState: any) => {
            prevState["selectedDays"] = _selectedDays;
            return prevState
        });
        setRender(!render);
    }

    /**
     * Check check box status
     * @param indx 
     * @returns 
     */
    const isDaySelected = (indx: number): boolean => {
        return selectedDays.includes(++indx);
    }

    /**
     * Disable session except selected
     * @param psId 
     * @returns 
     */
    const disableSessionSelection = (psId: string) => {
        return practiceSessionId !== psId && !isNewTiming
    }

    /**
     * Update the session
     */
    const onUpdateChanges = async () => {
        try {
            const payLoad = {
                fromTime: format(callConnectFromTime.toDate(), 'HH:mm:ss'),
                toTime: format(callConnectToTime.toDate(), 'HH:mm:ss'),
                connectDays: selectedDays
            }
            const { message } = await advancedSetttingServiceSerive.updateConnectTiming(callConnectTimingId, payLoad);
            getConnectTimingDet();
            setOpenModal(false);
            setIsNewTiming(false);
            appContext.toast({ open: true, message, severity: "success" });
        } catch (error: any) {
            appContext.toast({ open: true, message: error?.message, severity: "warning" });
            console.error("Error in updateConnectTiming", error);
        }
    }


    /**
     * On Change session
     * @param e 
     */
    const onChangeSession = (e: any) => {
        setSessionDetails((prevState: any) => {
            prevState["practiceSessionId"] = e.target.value;
            return prevState
        });
        setRender(!render);
    }


    return (
        <>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid item xs={12} md={12} lg={12} sm container sx={{ pt: '0px !important' }}>
                        <FormControl variant="standard" sx={{ my: 2, minWidth: '100%' }}>
                            <TextField
                                label="Choose Session"
                                fullWidth
                                id="practiceSessionId"
                                select
                                onChange={onChangeSession}
                                value={practiceSessionId ?? ''}
                                variant="standard"
                            >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                {
                                    practiceSessions.map((pses: any, i: number) => (
                                        <MenuItem
                                            value={pses.practiceSessionId}
                                            key={i}
                                            disabled={disableSessionSelection(pses.practiceSessionId)}
                                        >
                                            <Stack>
                                                <Typography variant="subtitle2">
                                                    {pses.orgName}, {pses.area}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {timeStrFormat(pses.consultingFromTime)} - {timeStrFormat(pses.consultingToTime)}
                                                </Typography>
                                            </Stack>
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} sm container
                        sx={{
                            mb: 1,
                            pt: '0px !important',
                            '.MuiInputBase-input': {
                                padding: '9%'
                            },
                            '.MuiInputBase-root': { boxShadow: 'unset' }
                        }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={[
                                    'TimePicker',
                                    'MobileTimePicker',
                                    'DesktopTimePicker',
                                    'StaticTimePicker',
                                ]}
                            >
                                <DemoItem label="From Time">
                                    <TimePicker
                                        value={callConnectFromTime ?? ''}
                                        onChange={(newValue) => onChangeTime("callConnectFromTime", newValue)}
                                        defaultValue={dayjs('2022-04-17T15:30')}
                                    />
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} sm container
                        sx={{
                            mb: 1,
                            pt: '0px !important',
                            '.MuiInputBase-input': {
                                padding: '9%'
                            },
                            '.MuiInputBase-root': { boxShadow: 'unset' }
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={[
                                    'TimePicker',
                                    'MobileTimePicker',
                                    'DesktopTimePicker',
                                    'StaticTimePicker',
                                ]}
                            >
                                <DemoItem label="To Time">
                                    <TimePicker
                                        value={callConnectToTime ?? ''}
                                        onChange={(newValue) => onChangeTime("callConnectToTime", newValue)}
                                        defaultValue={dayjs('2022-04-17T15:30')}
                                    />
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} container justifyContent={"flex-start"}
                        sx={{
                            pt: '0px !important',
                            mt: 1,
                        }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoItem label="Active Days">
                                <Grid container justifyContent="start">
                                    {
                                        [...days].map((d: any, indx: number) => {
                                            return (
                                                <Grid item xs={4} md={3} key={d}>
                                                    <span style={{ paddingRight: "6px" }}>
                                                        <Checkbox
                                                            checked={isDaySelected(indx)}
                                                            onChange={(e) => onSelectDays(e, indx)}
                                                            name={d}
                                                            size="small"
                                                            key={d}
                                                        />
                                                        <Typography variant="subtitle1" display="inline" gutterBottom>
                                                            {d}
                                                        </Typography>
                                                    </span>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </DemoItem>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Typography textAlign={"end"}>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            setOpenModal(false);
                            setIsNewTiming(false);
                        }}
                    >Cancel</Button>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={onUpdateChanges}
                        // disabled={this.state.updatBtnIsDisabled}
                        sx={{ ml: '5px' }}
                    >
                        Save
                    </Button>
                </Typography>
            </Box>
        </>
    )
}

export default ConnectTimingModal;