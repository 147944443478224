import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as React from 'react';
class SlotListCardSkeleton extends React.Component<any, any> {

    render(): React.ReactNode {
        return (
            <Card sx={{ padding: '0px', mb: '0.75rem' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={2} sx={{ pt: '0px !important' }}>
                        <Grid item xs={9} md={9}>
                            <Box sx={{
                                padding: '1rem !important'
                            }}>
                                <Typography variant="subtitle1" component="span">
                                    <Skeleton />
                                </Typography>
                                <Stack direction="row" spacing={1} sx={{
                                    marginTop: '1rem',
                                    '& .MuiChip-root': {
                                        height: 25,
                                        width: 50
                                    }
                                }}>
                                    <Skeleton width={50} />
                                    <Skeleton width={50} />
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Box sx={{
                                padding: '1rem !important'
                            }}>
                                <Typography variant="caption" component="span">
                                    <Skeleton height={30} />
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        )
    }
}

export default SlotListCardSkeleton;