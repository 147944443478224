import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import { AuthContext } from './contexts/auth-context';
import useAuth from './hooks/use-auth';
import AppLayout from './layouts/v1/app-layout/AppLayout';
import Appointment from './pages/appointments/Appointment';
import Cardview from "./pages/card-view";
import ApptContextProvider from './pages/appointments/contexts/appointment.context';
import PracticeSesContextProvider from './pages/practice-session/contexts/practice-session.context';
import Login from './pages/login/Login';
import PracticeSession from './pages/practice-session/PracticeSession';
import { createTheme } from './theme';
import GuardedRoute from './utils/GuardedRoute';
import LeavePage from './pages/leave/Leave';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import AppContextProvider from './contexts/app.context';
import LeaveContextProvider from './pages/leave/contexts/leave.context';
import ApptCardViewContextProvider from './pages/card-view/contexts/card-view.context';
import AppointmentSummary from './pages/appointments/AppointmentSummary';
import AdvancedSetting from './pages/advanced-settings/components';
import CallLogs from './pages/call-logs';
import CallLogsDetails from './pages/call-logs/call-logs-details/call-logs-details';
import ApptCallLogsContextProvider from './pages/call-logs/context/call-logs.context';
import CallLogsWip from './pages/call-logs-wip';
import CallLogsDetailsWip from './pages/call-logs-wip/call-logs-details/call-logs-details';
import ApptCallLogsContextProviderWip from './pages/call-logs-wip/context/call-logs.context';
import EditProfile from './pages/edit-profile';
import PaymentDetails from "./pages/reports/pages/paymentDetails/PaymentDetails";
import ReportSms from "./pages/reports/pages/sms/ReportSms";
import AllMessages from "./pages/reports/pages/allMessages/AllMessages";
import BulkWhatsApp from './pages/campaign/components/bulkWhatsApp/BulkWhatsApp';
import OnlinePaymentLinkMessage from "./pages/reports/pages/onlinePayementLinkMsgSts/OnlinePaymentLinkMessage";
import OnlinePaymentDetails from './pages/appointments/components/online-appointment-payment-detail/online-appointment-payment';

function App() {
	const theme = createTheme();
	const auth = useAuth();
	// console.log('auth',auth)
	return (
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<CssBaseline />
				<AppContextProvider>
					<ApptContextProvider>
						<PracticeSesContextProvider>
							<AuthContext.Provider value={auth}>
								<Routes>
									<Route
										path="/"
										element={
											<AppLayout theme={theme} />
										}
									>
										<Route path='/' element={<Navigate to={'appointments'} />}></Route>
										<Route path='appointments' element={
											<GuardedRoute auth={true}>
												<AppointmentSummary />
											</GuardedRoute>
										} />
										<Route path='reports/payment-details' element={
											<GuardedRoute auth={true}>
											<PaymentDetails  partyId={auth.partyId}/>
											</GuardedRoute>
										} />
										<Route path='reports/sms' element={
											<GuardedRoute auth={true}>
												<ReportSms/>
											</GuardedRoute>
										} />
										<Route path='reports/all-messages' element={
											<GuardedRoute auth={true}>
												<AllMessages partyId={auth.partyId}/>
											</GuardedRoute>
										} />
										<Route path='reports/online-payment-link-messages' element={
											<GuardedRoute auth={true}>
												<OnlinePaymentLinkMessage/>
											</GuardedRoute>
										} />
										<Route path='campaign/bulk-whatsapp' element={
											<GuardedRoute auth={true}>
												<BulkWhatsApp/>
											</GuardedRoute>
										} />
										<Route path='advanced-setting' element={
											<GuardedRoute auth={true}>
												<AdvancedSetting />
											</GuardedRoute>
										} />
										<Route path='appointment-list' element={
											<GuardedRoute auth={true}>
												<Appointment />
											</GuardedRoute>
										} />
										<Route path='appointment-list/online-payment' element={
											<GuardedRoute auth={true}>
												<OnlinePaymentDetails />
											</GuardedRoute>
										} />
										<Route path='leave' element={
											<LeaveContextProvider>
												<GuardedRoute auth={true}>
													<LeavePage />
												</GuardedRoute>
											</LeaveContextProvider>
										}></Route>
										<Route path='practice-session'
											element={
												<GuardedRoute auth={true}>
													<PracticeSession />
												</GuardedRoute>
											}
										>
										</Route>
										<Route path='card-view'
											element={
												<GuardedRoute auth={true}>
													<ApptCardViewContextProvider>
														<Cardview />
													</ApptCardViewContextProvider>
												</GuardedRoute>
											}
										>
										</Route>
										<Route path='call-log'>
											<Route
												index
												element={
													<GuardedRoute auth={true}>
														<ApptCallLogsContextProvider>
															<CallLogs />
														</ApptCallLogsContextProvider>
													</GuardedRoute>
												}
											/>
											<Route path="details" element={
												<ApptCallLogsContextProvider>
													<CallLogsDetails />
												</ApptCallLogsContextProvider>} />
										</Route>
											{/* wip-integration of NEW IVR sys */}
											<Route path='call-logs-wip'>
											<Route
												index
												element={
													<GuardedRoute auth={true}>
														<ApptCallLogsContextProviderWip>
															<CallLogsWip />
														</ApptCallLogsContextProviderWip>
													</GuardedRoute>
												}
											/>
											<Route path="details" element={
												<ApptCallLogsContextProviderWip>
													<CallLogsDetailsWip />
												</ApptCallLogsContextProviderWip>} />
										</Route>

										{/* wip-integration of NEW IVR sys */}
										<Route path='edit-profile'
											element={
												<GuardedRoute auth={true}>
													<ApptCardViewContextProvider>
														<EditProfile />
													</ApptCardViewContextProvider>
												</GuardedRoute>
											}
										/>
									</Route>
									<Route path='/login' element={<GuardedRoute auth={true}><Login /></GuardedRoute>} />
								</Routes>
							</AuthContext.Provider>
						</PracticeSesContextProvider>
					</ApptContextProvider>
				</AppContextProvider>
			</LocalizationProvider>
		</ThemeProvider>
	);
}

export default App;
