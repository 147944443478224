import React, { Component, useContext } from 'react';
import { Box, Checkbox, Button, TextField, Dialog, DialogContent, DialogActions } from '@mui/material';
import appointmentService from '../../../../services/http/appointments/appointment.service';
import { ApptContext } from '../../contexts/appointment.context';
import { AppContext } from '../../../../contexts/app.context';
import { PracticeSesContext } from '../../../practice-session/contexts/practice-session.context';
import withHooks from '../../../../utils/withHooks';

interface State {
    selectAllChecked: boolean;
    selectIndividualChecked: boolean;
    userInputText: string;
    errorMessage?: string | null;
    confirmationOpen: boolean;
}

interface Props {
    onCancel: () => void;
    apptContext: any;
    appContext: any;
    practiceSesContext: any;
}

class WhatsappNotifyComp extends Component<Props, State> {
    fixedMessage: string;

    constructor(props: Props) {
        super(props);
        this.fixedMessage = "Dear {patientName}, Kindly Note that";
        this.state = {
            selectAllChecked: false,
            selectIndividualChecked: false,
            userInputText: "",
            errorMessage: null,
            confirmationOpen: false,
        };
    }

    handleSelectAllChange = () => {
        this.setState({
            selectAllChecked: !this.state.selectAllChecked,
            selectIndividualChecked: false,
        });
    }

    handleSelectIndividualChange = () => {
        this.setState({
            selectAllChecked: false,
            selectIndividualChecked: !this.state.selectIndividualChecked,
        });
    }

    handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ userInputText: event.target.value });
    }

    handleSubmit = async () => {
        if (this.state.userInputText) {

          

            this.handleOpenConfirmation();
        }
    }

    confirmAndSendMessage = async () => {
        const { apptContext } = this.props;
        const selectedIds = this.state.selectIndividualChecked ? apptContext.selectedAppointments.map((index: number) => apptContext.appointments[index].id) : apptContext.appointments.map((appointment: any) => appointment.id)
        try {
            await appointmentService.notifyPatientsWithAppointments(selectedIds, this.state.userInputText);
            this.props.apptContext.setNotiModal(!this.props.apptContext.openNotiModal)
        } catch (error:any) {
            console.error("An error occurred while notifying patients:", error);
        }

        this.handleCloseConfirmation();
    }

    handleOpenConfirmation = () => {
        this.setState({ confirmationOpen: true });
    }

    handleCloseConfirmation = () => {
        this.setState({ confirmationOpen: false });
        this.props.apptContext.setNotiModal(!this.props.apptContext.openNotiModal)
    }




    render() {
        const { userInputText } = this.state;
        const { setNotiModal, openNotiModal } = this.props.apptContext;
        const {
            drName,
            orgName,

          }=this.props.practiceSesContext.selectedPracticeSession
        console.log("apt",this.props.practiceSesContext.selectedPracticeSession)

        const emptyMessageBox = !userInputText && (
            <p style={{ color: 'red', margin: '0', padding: '1' }}>Message cannot be blank.</p>
        );

        return (
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '100%',
                }}
            >
                <Checkbox
                    checked={this.state.selectAllChecked}
                    onChange={this.handleSelectAllChange}
                />
                <span style={{ fontWeight: 'bold' }}>WhatsApp All</span>
                <Checkbox
                    checked={this.state.selectIndividualChecked}
                    onChange={this.handleSelectIndividualChange}
                />
                <span style={{ fontWeight: 'bold' }}>WhatsApp Selected</span>
                 <Box
                  sx={{
                    border: '1px solid black',
                     borderRadius: '5px'

                    
                }}>
               
               <Box component="p" sx={{ margin: '0', padding: '5px', color: 'black' }}>Dear patientName, Kindly Note that</Box>

                

                {/* TextField */}
                <TextField
                    // label="Write your message here"
                    multiline
                    rows={4}
                    fullWidth
                    value={userInputText}
                    InputProps={{
                        sx: {
                            backgroundColor: 'transparent', 
                            border: '1px solid white',
                            verticalAlign: 'middle', 
                            padding: '0 10px'
                        }
                    }}
                    onChange={this.handleTextChange}
                    sx={{ marginTop: '0', marginBottom: '1px' }}
                    focused={false} 
                />
                 {emptyMessageBox}
                 <Box component="p" sx={{ margin: '0', padding: '5px', color: 'black' }}>Sent by {drName}, {orgName} Thank You</Box>
            </Box>

                


                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <Button variant="contained" color="primary" style={{ fontSize: '12px' }} onClick={this.handleSubmit}>Submit</Button>
                    <div style={{ width: '10px' }}></div>
                    <Button variant="outlined" color="primary" style={{ fontSize: '12px' }}  onClick={() => setNotiModal(!openNotiModal)}>Cancel</Button>
                </div>
                <Dialog
                open={this.state.confirmationOpen}
                onClose={this.handleCloseConfirmation}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description"
            >
                {/* <DialogTitle id="confirmation-dialog-title">Confirm Action</DialogTitle> */}
                <DialogContent>
                    {this.state.selectIndividualChecked && !this.props.apptContext.selectedAppointments.length && (
                        <p>Please select appointments before submitting.</p>
                    )}
                    {(this.state.selectIndividualChecked && this.props.apptContext.selectedAppointments.length) || this.state.selectAllChecked ? (
                        <p>Are you sure you want to send the message?</p>
                    ) : null}
                    {!this.state.selectIndividualChecked && !this.state.selectAllChecked ? (
                        <p>Please select at least one option before proceeding.</p>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    {(this.state.selectIndividualChecked && this.props.apptContext.selectedAppointments.length) || (!this.state.selectIndividualChecked && this.state.selectAllChecked) ? (
                        <>
                            <Button variant="contained" color="primary" onClick={this.confirmAndSendMessage}>Yes</Button>
                            <Button variant="outlined" color="primary" onClick={this.handleCloseConfirmation}>No</Button>
                        </>
                    ) : (
                        <Button variant="outlined" color="primary" onClick={this.handleCloseConfirmation}>OK</Button>
                    )}
                </DialogActions>
            </Dialog>
            </Box>
        );
    }
}

export default withHooks(WhatsappNotifyComp, [
    ['apptContext', useContext, [ApptContext]],
    ['appContext', useContext, [AppContext]],
    ['practiceSesContext', useContext, [PracticeSesContext]]
]);

