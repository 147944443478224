import { format } from 'date-fns';
import React, { createContext } from 'react';
import appointmentService from '../../../services/http/appointments/appointment.service';
import { getAttributeFromToken } from '../../../hooks/use-auth';

export enum ApptStatusEnum {
    ALL = 'all',
    CONFIRMED = 1,
    CANCELLED = 2
}

export interface ApptFilter {
    scheduleOn: Date;
    apptStatus: ApptStatusEnum;
    practiceSessionId: string | number;
}

export interface ApptContextState {
    appointments: any[];
    selectMode: boolean;
    selectAllAppts: false;
    selectedAppointments: any[];
    hasMore: boolean;
    isLoading: boolean;
    filter: ApptFilter;
    isApplyFilter: boolean;
    searchStr: string;
    openAddApptModal: boolean;
    openNotiModal: boolean;
    editApptModal: boolean;
    editSelectedAppt: {};
    isHoldable: boolean;
    partyId: number;
}

type ApptContextType = {
    appointments: any[];
    hasMore: boolean;
    isLoading: boolean;
    selectMode: boolean;
    selectAllAppts: false;
    selectedAppointments: any[];
    searchStr: string;
    openAddApptModal: boolean,

    openNotiModal: boolean,

    editApptModal: boolean,
    editSelectedAppt: {}
    isHoldable: boolean,
    partyId: number,
    setFilterValues: (attr: string, value: string) => void;
    loadAppointments: () => Promise<void>;
    selectAllAppointments: (reset: boolean) => void;
    selectAppointment: (index: number) => void;
};

export const ApptContext = createContext<ApptContextType | null>(null);

class ApptContextProvider extends React.Component<any, any> {

    state: ApptContextState;

    constructor(props: any) {
        super(props);

        this.state = {
            filter: {
                scheduleOn: new Date(),
                apptStatus: ApptStatusEnum.ALL,
                practiceSessionId: ''
            },
            isApplyFilter: false,
            appointments: [],
            selectMode: false,
            selectAllAppts: false,
            selectedAppointments: [],
            hasMore: true,
            isLoading: true,
            searchStr: '',
            openAddApptModal: false,
            openNotiModal: false,
            editApptModal: false,
            editSelectedAppt: {},
            isHoldable: false,
            partyId: getAttributeFromToken('partyId')
        }
    }


    loadAppointments = async () => {
        this.setState({ isLoading: true });
        const request = {
            ...this.state.filter,
            scheduleOn: format(this.state.filter.scheduleOn, 'yyyy-MM-dd')
        }
        const { data: appointments } = await appointmentService.getAppointments(request);
        this.setState({
            appointments,
            hasMore: appointments.length === 1,
        });
        setTimeout(() => {
            this.setState({ isLoading: !this.state.isLoading });
        }, 700);
    }

    selectAppointment = (index: number) => {
        let { appointments, selectedAppointments } = this.state;

        appointments = appointments.map((appt: any, i: number) => {
            if (i === index) {
                appt.isSelect = !appt.isSelect;
                if (appt.isSelect) {
                    selectedAppointments = [...selectedAppointments, index];
                } else {
                    selectedAppointments = [...selectedAppointments.filter((i: number) => i !== index)]
                }
            }
            return appt;
        });
        this.setState({
            appointments,
            selectedAppointments,
            selectAllAppts: appointments.length === selectedAppointments.length,
            selectMode: selectedAppointments.length > 0,
            isHoldable: !(selectedAppointments.length === 0 && this.state.isHoldable)
        });
    }

    setIsHoldable = (isHoldable: boolean) => this.setState({ isHoldable })

    selectAllAppointments = (reset: boolean) => {
        let { appointments, selectedAppointments, selectAllAppts } = this.state;
        appointments.map((appt: any, i: number) => {
            appt.isSelect = reset ? false : (!selectAllAppts && appt.status === ApptStatusEnum.CONFIRMED);
            return appt;
        });

        selectedAppointments = [];

        if (!selectAllAppts === true) {
            selectedAppointments = [...appointments.map((a: any, i: number) => i)];
        }

        this.setState({
            appointments,
            selectAllAppts: !selectAllAppts,
            selectedAppointments: reset ? [] : selectedAppointments,
            selectMode: reset ? false : selectedAppointments.length > 0,
            isHoldable: reset ? false : selectedAppointments.length > 0
        })
    }

    setFilterValues = (attr: string, value: string) => {
        const filter = this.state.filter as any;
        filter[attr] = value;
        this.setState({
            filter
        });
    }

    setSearchStr = (searchStr: string) => this.setState({ searchStr })

    refreshAppointments = async () => {
        console.log('Refreshing appontments')
        const request = {
            ...this.state.filter,
            scheduleOn: format(this.state.filter.scheduleOn, 'yyyy-MM-dd')
        }
        const { data: appointments } = await appointmentService.getAppointments(request);

        this.searchAppointment(this.state.searchStr, this.updateAppointments(appointments));
    }

    updateAppointments = (appointments: any): Array<any> => {
        const _appointments = appointments.map((newappt: any) => {
            const indx = this.state.appointments.findIndex(appt => appt.id === newappt.id);
            const isSelect = this.state.appointments[indx].isSelect;
            if (isSelect) newappt.isSelect = isSelect;
            return newappt;
        })
        return _appointments;
    }

    searchAppointment = (searchStr: string, data?: any) => {
        let appointments = data || this.state.appointments;
        appointments = appointments.map((appt: any) => {
            appt.isFilter = false
            if (('' + appt.phoneNumber).includes(searchStr) || (appt.name || '').includes(searchStr)) {
                appt.isFilter = true
            }
            return appt;
        });

        this.setState({ appointments });
    }

    getAppointments = (): any => {
        return this.state.appointments.filter((appt: any) => appt?.isFilter === undefined || appt.isFilter === true)
    }

    setApptModal = (openAddApptModal: boolean) => this.setState({ openAddApptModal })
    setNotiModal = (openNotiModal: boolean) => this.setState({ openNotiModal })

    setEditApptModal = (editApptModal: boolean) => this.setState({ editApptModal })

    setEditSelectedAppt = (editSelectedAppt: any) => {
        this.setState({ editSelectedAppt })
    }

    private contextValues = () => ({
        ...this.state,
        loadAppointments: this.loadAppointments,
        selectAllAppointments: this.selectAllAppointments,
        selectAppointment: this.selectAppointment,
        setFilterValues: this.setFilterValues,
        refreshAppointments: this.refreshAppointments,
        searchAppointment: this.searchAppointment,
        setSearchStr: this.setSearchStr,
        getAppointments: this.getAppointments,
        setApptModal: this.setApptModal,
        setNotiModal: this.setNotiModal,
        setIsHoldable: this.setIsHoldable,
        setEditApptModal: this.setEditApptModal,
        setEditSelectedAppt: this.setEditSelectedAppt
    })

    render(): React.ReactNode {
        return (
            <ApptContext.Provider value={this.contextValues()}>
                {this.props.children}
            </ApptContext.Provider>
        )
    }
}


export default ApptContextProvider;