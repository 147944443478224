import axios from 'axios';
import { appointmentServiceApi } from "../../../config/config";
// import CardViewMock from "../../../mock/card-view.json";
import { setupInterceptorsTo } from "../interceptors/axios.interceptos";
const axiosInstance = setupInterceptorsTo(axios.create());


class CampaignService {

    async getTemplate( partyId : any): Promise<any> {
        try {
            const { data } = await axiosInstance.get(`${appointmentServiceApi.baseUrl}/api/v1/message-bird/static-templates`, {
                params: {
                    partyId: partyId
                }
            });
            return data;
        } catch (error) {
            
            console.log('Error in Get Template')
            return {
                data: []
            }
        }
    }
    async sendMessage( params : any): Promise<any> {
        try {
            const { data } = await axiosInstance.post(`${appointmentServiceApi.baseUrl}/api/v1/message-bird/bulk-whats-app`,params);
            return data;
        } catch (error) {
            
            console.log('Error in Send Message')
            return {
                data: []
            }
        }
    }

    
    
}
const campaignService = new CampaignService();
export default campaignService;