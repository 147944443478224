import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import EditIcon from '@mui/icons-material/Edit';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { Box, Grid, SvgIcon } from '@mui/material';
import Card from '@mui/material/Card';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { format } from "date-fns";
import * as React from 'react';
import AppointmentModal from '../../../../modals/appointment.modal';
import { ApptStatusEnum } from '../../contexts/appointment.context';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Link } from 'react-router-dom';


export interface AppointmentListCardProps extends AppointmentModal {
    isMd: boolean,
    onSelect: Function,
    item: number,
    apptContext: any,
    isHoldable: boolean,
    status: number,
    apptDetails:any,
    sessionType:any,
}



export const RequestModeIcon: any = {
    1: <LocalPhoneOutlinedIcon />,
    2: <PhoneAndroidOutlinedIcon />,
    3: <DesktopWindowsOutlinedIcon />,
    4: <DirectionsWalkIcon />
}
class AppointmentListCard extends React.Component<AppointmentListCardProps, any> {

    state = {
        expanded: false,
        isPhoneNumberCopied: false
    }

    constructor(props: any) {
        super(props);

        this.state = {
            expanded: false,
            isPhoneNumberCopied: false
        }
    }

    handleExpandClick = () => {
        this.setState({ ...this.state, expanded: !this.state.expanded });
    };

    showRequestMode() {
        return RequestModeIcon[this.props.requestMode]
    }

    isShowCheckBox = () => {
        const { apptContext, status } = this.props;
        const selectedDate = format(apptContext.filter.scheduleOn, 'yyyy-MM-dd');
        const todayDate = format(new Date(), 'yyyy-MM-dd');
        return (selectedDate >= todayDate && status !== ApptStatusEnum.CANCELLED);
    }


    /**
     * Onclick edit appointment btn
     */
    editApptUserDetails = () => {
        const { apptContext, apptDetails } = this.props;
        apptContext?.setEditApptModal(true);
        apptContext?.setEditSelectedAppt(apptDetails);
    }

     /**
     * Onclick Whatsapp chat open btn
     */

    handleWhatsAppClick = () => {

        const phone = this.props.phoneNumber;
        const whatsappUrl = `https://wa.me/+91${phone}`; 
        window.open(whatsappUrl, '_blank');
    }


   

    handleContentCopyClick = () => {
        const { phoneNumber } = this.props;
        navigator.clipboard.writeText(phoneNumber)
            .then(() => {console.log("Phone number copied");
            
            this.setState({ isPhoneNumberCopied: true });
            }
            )
            .catch(error => console.error("Failed to copy phone number:", error));
    }

    handleCloseModal = () => {
        this.setState({ isPhoneNumberCopied: false });
    }



    render(): React.ReactNode {
        const { item, apptContext, isSelect,phoneNumber,sessionType} = this.props;
        const {id,scheduledOn}=this.props.apptDetails;
        const date=scheduledOn.split('T')[0]; 
       
        
        return (
            <Card sx={{ padding: '0px', width: '100%' }} onDoubleClick={(evt: any) => this.props.onSelect(evt)}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                    <Grid container sx={{
                        padding: '0rem'
                    }}>
                        <Grid item xs={4} sx={{
                            padding: '0rem',
                            display: 'flex',
                            ...(true && {
                                background: '#0c3376',
                                color: '#f9f9f9',
                                '& .schedule-datetime': {
                                    color: '#f9f9f9',
                                    fontWeight: '400'
                                }
                            })
                        }} className="appt-number">
                            {/* <Typography component="div" sx={{
                                ...(this.props.isMd && {
                                    display: 'none'
                                })
                            }}>
                                <Checkbox />
                            </Typography> */}

                            <CardContent sx={{
                                flex: '1 0 auto',
                                textAlign: 'center',
                                padding: '15px 15px !important'
                            }}>
                               
                                <Typography component="div" variant="h3">
                                    
                                    {this.props.apptNumber}
                                </Typography>
                                <Typography className="schedule-datetime" variant="subtitle2" color="text.secondary" component="div">
                                    <div>{this.props.scheduleDateString}</div>
                                    <div> {this.props.scheduleTime ?? '00:00'}</div>
                                </Typography>
                                <Typography variant="caption" gutterBottom sx={{ fontSize: '8px', mt: '20px', fontWeight: '300' }}>
                                    {this.props.requestedOn}
                                </Typography>
                            </CardContent>
                            <Typography component="span" sx={{
                                position: 'relative',
                                left: '-90%',
                                top: '5%',
                            }}>
                                <SvgIcon fontSize="small">{this.showRequestMode()}</SvgIcon>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm container sx={{ padding: '0rem' }}>
                            <CardContent sx={{
                                flex: '1 0 auto', justifyContent: 'center',
                                padding: '15px 15px !important'
                            }}>
                                
                                <Typography component="div" variant="h6" >

                                     

                                {phoneNumber ? (
                                   <>
                                        <span>{phoneNumber}</span>
                                        <IconButton aria-label="whatsapp" onClick={this.handleWhatsAppClick}>
                                            <WhatsAppIcon />
                                        </IconButton>

                                        {sessionType === 'Online' && (
                                          <Link to={`/appointment-list/online-payment?apptId=${id}&date=${date}`}>
                                                <IconButton aria-label="payment">
                                                <CurrencyRupeeIcon />
                                                </IconButton>
                                            </Link>
                                            )}
                                            {sessionType === 'Online' && (
                                            <IconButton aria-label="copy" onClick={this.handleContentCopyClick}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                            )}

                                   </>
                                ) : (
                                    <span>-</span>
                                )}

                                  

                                </Typography>
                            
                                <Typography sx={{ mt: 1.5 }} component="div" variant="subtitle1" >
                                    {this.props.name ?? 'No Name'}
                                </Typography>
                                <Typography sx={{ mt: 1.5 }} color="text.secondary">
                                    Physique
                                </Typography>
                                <Typography color="text.primary" variant="subtitle1" component="div">
                                    {this.props.age ?? '- years'} | {this.props.gender ?? '-'} | {this.props.height ?? '- cm'} | {this.props.weight ?? '- kgs'}
                                </Typography>
                            </CardContent>
                        </Grid>
                        <Grid container item xs={1}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-end"
                            sx={{ padding: '0rem', ml: this.props.isMd ? 2 : 0, mr: this.props.isMd ? 0 : 1 }}
                        >
                            <Typography color="text.primary" component="div" sx={{ mt: 1.5 }}>
                                <IconButton onClick={() => this.editApptUserDetails()}>
                                    <SvgIcon fontSize="small"> <EditIcon /></SvgIcon>
                                </IconButton>
                            </Typography>
                            {
                                (this.props.isMd && apptContext?.isHoldable && this.isShowCheckBox()) && <Typography component="div" textAlign={"center"}>
                                    <Checkbox
                                        id={`${item}`}
                                        checked={isSelect || false}
                                        onChange={() => apptContext.selectAppointment(item)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Typography>
                            }
                            {
                                (!this.props.isMd && this.isShowCheckBox()) && <Typography component="div" textAlign={"center"}>
                                    <Checkbox
                                        id={`${item}`}
                                        checked={isSelect || false}
                                        onChange={() => apptContext.selectAppointment(item)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        )
    }
}

export default AppointmentListCard;