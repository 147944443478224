import { useTheme } from '@emotion/react';
import { Box, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import withHooks from '../../utils/withHooks';
import ListSessionCard from './components/ListSessionCard';
import ListSessionCardSkeleton from './components/ListSessionCardSkeleton';
import ManageSessionModal from './components/manage-session/ManageSessionModal';
import { PracticeSesContext } from './contexts/practice-session.context';
import { getAttributeFromToken } from '../../hooks/use-auth';
import practiceSessionService, { ListAllSessionRequest } from '../../services/http/practice-sessions/practice-session.service';
import dayjs from 'dayjs';
import { format } from 'date-fns';

class PracticeSession extends React.Component<any> {

    state: any;
    dateNow = format(new Date(), 'yyyy-MM-dd');

    constructor(props: any) {
        super(props);

        this.state = {
            practiceSessions: [],
            isFilterDrawerOpen: true
        }
    }

    async componentDidMount(): Promise<void> {
        await this.getPracticeSessions();
    }

    async getPracticeSessions() {
        const request: ListAllSessionRequest = {
            partyId: getAttributeFromToken('partyId'),
            onlyActiveSessions: true,
            dayFilter: false
        }
        const { data: practiceSessions } = (await practiceSessionService.listAllSessions(request)).data;
        this.setState({ ...this.state, practiceSessions });
        this.props.practiceSesContext.setPracticeSessions(practiceSessions);
    }

    async toggleFilterDrawer() {
        this.setState({ ...this.state, isFilterDrawerOpen: !this.state.isFilterDrawerOpen });
    }

    onSelectSession = async (selectedId: number): Promise<any> => {
        try {

            const { setPracticeSessionsSlots, setManageSesModal, openManageSesModal, setManageSessionSelected, } = this.props?.practiceSesContext;
            const { apptBookingFromTime, apptBookingToTime, consultingFromTime, consultingToTime, day, id, regularSessionId, psDate } = this.getSelectedSession(selectedId);

            const { data } = await practiceSessionService.listSlots({ practiceSessionId: selectedId, date: format(new Date(), 'yyyy-MM-dd') });
            setPracticeSessionsSlots(data);
            this.setState({ selectedId });

            setManageSessionSelected({
                selecedSession: selectedId,
                authAssignmentId: id,
                consultingStartTime: dayjs(format(new Date(`${this.dateNow} ${consultingFromTime}`), "yyyy-MM-dd'T'HH:mm")),
                consultingEndTime: dayjs(format(new Date(`${this.dateNow} ${consultingToTime}`), "yyyy-MM-dd'T'HH:mm")),
                bookingStartTime: dayjs(format(new Date(`${this.dateNow} ${apptBookingFromTime}`), "yyyy-MM-dd'T'HH:mm")),
                bookingEndTime: dayjs(format(new Date(`${this.dateNow} ${apptBookingToTime}`), "yyyy-MM-dd'T'HH:mm")),
                selectedDays: day ? day.split(",").map((num: any) => parseInt(num)) : psDate ? [8] : [],
                regularSessionId,
                psDate,
                day
            });

            setManageSesModal(!openManageSesModal);
        } catch (error) {
            console.error("error", error);
        }
    }

    getSelectedSession = (id: number): any => {
        const { practiceSessions } = this.practiceSesContext;
        const [session] = practiceSessions.filter((pses: any) => pses.practiceSessionId === id);
        return session ? session : {}
    }

    get practiceSesContext() {
        return this.props.practiceSesContext;
    }

    render(): React.ReactNode {
        const { practiceSessions } = this.props.practiceSesContext
        return <React.Fragment>
            {/**Practice session page */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 2,
                    '.MuiContainer-root': {
                        margin: 0
                    }
                }}
            >
                <Container maxWidth="xl">
                    <Stack
                        spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Typography variant={this.props?.isMd ? 'h6' : 'h5'}>
                                    Practice Sessions
                                </Typography>
                            </Stack>
                        </Stack>
                        <Grid container>
                            {
                                practiceSessions.length ? (<React.Fragment>
                                    {
                                        practiceSessions.map((ses: any, index: number) => (
                                            <Grid item xs={12} md={8} sx={{ pb: '1rem' }} key={index} onClick={() => this.onSelectSession(ses?.practiceSessionId)} >
                                                <ListSessionCard {...ses} />
                                            </Grid>
                                        ))
                                    }
                                </React.Fragment>) : (<React.Fragment>
                                    {
                                        [1, 2, 3, 4, 5, 6, 7].map((item => <ListSessionCardSkeleton key={item} />))

                                    }
                                </React.Fragment>
                                )
                            }
                        </Grid>
                    </Stack>
                </Container>
            </Box>

            {/**Manage practice session modal */}
            {
                this.state?.selectedId && <ManageSessionModal id={this.state?.selectedId} />
            }
        </React.Fragment>
    }
}

export default withHooks(PracticeSession, [
    ['theme', useTheme],
    ['practiceSesContext', useContext, [PracticeSesContext]],
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
]);