import { useEffect, useContext } from 'react';
import { Box, Container, Typography, useMediaQuery } from '@mui/material'
import MainCard from './componenst/main-card';
import QueueTabs from './componenst/queue-tabs';
import appointmentService from '../../services/http/appointments/appointment.service';
import { ApptCardViewContext } from './contexts/card-view.context';
import { useLocation } from 'react-router-dom';
import CardViewSkeleton from './componenst/card-view-skeleton';

export default function CardView() {
    const cardViewCnxt = useContext(ApptCardViewContext);
    const { cardView, setCardView }: any = cardViewCnxt;
    const isMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
    const { state } = useLocation();

    useEffect(() => {
        getCardView();
        return () => { }
        // eslint-disable-next-line
    }, []);

    const getCardView = async () => {
        try {
            const resp = await appointmentService.getCardViewDetails(state?.practiceSessionId);
            setCardView(resp);
        } catch (error) {
            console.error("getCardView error", error)
        }
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 2,
                '.MuiContainer-root': {
                    margin: 0
                }
            }}>
            {
                (cardView && Object.keys(cardView).length) ? <Container maxWidth="xl">
                    <Typography variant={isMd ? 'h6' : 'h5'}>
                        Card View
                    </Typography>
                    <MainCard />
                    <QueueTabs />
                </Container> : (<Box sx={{ padding: '1rem !important' }}><CardViewSkeleton /></Box>)
            }
        </Box>
    )
}
