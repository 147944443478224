import { createContext } from 'react';


interface IAuthContext {
    isLogin: boolean;
    isLoading: boolean;
    login?: () => void;
}

const defaultState = {
    isLogin: false,
    isLoading: false
};

const AuthContext = createContext<IAuthContext>(defaultState);

export {
    AuthContext
};
