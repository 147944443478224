import React, { Component } from "react";
import SmsData from "../../jsonDumyData/smsData.json";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MyDatePicker from "../../components/MyDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { Stack } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {tableBody,tableBodyCell,mainContentStyle,contentTitleStyle,paperStyle} from "../../util/style"
import NoResultsMessage from "../../components/NoResultsMessage";
import SkeletonTableclass from "../../components/Skeleton";

interface Session {
  id:string;
  date:string;
  from:string;
  to:string;
  message:string;
  status:string;
  detailedStatus:string;
  sendOn:string;
}
interface ReportSmsProps {}
interface PaymentDetailsState {
    selectedDate: Dayjs | null;
    loading: boolean;
}

export default class ReportSms extends Component<ReportSmsProps, PaymentDetailsState> {
    state = { selectedDate: dayjs(),
              loading: true 
              };


    componentDidMount() {
      // Simulate a 2000ms (2 seconds) delay for skeleton
      setTimeout(() => {
        this.setState({ loading: false }); 
      }, 2000);
    }

    handlePrint = () => { window.print(); };
    handleDateSelection = (selectedDate: Dayjs | null) => { this.setState({ selectedDate }); };
    render() {
    const { selectedDate } = this.state;
    const formattedSelectedDate = selectedDate.format("DD-MM-YYYY").toLowerCase();
    const filterSmsData = SmsData.smsDummyData.filter((session: Session) => !selectedDate || session.date.toLowerCase() === formattedSelectedDate);
    const showRecordsFound = filterSmsData.length > 0;
    const loading: boolean = this.state.loading;
    
    return (
        <div>
        <Stack sx={{ ...mainContentStyle,overflowX: 'auto' }}>
            <Typography variant="h5" sx={{...contentTitleStyle}}>
              Sms Report
            </Typography>
            {loading ? (
            <SkeletonTableclass />
              ) :(
                <div style={{ overflowX: 'auto' }}> 
            <Paper elevation={2} sx={{ ...paperStyle,overflowX: 'auto'}}>
            <Stack sx={{ display: "flex", justifyContent: "space-between",flexDirection:'row',overflowX: 'auto' }}>
                <Stack sx={{ marginBottom: "25px" }}>
                <MyDatePicker onDateSelect={this.handleDateSelection} />
                </Stack>               
            </Stack>
            {showRecordsFound&&(<Stack display={"flex"} flexDirection={'row'} gap={'5px'} >Showing <strong>1-{filterSmsData.length}</strong> of <strong >{filterSmsData.length}</strong> items.</Stack>)}
            { (
               <TableContainer component={Stack}>
               <Table sx={{ minWidth: 650,overflowX: 'auto' }} aria-label="simple table">
               
               <TableHead >               
                    <TableRow>
                        <TableCell  style={{ ...tableBodyCell, width: '5.5%' }}>#</TableCell>
                        <TableCell style={{  ...tableBodyCell, width:'8%'}} align="left">To</TableCell>
                        <TableCell style={{  ...tableBodyCell, width:'17%'}} align="left">Message</TableCell>
                        <TableCell style={{  ...tableBodyCell,width:'15%' }} align="left">Status</TableCell>
                    </TableRow>                   
               </TableHead>   
                 <TableBody>
                   {filterSmsData.map((session: Session, index: number) => (
                     <TableRow key={index} sx={{ verticalAlign: 'top',  color: '#535252',border:'solid 1px #f1f1f1',backgroundColor:"#f8f7f7",...(index & 1 && { backgroundColor: '#ffffff' }) }}  >
                       <TableCell  sx={{ ...tableBody}} >{index+1}</TableCell>
                       <TableCell  sx={{ ...tableBody}} >{session.to}</TableCell>
                       <TableCell  sx={{ ...tableBody}} >{session.message}</TableCell>
                       <TableCell  sx={{ ...tableBody}} >{session.status}</TableCell>
                     </TableRow>
                   ))}
                 </TableBody>
               </Table>
             </TableContainer>
            )}
            
            { showRecordsFound ?"":
               <NoResultsMessage/>
            }
            </Paper>
            </div>
             )}
        </Stack>
        </div>
    );
    }
}

