// stackStyles.tsx
import { SxProps } from '@mui/system';


// mainContentStyle

export const mainContentStyle:SxProps={
  maxWidth: "97%", padding: "0px 2px", margin: "0 auto",
}

// title style
export const contentTitleStyle:SxProps = {
  fontWeight:'200', fontSize:'22px',color:"#5e5656",letterSpacing:'1px',marginBottom:'15px',
}

// paper style
export const paperStyle:SxProps = {
  padding: "2px 8px 25px 8px", backgroundColor: "#ffffff", borderRadius: "3px", boxShadow: "0 1px 0.1px rgba(0, 0, 0, 0.1), 0 -2.5px 0.1px rgba(0, 0, 0, 0.1)" 
}

// body rows style of the table
export const tableBody: SxProps={
  border:'solid 1px #f1f1f1' ,color: '#535252',textAlign:'left',
}

// heading rows style of the table
export const tableBodyCell: React.CSSProperties = {
  backgroundImage: "linear-gradient(to bottom, #fff, #ece2e2)",border: 'solid 1px #f1f1f1', textTransform: 'capitalize', color: 'black',textAlign:'left', verticalAlign: 'bottom',
};


