type ValidationResponse = {
    status: Boolean,
    message: String
}

class PracticeSessionSlotValidation {
    constructor(private sessionInformation: any) {
    }

    validate(): ValidationResponse {
        let resp: ValidationResponse = {
            status: false,
            message: ""
        }

        resp = this.checkSlotsTimes();
        if (!resp?.status)
            resp = this.checkSlotsConflicts();

        return resp;
    }

    private checkSlotsTimes(): any {
        const { consultingFromTime, consultingToTime, slots } = this.sessionInformation;

        if (!slots.length) return this.makeResponse(false, "");

        const [{ fromTime }] = slots;
        const [{ toTime }] = [...slots].reverse();

        if (this.convertTimeToMinutes(fromTime) < this.convertTimeToMinutes(consultingFromTime)
            || this.convertTimeToMinutes(toTime) > this.convertTimeToMinutes(consultingToTime)
        ) {
            return this.makeResponse(true, "Slot time should be in consulting start time to end time")
        }

        return this.makeResponse(false, "")

    }

    private checkSlotsConflicts(): any {
        const { slots } = this.sessionInformation;

        if (!slots.length || slots.length === 1) return this.makeResponse(false, "");
        const [{ fromTime, toTime }] = slots;

        let startTime = this.convertTimeToMinutes(fromTime);
        let endTime = this.convertTimeToMinutes(toTime);

        const conflict = slots.every((slot: any, indx: number) => {
            let status = true;
            const { fromTime, toTime } = slot;

            if ((indx !== 0 && this.convertTimeToMinutes(fromTime) < endTime) || this.convertTimeToMinutes(fromTime) < startTime || (indx !== 0 && this.convertTimeToMinutes(toTime) <= endTime)) {
                status = false;
            }

            startTime = this.convertTimeToMinutes(fromTime);
            endTime = this.convertTimeToMinutes(toTime);

            return status
        })

        if (!conflict) {
            return this.makeResponse(!conflict, "Slots time conflict, Please ensure that the time ranges of the slots do not conflict with each other");
        }

        return this.makeResponse(false, "");

    }

    private makeResponse(status: boolean, message: string): ValidationResponse {
        return {
            status,
            message
        }
    }

    private convertTimeToMinutes(time: string) {
        const [hour, minutes]: any = time.split(":");
        return (Math.floor(parseInt(hour) * 60) + parseInt(minutes));

    }

}

export default PracticeSessionSlotValidation;