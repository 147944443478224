import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

interface SkeletonProps {
    iteration?: number;
    isMd?: boolean;
}

const ListSessionCardSkeleton = (props: SkeletonProps) => {
    return (
        <Card sx={{ mb: '1rem', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container sx={{ padding: '0rem' }}>
                    <Grid item xs={12} sx={{
                        padding: '0rem',
                        ...(props.isMd && {
                            background: '#0c3376',
                            color: '#f9f9f9',
                            '& .schedule-datetime': {
                                color: '#f9f9f9',
                                fontWeight: '400'
                            }
                        })
                    }}>
                        <CardContent sx={{
                            flex: '1 0 auto',
                            textAlign: 'center',
                            padding: '6px 12px !important'
                        }}>
                            <Typography component="div" variant="h3">
                                <Skeleton />
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Grid item xs={9} sx={{
                        padding: '0rem',
                        ...(props.isMd && {
                            background: '#0c3376',
                            color: '#f9f9f9',
                            '& .schedule-datetime': {
                                color: '#f9f9f9',
                                fontWeight: '400'
                            }
                        })
                    }}>
                        <CardContent sx={{
                            flex: '1 0 auto',
                            textAlign: 'center',
                            padding: '0px 12px !important'
                        }}>
                            <Typography className="schedule-datetime" variant="subtitle2" color="text.secondary" component="div">
                                <div><Skeleton /></div>
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Grid container >
                        <Grid item xs={4} sm container sx={{ padding: '0rem' }}>
                            <CardContent sx={{
                                flex: '1 0 auto', justifyContent: 'center',
                                padding: '6px 12px !important'
                            }}>
                                <Typography component="div" variant="h6" >
                                    <Skeleton />
                                </Typography>
                                <Typography sx={{ mt: 1.5 }} component="div" variant="subtitle1" >
                                    <Skeleton />
                                </Typography>
                            </CardContent>
                        </Grid>
                        <Grid item xs={4} sm container sx={{ padding: '0rem' }}>
                            <CardContent sx={{
                                flex: '1 0 auto', justifyContent: 'center',
                                padding: '6px 15px !important'
                            }}>
                                <Typography component="div" variant="h6" >
                                    <Skeleton />
                                </Typography>
                                <Typography sx={{ mt: 1.5 }} component="div" variant="subtitle1" >
                                    <Skeleton />
                                </Typography>
                            </CardContent>
                        </Grid>
                        <Grid item xs={4} sm container sx={{ padding: '0rem' }}>
                            <CardContent sx={{
                                flex: '1 0 auto', justifyContent: 'center',
                                padding: '6px 15px !important'
                            }}>
                                <Typography component="div" variant="h6" >
                                    <Skeleton />
                                </Typography>
                                <Typography sx={{ mt: 1.5 }} component="div" variant="subtitle1" >
                                    <Skeleton />
                                </Typography>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}

export default ListSessionCardSkeleton