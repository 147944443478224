import React, { useEffect } from "react";
import { useTheme } from '@emotion/react';
import { Dialog, DialogContent, AppBar, Toolbar, Box, Container, Grid, Stack, Tooltip, Typography, useMediaQuery, Tabs, Tab } from '@mui/material';
import withHooks from '../../../utils/withHooks';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import ConnectTimingModal from './add-timing-modal';
import ConnectTimeMainCard from "./connect-timing-main-card";
import LeaveDaymenu from "./leave-day-main-card";
import SettingSkeleton from "./settings-skeleton";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import advancedSetttingServiceSerive from "../../../services/http/advanced-settings/advanced-settings.services";
import { getAttributeFromToken } from "../../../hooks/use-auth";

type SettingTabTypes = "connect-timing" | "leave-day-menu";

interface AdvancedSettingPageProps {
    theme: any;
    isMd: boolean;
}

interface ITabType {
    name: SettingTabTypes,
    value: number
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function AdvancedSetting(props: AdvancedSettingPageProps) {
    const { isMd } = props
    const [openModal, setOpenModal] = useState(false);
    const [connectTimings, setConnectTiming] = useState<any[]>([]);
    const [selectedSes, setSelectedSes] = useState<number>(0);
    const [isNewTiming, setIsNewTiming] = useState<boolean>(false);
    const [tab, setValue] = useState<ITabType>({ value: 0, name: "connect-timing" });
    const [isShow, setIshow] = useState<boolean>(true);
    const { value } = tab;

    useEffect(() => {
        getConnectTimingDet();
        return () => { }
    }, [])

    async function getConnectTimingDet() {
        try {
            const partyId = getAttributeFromToken('partyId')
            const resp = await advancedSetttingServiceSerive.getConnectTimingList(partyId);

            resp.map((item: any) => {
                item["checked"] = !!item?.isConnect;
                return item;
            });


            setConnectTiming(resp);
            setIshow(false);
        } catch (error) {
            console.error("Error in getConnectTimingDet", error);
        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        let tabName = "connect-timing";

        switch (newValue) {
            case 0:
                tabName = "connect-timing"
                break;
            case 1:
                tabName = "leave-day-menu"
                break;
            default:
                break;
        }
        setValue({ value: newValue, name: tabName } as ITabType);
    };

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    '.MuiContainer-root': {
                        margin: 0
                    }
                }}
            >
                {
                    !isShow ? (
                        <React.Fragment>
                            {/**Header */}
                            <Container sx={{ width: '100%', maxWidth: '100% !important', pt: 2 }} >
                                <Grid container >
                                    <Grid item xs={12} md={8}>
                                        <Typography variant={isMd ? 'h6' : 'h5'} component="div" sx={{ flexGrow: 1, mt: 'auto', mb: 'auto' }}>
                                            Advanced Settings
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>

                            {/**Tabs and Main section */}
                            <Grid container>
                                <Grid item xs={12} md={8}>
                                    <Box sx={{
                                        padding: '1rem !important'
                                    }}>
                                        <Stack spacing={3}>
                                            <Grid container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Stack>
                                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                            <Tab label="Connect Timing" />
                                                            <Tab label="Leave Day Menu" />
                                                        </Tabs>
                                                    </Box>
                                                </Stack>
                                                {
                                                    value === 0 ? (<Stack alignSelf={'flex-start'} pt={.5}>
                                                        <Tooltip title={'Add'}>
                                                            <IconButton
                                                                edge="start"
                                                                color="inherit"
                                                                aria-label="close"
                                                                disabled
                                                                onClick={() => {
                                                                    setOpenModal(true);
                                                                    setIsNewTiming(true);
                                                                }}
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>) : ("")
                                                }
                                            </Grid>
                                            {value === 0 && (
                                                <ConnectTimeMainCard
                                                    setOpenModal={setOpenModal}
                                                    connectTimings={connectTimings}
                                                    getConnectTimingDet={getConnectTimingDet}
                                                    setSelectedSes={setSelectedSes}
                                                />
                                            )}
                                            {value === 1 && (<LeaveDaymenu />)}
                                        </Stack>
                                    </Box>

                                </Grid>

                            </Grid>
                        </React.Fragment>
                    ) : (<Box sx={{ padding: '1rem !important' }}><SettingSkeleton /></Box>)
                }

            </Box>

            {/**Add connect timing modal */}
            <Dialog
                fullScreen={isMd}
                open={openModal}
                TransitionComponent={Transition}
                sx={{
                    ".MuiPaper-root": {
                        width: "100%"
                    }
                }}
            >
                <AppBar sx={{
                    width: `${!isMd ? '500px' : 'auto'}`,
                    position: 'relative',
                    background: 'none',
                    boxShadow: 'none !important',
                    color: 'neutral.900'
                }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            Connect Timing
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            onClick={() => {
                                setOpenModal(!openModal);
                                setIsNewTiming(false);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <ConnectTimingModal
                        connectTimings={connectTimings}
                        selectedSes={selectedSes}
                        setOpenModal={setOpenModal}
                        getConnectTimingDet={getConnectTimingDet}
                        isNewTiming={isNewTiming}
                        setIsNewTiming={setIsNewTiming}
                    />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default withHooks(AdvancedSetting, [
    ['theme', useTheme],
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
    ['location', useLocation]
])