type ValidationResponse = {
    status: Boolean,
    message: String
}

class PracticeSessionValidation {
    constructor(private sessionInformation: any) {
    }

    validate(): ValidationResponse {
        let resp: ValidationResponse = {
            status: false,
            message: ""
        }

        resp = this.checkBookingTime();
        if (!resp?.status)
            resp = this.validateConsultingTime();
        if (!resp?.status)
            resp = this.validateBookingTime();

        return resp;
    }

    private checkBookingTime(): any {
        const { apptBookingToTime, consultingToTime } = this.sessionInformation;

        if (this.convertTimeToMinutes(apptBookingToTime) > this.convertTimeToMinutes(consultingToTime)) {
            return this.makeResponse(true, "Booking end time is greater than consulting end time")
        }

        return this.makeResponse(false, "")
    }

    private validateConsultingTime(): any {
        const { consultingFromTime, consultingToTime } = this.sessionInformation;

        if (this.convertTimeToMinutes(consultingFromTime) > this.convertTimeToMinutes(consultingToTime)) {
            return this.makeResponse(true, "Consulting start time is greater than consulting end time")
        }

        return this.makeResponse(false, "")
    }

    private validateBookingTime(): any {
        const { apptBookingFromTime, apptBookingToTime } = this.sessionInformation;

        if (this.convertTimeToMinutes(apptBookingFromTime) > this.convertTimeToMinutes(apptBookingToTime)) {
            return this.makeResponse(true, "Booking start time is greater than booking end time")
        }

        return this.makeResponse(false, "")
    }

    private makeResponse(status: boolean, message: string): ValidationResponse {
        return {
            status,
            message
        }
    }

    private convertTimeToMinutes(time: string) {
        const [hour, minutes]: any = time.split(":");
        return (Math.floor(parseInt(hour) * 60) + parseInt(minutes));

    }

}

// const practiceSessionValidation = new PracticeSessionValidation();
export default PracticeSessionValidation;