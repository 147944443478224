import  { Component } from "react";
import dayjs, { Dayjs } from "dayjs";
import { styled } from "@mui/system";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IoCalendar } from "react-icons/io5";
import { Stack } from "@mui/material";

const OpenPickerIcon = styled(IoCalendar)({
  color: "black",
  fontSize: "20px",
});

interface MyDatePickerProps {
  onDateSelect: (selectedDate: Dayjs | null) => void;
}

export default class MyDatePicker extends Component<MyDatePickerProps> {
  handleDateChange = (selectedDate: Dayjs | null) => {
    if (selectedDate) {
      console.log("Selected Date in MyDatePicker: ", selectedDate.format("DD-MMM-YYYY"));
      this.props.onDateSelect(selectedDate);
    }
  };

  render() {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <Stack>
            <DatePicker
              format="DD-MMM-YYYY"
              defaultValue={dayjs(new Date())}
              slots={{
                openPickerIcon: OpenPickerIcon,
              }}
              slotProps={{
                textField: {
                  variant: "outlined",
                  InputProps: {
                    style: {width: "23.5rem",padding: "0 18px",color: "#5c5959",height: "35px",paddingRight: "20px",borderRadius: "1px",overflow: "hidden",textOverflow: "ellipsis",
                    },
                  },
                },
              }}
              sx={{
                ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black !important",
                  border: "solid black 0.1px",
                },
                ".MuiInputBase-input": {
                  borderRight: "solid  #e0d8d8 1px",
                  fontSize: "16px",
                },
              }}
              onChange={this.handleDateChange}
            />
          </Stack>
        </DemoContainer>
      </LocalizationProvider>
    );
  }
}
