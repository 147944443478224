import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Card, CardActions, Drawer, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import withHooks from '../../../../utils/withHooks';
import { ApptContext } from '../../contexts/appointment.context';
import AppointmentFilter from './AppointmentFilter';


class AppointmentFilterDrawer extends React.Component<any, any, {}> {

    state: any;

    constructor(props: any) {
        super(props);

        this.state = {
            open: props.open ?? false,
            filterValues: {

            },
            formError: {
                isError: false,
                apptStatus: false,
                practiceSessionId: false,
            }
        }
    }

    formValidation() {
        const { filter } = this.props.apptContext;
        const state = this.state;
        this.setState({
            ...state,
            formError: {
                isError: !filter.practiceSessionId,
                apptStatus: false,
                practiceSessionId: !filter.practiceSessionId
            }
        });
    }


    render(): React.ReactNode {
        return <>
            <Drawer
                anchor={'right'}
                open={this.props.open}
                onClose={() => this.props.onClose()}
                variant="persistent"
                sx={{
                    position: "relative",
                    marginLeft: "auto",
                    width: 200,
                    "& .MuiPaper-root": {
                        borderRadius: '18px'
                    },
                    "& .MuiBackdrop-root": {
                        display: "none"
                    },
                    "& .MuiDrawer-paper": {
                        width: 300,
                        height: '85%',
                        top: "100px",
                        bottom: "10px",
                        right: "20px"
                    }
                }}
            >
                <Card sx={{ height: '100%' }}>
                    <Box role="temporary">
                        <Toolbar>
                            <Typography sx={{ flex: 1 }} variant="h6" component="div">
                                Filters
                            </Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                                onClick={() => this.props.onClose()}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </Box>
                    <AppointmentFilter filterValues={this.props.filterValues}
                        onFilter={() => {
                            this.props.apptContext.loadAppointments()
                        }}
                        onEmitValues={(e: any, isInit?: boolean) => {
                            this.setState({ filterValues: e })
                            if (isInit) {
                                this.props.onFilter(e);
                            }
                        }} formError={this.state.formError} 
                    />
                    {
                        this.props.isMd ? <CardActions sx={{ justifyContent: 'end', mt: '1rem' }}>
                            <Button onClick={() => this.props.onClose()}>Cancel</Button>
                            {/* <Button onClick={() => {
                                this.formValidation();
                                setTimeout(() => {
                                    if (!this.state.formError.isError) {
                                        this.props.onFilter(this.state.filterValues)
                                    }
                                }, 100);
                            }}>Apply Filter</Button> */}
                        </CardActions> : ''
                    }

                </Card>
            </Drawer>
        </>
    }
}

export default withHooks(AppointmentFilterDrawer, [
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
    ['theme', useTheme],
    ['apptContext', React.useContext, [ApptContext]]
]);