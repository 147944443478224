import React, { useContext, useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import callLogSerive from '../../services/http/call-logs/call-logs.services';
import { ApptCallLogsContext } from './context/call-logs.context';
import CallLogsSkeleton from './components/call-logs-skeleton';
import CallLogsHeader from './header/header';
import SessionsMainCard from './components/session-main-card';
import { getAttributeFromToken } from '../../hooks/use-auth';
import { format } from "date-fns";

export default function CallLogs() {
    const callLogCntx: any = useContext(ApptCallLogsContext);
    const { setSessionCallLogs, sessionCallLogs, date } = callLogCntx;
    const [noLogs, setIsNoLogs] = useState(false);

    useEffect(() => {
        getSessionCallLogs();
        return () => { }
        // eslint-disable-next-line
    }, []);


    /**
     * Get call log details for the selected date
     */
    const getSessionCallLogs = async () => {
        try {
            const partyId = getAttributeFromToken('partyId')
            const resp = await callLogSerive.getCallLogs(partyId, format(date, "yyyy-MM-dd"));
            if (!resp.length) setIsNoLogs(true);
            setSessionCallLogs(resp);
        } catch (error) {
            console.error("Erron in getSessionCallLogs", error);
        }
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 2,
                '.MuiContainer-root': {
                    margin: 0
                }
            }}>
            <Container maxWidth="xl">
                <CallLogsHeader header="Call Logs" type="call-summary" />
                {
                    sessionCallLogs.length ? (<React.Fragment>
                        <SessionsMainCard />
                    </React.Fragment>) : (
                        <React.Fragment>
                            {
                                !noLogs ? (
                                    <CallLogsSkeleton />
                                ) : (
                                    <Typography variant="subtitle1" component="div" textAlign={"center"} sx={{ my: 2 }}>
                                        No Data
                                    </Typography>
                                )
                            }
                        </React.Fragment >)
                }
            </Container>
        </Box>
    )
}
