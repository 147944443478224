
import React, { createContext } from 'react';
import practiceSessionService from '../../../services/http/practice-sessions/practice-session.service';
import { format } from 'date-fns';
import { getAttributeFromToken } from '../../../hooks/use-auth';

export interface LeaveContextState {
    openApplyLeaveModal: boolean;
    leaveList: any[];
    sessionsList: any[];
}

type LeaveContextType = LeaveContextState & {
    loadLeaveLists: () => Promise<void>;
    loadSessionsList: () => Promise<void>;
};

export const LeaveContext = createContext<LeaveContextType | null>(null);

class LeaveContextProvider extends React.Component<any, LeaveContextState> {

    state: LeaveContextState;

    constructor(props: any) {
        super(props);

        this.state = {
            openApplyLeaveModal: false,
            leaveList: [],
            sessionsList: []
        }
    }

    loadLeaveLists = async (): Promise<void> => {
        try {
            const resp = await practiceSessionService.listLeaves({
                startWith: format(new Date(), 'yyyy-MM-dd')
            });
            this.setState({ leaveList: resp.data });
        } catch (error: any) {
            console.error(error.message)
        }
    }
    
    loadSessionsList = async (): Promise<any> => {
        try {
            if (this.state.sessionsList.length) {
                return this.state.sessionsList;
            }
            const { data } = (await practiceSessionService.listAllSessions({
                onlyActiveSessions: false,
                partyId: getAttributeFromToken('partyId')
            })).data;
            this.setState({ sessionsList: data });
            return data;
        } catch (error: any) {
            console.error(error.message)
        }
    }

    private contextValues = () => ({
        ...this.state,
        loadLeaveLists: this.loadLeaveLists,
        loadSessionsList: this.loadSessionsList
    })

    render(): React.ReactNode {
        return (
            <LeaveContext.Provider value={this.contextValues()}>
                {this.props.children}
            </LeaveContext.Provider>
        )
    }
}


export default LeaveContextProvider;
