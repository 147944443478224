import { Button, ButtonGroup, Checkbox, Grid, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { addDays, endOfDay, format, startOfDay } from 'date-fns';
import React, { useContext } from 'react';
import ConfirmDialog from '../../../components/ConfirmDialog';
import practiceSessionService from '../../../services/http/practice-sessions/practice-session.service';
import withHooks from '../../../utils/withHooks';
import { PracticeSesContext } from '../../practice-session/contexts/practice-session.context';
import { timeStrFormat } from '../../../utils/common';

export const WeekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const weekDaysMapping = (days: any[]) => days.map((day: string) => WeekDays[parseInt(day) - 1]).join('-');
class ApplyLeave extends React.Component<any, any> {
    state: any;

    dateDefines = {
        startOfToday: startOfDay(new Date()),
        endOfToday: endOfDay(new Date()),
        startOfTommorrow: startOfDay(addDays(new Date(), 1)),
        endOfTommorrow: endOfDay(addDays(new Date(), 1))
    };

    dateRanges = {
        'Today': {
            range: () => ({
                fromDate: this.dateDefines.startOfToday,
                toDate: this.dateDefines.endOfToday,
            })
        },
        'Tommorrow': {
            range: () => ({
                fromDate: this.dateDefines.startOfTommorrow,
                toDate: this.dateDefines.endOfTommorrow,
            })
        },
        'Today&Tomorrow': {
            range: () => ({
                fromDate: this.dateDefines.startOfToday,
                toDate: this.dateDefines.endOfTommorrow,
            })
        }
    }

    constructor(props: any) {
        super(props);
        this.state = {
            practiceSesions: [],
            showApplyButton: !!props.showApplyButton,
            openSnackBar: false,
            selectAll: true,
            isFormSubmit: false,
            selectionRange: {
                fromDate: new Date(),
                toDate: new Date(),
                key: 'selection',
            },
            leaveForm: {

            },
            confirmDialog: false
        }
    }

    async componentDidMount(): Promise<void> {
        await this.leaveFormData();
    }

    async leaveFormData() {

        let { fromDate, toDate } = this.state.selectionRange;
        fromDate = format(new Date(fromDate), 'yyyy-MM-dd');
        toDate = format(new Date(toDate), 'yyyy-MM-dd');

        const { data } = (await practiceSessionService.listAllSessions({
            startDate: fromDate,
            endDate: toDate,
            withInRange: true,
            onlyActiveSessions: true,
            partyId: this.props.practiceSesContext.partyId
        })).data;
        let practiceSesions = data;

        this.setState({
            practiceSesions,
            selectAll: false,
            leaveForm: practiceSesions.reduce((intiVal: any, ses: any) => ({
                ...intiVal,
                [ses.practiceSessionId]: {
                    isError: false,
                    errorMessages: [],
                    checked: true,
                    disabled: false,
                    session: ses
                }
            }), {})
        });
        setTimeout(async () => {
            await this.applyLeave(true);
        }, 10);
    }

    handleDateChange = (value: any, attr: string) => {
        this.setDateRange({
            ...this.state.selectionRange,
            [attr]: value
        })
    }

    checkAndValidate = async (event: any, session: any) => {
        const checked = !this.state.leaveForm[session.practiceSessionId]?.checked;
        this.setState((state: any) => {
            return state = {
                leaveForm: {
                    ...state.leaveForm,
                    [session.practiceSessionId]: {
                        ...state.leaveForm[session.practiceSessionId],
                        checked,
                        isError: false,
                        errorMessages: [],
                        session
                    }
                }
            }
        });

        setTimeout(async () => {
            if (checked) {
                const { errors } = await practiceSessionService.applyLeaveValidate([{
                    sessionId: session.practiceSessionId,
                    fromDate: format(new Date(this.state.selectionRange.fromDate), 'yyyy-MM-dd'),
                    toDate: format(new Date(this.state.selectionRange.toDate), 'yyyy-MM-dd'),
                    validateOnly: true,
                    session
                }]);
                await this.showErrors(errors);
            }
        }, 10);

    }

    getApplyLeaveRequest(validateOnly = false) {

        let selectedSessions = Object.keys(this.state.leaveForm).filter((key: string) => this.state.leaveForm[key]['checked'])

        if (validateOnly === false) {
            selectedSessions = selectedSessions
                .filter((key: string) => this.state.leaveForm[key]['checked'] && !this.state.leaveForm[key]['disabled'])
        }

        return selectedSessions.map((key: string) => ({
            sessionId: parseInt(key),
            fromDate: format(new Date(this.state.selectionRange.fromDate), 'yyyy-MM-dd'),
            toDate: format(new Date(this.state.selectionRange.toDate), 'yyyy-MM-dd'),
            validateOnly,
            session: this.state.leaveForm[key]['session']
        }))
    }

    applyLeave = async (validateOnly = false) => {
        this.setState({ isFormSubmit: true });
        const fromDate = new Date(this.state.selectionRange.fromDate);
        const toDate = new Date(this.state.selectionRange.toDate);
        if (fromDate.getTime() > toDate.getTime()) {
            return;
        }

        try {
            const request = this.getApplyLeaveRequest(validateOnly);
            const { errors } = await practiceSessionService.applyLeaveValidate(request);
            await this.showErrors(errors);
            if (errors.length === 0 && validateOnly === false) {
                this.props.appContext.toast({ open: true, message: 'Leave applied Success' });
                this.props?.refresh(true);
            }
        } catch (error) {
            if (validateOnly === false) {
                this.props.appContext.toast({ open: true, severity: 'error', message: 'Something went wrong' });
            }
        }
        await this.setState({ isFormSubmit: false, confirmDialog: false });
    }

    isFormError = () => {
        return Object.entries(this.state.leaveForm).filter((entry: any) => entry[1]?.isError && !entry[1]?.disabled).length > 0
    }

    isSelectAll = () => {
        return this.state.practiceSesions.length === Object.entries(this.state.leaveForm).filter((entry: any) => entry[1]?.checked).length
    }

    isFormDisabled = () => {
        return this.isFormError()
            || Object.entries(this.state.leaveForm).filter((entry: any) => entry[1]?.checked && !entry[1]?.disabled).length === 0
            || this.state.isFormSubmit
    }

    async showErrors(errors: any[]) {
        if (errors.length) {
            errors.forEach(({ sessionId, error }: any) => {
                this.setState((state: any) => {
                    return state = {
                        leaveForm: {
                            ...state.leaveForm,
                            [sessionId]: {
                                checked: true,
                                isError: true,
                                errorMessages: error.map((e: any) => (e.message)),
                                disabled: true
                            }
                        }
                    }
                })
            });
        }
    }

    composedErrorMessage = (messages: any[]) => {
        return (messages || []).map((msg: any, key: number) => (
            <ListItemText
                key={key}
                secondary={`${msg}`}
                sx={{
                    '& .MuiTypography-root': {
                        color: 'error.main',
                        fontSize: '0.8rem',
                        textAlign: 'center'
                    }
                }}
            />
        ))
    }

    setDateRange({ fromDate, toDate }: any) {
        this.setState({
            selectionRange: {
                ...this.state.selectionRange,
                fromDate,
                toDate
            }
        });
        this.resetLeaveForm();
        setTimeout(() => {
            this.leaveFormData();
        }, 10);
    }

    resetLeaveForm() {
        this.setState({
            leaveForm: this.state.practiceSesions.reduce((intiVal: any, ses: any) => ({
                ...intiVal,
                [ses.practiceSessionId]: {
                    isError: false,
                    errorMessages: [],
                    checked: true
                }
            }), {})
        });
    }

    handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ toast: { ...this.state.toast, open: false } });
        this.props.onClose();
    };

    weekDaysMapping = (days: any[]) => days.map((day: string) => WeekDays[parseInt(day) - 1]).join('-');

    render(): React.ReactNode {
        const { leaveForm } = this.state;
        return <>

            <Stack>
                <Typography variant="subtitle2" color="text.secondary">
                    Choose Date
                </Typography>
            </Stack>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    mt: '0.30rem',
                    ...(this.props.isSm && {
                        '& .MuiButtonGroup-root': {
                            width: '100%'
                        }
                    })
                }}
                justifyContent={'center'}
            >
                <ButtonGroup
                    disableElevation
                    variant="text"
                    aria-label="Disabled elevation buttons"
                    sx={{
                        border: `1px solid ${this.props.theme.palette.primary.main}`
                    }}
                >
                    <Button size="small" onClick={() => this.setDateRange(this.dateRanges.Today.range())}>Today</Button>
                    <Button size="small" onClick={() => this.setDateRange(this.dateRanges.Tommorrow.range())}>Tomorrow</Button>
                    <Button size="small" onClick={() => this.setDateRange(this.dateRanges['Today&Tomorrow'].range())}>Today & Tomorrow</Button>
                </ButtonGroup>
            </Stack>
            <Stack>
                <Typography variant="caption" textAlign={'center'} color={'text.secondary'} paddingTop={'0.25rem'}>
                    OR
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ mt: '0.25rem' }} justifyContent={'center'}>
                <DemoItem>
                    <MobileDatePicker
                        label={'From'}
                        sx={{
                            label: { top: '-4px', fontSize: '13px' },
                            input: { pt: '20px', pb: '5px' }
                        }}
                        closeOnSelect={true}
                        minDate={new Date()}
                        value={this.state.selectionRange.fromDate}
                        onChange={(event) => this.handleDateChange(event, 'fromDate')}
                        format="dd-MMMM-yyyy"
                    />
                </DemoItem>
                <DemoItem>
                    <MobileDatePicker
                        label={'To'}
                        sx={{
                            label: { top: '-4px', fontSize: '13px' },
                            input: { pt: '20px', pb: '5px' }
                        }}
                        closeOnSelect={true}
                        minDate={this.state.selectionRange.fromDate}
                        value={this.state.selectionRange.toDate}
                        onChange={(event) => this.handleDateChange(event, 'toDate')}
                        format="dd-MMMM-yyyy"
                    />
                </DemoItem>
            </Stack>

            <Stack spacing={2} sx={{ mt: '1rem' }} direction={'column'}>
                <Typography variant="subtitle2" component={'div'} sx={{ my: 'auto' }} color="text.secondary" >
                    <span>Choose Sessions </span>
                </Typography>
                <Typography variant="caption" component={'div'}  sx={{mt: '5px !important'}} color="text.secondary" >
                    Sessions are selected by default. If you want to apply leave for particular session. Please de-select others
                </Typography>
            </Stack >

            <Stack spacing={2} sx={{ mt: '1rem' }}>

                {
                    this.state.practiceSesions.map((session: any, i: number) => (
                        <Card key={i}>
                            <Grid container spacing={2} sx={{ padding: '0.2rem !important' }}>

                                <Grid item xs={2} md={1}>
                                    <Checkbox
                                        disabled={leaveForm[session.practiceSessionId]?.disabled}
                                        checked={leaveForm[session.practiceSessionId]?.checked}
                                        onChange={(event) => this.checkAndValidate(event, session)} />
                                </Grid>
                                <Grid item xs={9} md={9} sx={{ my: 'auto !important' }}>
                                    <Stack sx={{ padding: '0.2rem !important' }}>
                                        <Typography variant="subtitle2" sx={{ fontWeight: '600' }}>
                                            {session.orgName}, {session.area}
                                        </Typography>
                                        <Typography variant="body2">
                                            {timeStrFormat(session.consultingFromTime)} - {timeStrFormat(session.consultingToTime)}
                                        </Typography>
                                        <Typography variant="body2">
                                            {session.day ? this.weekDaysMapping(session.day.split(',')) : `Custom (${format(new Date(session.psDate), 'dd-MM-yyyy')})`}
                                        </Typography>
                                    </Stack>

                                </Grid>
                                {
                                    leaveForm[session.practiceSessionId]?.isError && <Grid item xs={12} md={12} sx={{ pt: '0px !important' }}>
                                        <List>
                                            {this.composedErrorMessage(leaveForm[session.practiceSessionId]?.errorMessages)}
                                        </List>
                                    </Grid>
                                }
                            </Grid>
                        </Card>
                    ))
                }
            </Stack>
            <Stack spacing={2} justifyContent="center"
                alignItems="flex-end" sx={{ mt: '1rem' }}>
                {
                    this.state.showApplyButton && <Button size="small" variant="outlined" disabled={this.isFormDisabled()} onClick={async () => {
                        this.setState({ confirmDialog: true });
                    }}>Apply</Button>
                }
            </Stack>

            <ConfirmDialog
                title={`Leave Apply Confirmation`}
                body="Would you like to apply the leave?"
                open={this.state.confirmDialog}
                buttonProps={
                    [{
                        label: 'No, Thanks'
                    },
                    {
                        label: 'Ok',
                        disabled: this.state.isFormSubmit
                    }]
                }
                onClose={() => {
                    this.setState({ confirmDialog: false });
                }}
                confirmCallback={async () => {
                    this.setState({ isFormSubmit: true });
                    await this.applyLeave();
                }} />
        </>
    }
}

export default withHooks(ApplyLeave, [
    ['practiceSesContext', useContext, [PracticeSesContext]]
]);
