import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
import BellIcon from '@heroicons/react/24/solid/BellIcon';
import UsersIcon from '@heroicons/react/24/solid/UsersIcon';
import {
	Avatar,
	Badge,
	Box,
	Button,
	IconButton,
	Stack,
	SvgIcon,
	Tooltip,
	useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';

import { useTheme } from '@emotion/react';
import React, { useContext } from 'react';
import { common } from '../../../../../config/config';
import { usePopover } from '../../../../../hooks/use-popover';
import { ApptContext } from '../../../../../pages/appointments/contexts/appointment.context';
import settingService from '../../../../../services/http/settings/setting.service';
import withHooks from '../../../../../utils/withHooks';
import { AccountPopover } from '../account-popover/AccountPopover';
import editProfileService from '../../../../../services/http/edit-profile/edit-profile-service';
import { getAttributeFromToken } from '../../../../../hooks/use-auth';
import { AppContext } from '../../../../../contexts/app.context';

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 57;

class TobBar extends React.Component<any> {
	state: any;

	constructor(props: any) {
		super(props);
		this.state = {
			imageUrl: "/assets/avatars/avatar-anika-visser.png"
		}
	}

	async componentDidMount(): Promise<void> {
		try {
			const partyId = getAttributeFromToken('partyId')
			const resp = await editProfileService.getAccountProfile(partyId);
			
			this.props.appContext.setUserProfile({ imageUrl: resp?.image_path ? resp?.image_path : "/assets/avatars/avatar-anika-visser.png" })
		} catch (error) {
			console.log(error)
			console.error("Error in get user image uri")
		}
	}

	render(): React.ReactNode {
		const fromPartyId = this.props.apptContext.partyId;
		console.log("Re Render", this.props.appContext)
		const imageUrl = `${this.props.appContext.userProfile.imageUrl}?time=${Date.now()}`;
		return <>
			<Box
				component="header"
				sx={{
					backdropFilter: 'blur(6px)',
					backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
					position: 'sticky',
					left: {
						lg: `${!this.props.isOpen ? SIDE_NAV_WIDTH : 0}px`
					},
					top: 0,
					width: {
						lg: `calc(100% - ${!this.props.isOpen ? SIDE_NAV_WIDTH : 0}px)`
					},
					zIndex: (theme) => theme.zIndex.appBar
				}}
			>
				<Stack
					alignItems="center"
					direction="row"
					justifyContent="space-between"
					spacing={2}
					sx={{
						minHeight: TOP_NAV_HEIGHT,
						px: 2
					}}
				>
					<Stack
						alignItems="center"
						direction="row"
						spacing={2}
					>
						{true && (
							<IconButton onClick={this.props.onNavOpen}>
								<SvgIcon fontSize="small">
									<Bars3Icon />
								</SvgIcon>
							</IconButton>
						)}
					</Stack>
					<Stack
						alignItems="center"
						direction="row"
						spacing={2}
					>
						<Tooltip title="Switch to V1">
							<Button onClick={async () => {
								await settingService.savePreferedUi(fromPartyId, 'legacy');
								window.location.replace(common.schedulaLegacyAppUrl as string)
								// if (common.schedulaLegacyAppUrl) {
								// 	removeCookie('PREFERRED_UI_URL', {
								// 		domain: `.${common.schedulaDomainName}`
								// 	});
								// 	setCookie('PREFERRED_UI_URL', common.schedulaLegacyAppUrl, {
								// 		domain: `.${common.schedulaDomainName}`
								// 	})
								// 	setTimeout(() => {
								// 		window.location.replace(common.schedulaLegacyAppUrl as string)
								// 	}, 1000);
								// }
							}} sx={{ fontSize: this.props.isMd ? '11px' : '15px' }}>Switch To Old UI</Button>
						</Tooltip>
						<Tooltip title="Contacts">
							<IconButton>
								<SvgIcon fontSize="small">
									<UsersIcon />
								</SvgIcon>
							</IconButton>
						</Tooltip>
						<Tooltip title="Notifications">
							<IconButton>
								<Badge
									badgeContent={4}
									color="success"
									variant="dot"
								>
									<SvgIcon fontSize="small">
										<BellIcon />
									</SvgIcon>
								</Badge>
							</IconButton>
						</Tooltip>
						<Avatar
							onClick={this.props.accountPopover.handleOpen}
							ref={this.props.accountPopover.anchorRef}
							sx={{
								cursor: 'pointer',
								height: 40,
								width: 40
							}}
							src={imageUrl}
						/>
					</Stack>
				</Stack>
			</Box>
			<AccountPopover
				anchorEl={this.props.accountPopover.anchorRef.current}
				open={this.props.accountPopover.open}
				onClose={this.props.accountPopover.handleClose}
			/>
		</>
	}
}

export default withHooks(TobBar, [
	['theme', useTheme],
	['accountPopover', usePopover],
	['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
	['apptContext', useContext, [ApptContext]],
	['appContext', useContext, [AppContext]],
])