import {
    AppBar,
    Box,
    Container,
    Grid,
    IconButton,
    Stack,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
    CardContent
} from '@mui/material';
import React, { Component, MouseEvent, RefObject, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import 'react-swipeable-list/dist/styles.css';

import withHooks from '../../utils/withHooks';
import PracticeSessionCard from '../practice-session/components/PracticeSessionCard';
import ManageSlotModal from '../slots/components/manage-slots/ManageSlotModal';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { PracticeSesContext } from '../practice-session/contexts/practice-session.context';
import { ApptContext } from './contexts/appointment.context';
import ApptmSummarySkeleton from "./components/skeleton/appointmentSummarySkeleton";
import AddAppointmentModal from './components/add-appointment/AddAppointmentModal';

interface AppointmentSummaryPageProps {
    theme: any;
    isMd: boolean;
    apptContext: any;
    practiceSesContext: any;
}

interface AppointmentSummaryPageState {
    isApptRescheduleDrawerOpen: boolean;
    isFilterDrawerOpen: boolean;
    openFilterModal: boolean;
    openAddApptModal: boolean;
    expandSearchBar: boolean;
    showPageHeader: boolean;
    searchStr: string;
    rescheduleAnchorEl: HTMLElement | null;
    apptRequest: {
        scheduleOn: string;
        practiceSessionId: string;
        apptStatus: string;
    };
    hasMore: boolean;
}

class AppointmentSummaryPage extends Component<AppointmentSummaryPageProps, AppointmentSummaryPageState> {
    apptFilterModalRef: RefObject<any>;
    addApptModalRef: RefObject<any>;
    refreshDataInterval: any;
    refreshRateMs = 60000;

    constructor(props: AppointmentSummaryPageProps) {
        super(props);

        this.state = {
            isApptRescheduleDrawerOpen: false,
            isFilterDrawerOpen: true,
            openFilterModal: false,
            openAddApptModal: false,
            expandSearchBar: false,
            showPageHeader: true,
            searchStr: '',
            rescheduleAnchorEl: null,
            apptRequest: {
                scheduleOn: '2023-03-31',
                practiceSessionId: '',
                apptStatus: '1',
            },
            hasMore: false,
        };

        this.apptFilterModalRef = React.createRef();
        this.addApptModalRef = React.createRef();
        this.setApptRescheduleDrawer = this.setApptRescheduleDrawer.bind(this);
    }

    async componentDidMount(): Promise<void> {
        try {
            this.setState({ openFilterModal: !this.props.isMd });
            this.refreshDataInterval = setInterval(this.apptContext.refreshAppointments, this.refreshRateMs);
            this.apptContext.setFilterValues('scheduleOn', new Date());
            await this.practiceSesContext.loadPracticeSessions();
        } catch (error) {
            console.error("Erro in componentDidMount", error);
        }

    }

    componentWillUnmount(): void {
        clearInterval(this.refreshDataInterval);
    }

    get apptContext() {
        return this.props.apptContext;
    }

    get practiceSesContext() {
        return this.props.practiceSesContext;
    }

    async toggleFilterDrawer() {
        this.setState({ isFilterDrawerOpen: !this.state.isFilterDrawerOpen });
    }

    fetchMoreData = () => {
        this.setState({ hasMore: false });
    };

    setFilterValues = (event: any) => {
        setTimeout(async () => await this.apptContext.loadAppointments(), 1);
    };

    get isClinicOnLeave() {
        return this.apptContext.selectedPracticeSession?.isClinicLeave;
    }

    getAppointments(): any {
        return this.apptContext.appointments.filter((appt: any) => appt?.isFilter === undefined || appt.isFilter === true);
    }

    onClickSelectAllAppt(): any {
        this.apptContext.selectAllAppointments(false);
    }

    setApptRescheduleDrawer(event: MouseEvent<HTMLElement>) {
        this.setState({ rescheduleAnchorEl: event ? event.currentTarget : null });
    }

    render(): React.ReactNode {
        const { practiceSessions } = this.practiceSesContext;
        const { theme, isMd } = this.props;

        return (
            <>
                <Box component="main" sx={{ flexGrow: 1, pb: 2 }}>
                    <AppBar position="sticky" sx={{ background: theme.palette.background.default, boxShadow: 'none !important', color: 'neutral.900' }}>
                        <Toolbar>
                            <Grid container>
                                <Grid item xs={12} md={8}>
                                    <Stack direction="row" spacing={2}>
                                        <Typography variant={isMd ? 'h6' : 'h5'} component="div" sx={{ flexGrow: 1, mt: 'auto', mb: 'auto' }}>
                                            Appointment Summary
                                        </Typography>
                                        {
                                            !this.practiceSesContext.isClinicLeave && <React.Fragment>
                                                {
                                                    <Box>
                                                        <IconButton
                                                            edge="start"
                                                            color="inherit"
                                                            aria-label="close"
                                                            sx={{ mr: '1rem' }}
                                                            onClick={() =>
                                                                this.apptContext.setApptModal(true)
                                                            }
                                                        >
                                                            <Tooltip title="Add appointment">
                                                                <AddCircleOutlineIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Box>
                                                }

                                            </React.Fragment>
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Container sx={{ width: '100%', maxWidth: '100% !important' }}>
                        {
                            this.practiceSesContext.isClinicLeave === false ? (<Stack spacing={3}>
                                <Grid container sx={{ '& .infinite-scroll-component__outerdiv': { width: '100%' } }}>
                                    <InfiniteScroll
                                        style={{ width: '100%' }}
                                        dataLength={this.apptContext.appointments.length}
                                        next={this.fetchMoreData}
                                        hasMore={this.apptContext.hasMore}
                                        loader={<h4 hidden>Loading...</h4>}
                                        endMessage={<p style={{ textAlign: 'center' }}><b></b></p>}
                                        refreshFunction={() => this.apptContext.loadAppointments()}
                                        pullDownToRefresh={false}
                                        pullDownToRefreshThreshold={50}
                                        pullDownToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>}
                                        releaseToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>}
                                    >
                                        {practiceSessions && practiceSessions.length
                                            ? practiceSessions.map((session: any, indx: number) => (
                                                <Grid item xs={12} md={8} key={indx}>
                                                    <PracticeSessionCard {...session} showCardViewBtn={false} scheduleOn={this.apptContext.filter.scheduleOn} apptContext={this.apptContext} practiceSesContext={this.practiceSesContext} />
                                                </Grid>
                                            ))
                                            : <ApptmSummarySkeleton />}
                                    </InfiniteScroll>
                                </Grid>
                            </Stack>) : ("")
                        }

                        {
                            !this.practiceSesContext.practiceSessions.length && this.practiceSesContext.isClinicLeave && <Grid item xs={12}>
                                <CardContent sx={{
                                    flex: '1 0 auto',
                                    justifyContent: 'center',
                                    padding: '0px 0px 1rem 1rem !important'
                                }}>
                                    <Typography variant="h6" color="text.secondary" component="div" sx={{ textAlign: 'center' }}>
                                        Clinic is on leave
                                    </Typography>
                                </CardContent>
                            </Grid>
                        }
                        <ManageSlotModal open={this.practiceSesContext.isOpenManageSlotModal} />
                        <AddAppointmentModal filterValues={this.state.apptRequest} />
                    </Container>
                </Box>
            </>
        );
    }
}

export default withHooks(AppointmentSummaryPage, [
    ['theme', useTheme],
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
    ['apptContext', useContext, [ApptContext]],
    ['practiceSesContext', useContext, [PracticeSesContext]],
]);
