import {
    Box,
    Divider,
    Drawer,
    Stack,
    SvgIcon,
    useMediaQuery
} from '@mui/material';

import { useLocation } from 'react-router-dom';
import { SideNavItem } from '../side-menu-item/SideMenuItem';

import { useTheme } from '@emotion/react';
import { styled } from '@mui/material/styles';
import React from 'react';
import SimpleBar from 'simplebar-react';
import withHooks from '../../../../../utils/withHooks';
import { items } from '../config/Config';
import HistoryIcon from '@mui/icons-material/History';
import { getAttributeFromToken } from '../../../../../hooks/use-auth';
import { developerIdPass } from '../../../../../config/config';
import ExpandedNavbar  from "../side-expand-menu-bar/SideExpandMenuBar";
import CampaignExpandMenuBar from '../side-expand-menu-bar/CampaignExpandMenuBar';

export const Scrollbar = styled(SimpleBar)``;


class SideNav extends React.Component<any, any> {
    componentDidMount() {
        this.addCallLogsMenuItem();
    }
    addCallLogsMenuItem = () => {
        if (getAttributeFromToken('username') === developerIdPass.user && !items.some((item) => item.title === 'Call Logs Wip')) {
            items.push({
                title: 'Call Logs Wip',
                path: '/call-logs-wip',
                icon: (
                    <SvgIcon fontSize="small">
                        <HistoryIcon />
                    </SvgIcon>
                ),
            });
        }
    };

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
            this.addCallLogsMenuItem();
        });
    };
    

    render(): React.ReactNode {    
        const content = (
            <Stack
                sx={{
                    height: '100%',
                    '& .simplebar-content': {
                        height: '100%'
                    },
                    '& .simplebar-scrollbar:before': {
                        background: 'neutral.400'
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    }}
                >
                    <Box sx={{ p: 3 }}>
                        <Box

                            sx={{
                                display: 'inline-flex',
                                height: 32,
                                width: 32
                            }}
                        >
                            <img
                                src={`https://schedula.in/wp-content/uploads/2023/05/schedula.svg`}
                                loading="lazy"
                                alt={'Schedula Logo'}
                            />
                        </Box>

                    </Box>
                    <Divider sx={{ borderColor: 'neutral.700' }} />
                    <Box
                        component="nav"
                        sx={{
                            flexGrow: 1,
                            px: 2,
                            py: 3
                        }}
                    >
                        <Stack
                            component="ul"
                            spacing={0.5}
                            sx={{
                                listStyle: 'none',
                                p: 0,
                                m: 0
                            }}
                        >
                            {items.map((item: any) => {
                                const active = item.path ? (this.props.location.pathname === item.path) : false;

                                return (
                                    <SideNavItem
                                        active={active}
                                        disabled={item.disabled}
                                        external={item.external}
                                        icon={item.icon}
                                        key={item.title}
                                        path={item.path}
                                        title={item.title}
                                        onClose={this.props.onClose}
                                        isLg={this.props.isLg}
                                    />
                                );
                            })}
                         <ExpandedNavbar />
                         <CampaignExpandMenuBar/>
                        </Stack>
                    </Box>
                </Box>
            </Stack>
        );

        if (this.props.isLg) {
            return (
                <Drawer
                    anchor="left"
                    open={!this.props.open}
                    onClose={this.props.onClose}
                    PaperProps={{
                        sx: {
                            backgroundColor: 'neutral.800',
                            color: 'common.white',
                            width: 280
                        }
                    }}
                    variant="persistent"
                >
                    {content}
                </Drawer>
            );
        }

        return (
            <Drawer
                anchor="left"
                onClose={this.props.onClose}
                open={this.props.open}
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.800',
                        color: 'common.white',
                        width: 280
                    }
                }}
                sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
                variant="temporary"
            >
                {content}
            </Drawer>
        );
    }
}

export default withHooks(SideNav, [
    ['location', useLocation],
    ['theme', useTheme],
    ['isLg', (() => {
        return useMediaQuery((theme: any) => theme.breakpoints.up('lg'))
    })]
]);
