import React, { useEffect, useContext } from "react"
import { Box, Typography, Container, Card, Grid, CardContent, Stack, Button } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import callLogSerive from "../../../services/http/call-logs/call-logs.services";
import { useLocation, useNavigate } from "react-router-dom";
import CallLogsHeader from "../header/header";
import { getAttributeFromToken } from "../../../hooks/use-auth";
import { ApptCallLogsContext } from "../context/call-logs.context";
import { format } from "date-fns";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function CallLogsDetails() {
    const navigation = useNavigate();
    const callLogCntx: any = useContext(ApptCallLogsContext);
    const { callHistory, date, setCallHistory, setDate } = callLogCntx;
    const { state } = useLocation();

    useEffect(() => {
        getCallLogsDetails();
        setDate(state?.date ?? new Date());
        return () => { }
        // eslint-disable-next-line
    }, []);


    /**
     * Get call logs history for the selected session
     */
    const getCallLogsDetails = async () => {
        try {
            const partyId = getAttributeFromToken('partyId')
            const { practiceSessionId, logType } = state;
            const resp = await callLogSerive.getCallLogsDetails(partyId, format(state?.date, "yyyy-MM-dd"), practiceSessionId, logType);
            setCallHistory(resp);
        } catch (error) {
            setCallHistory([]);
            console.error("Erro in getCallLogsDetails", error);
        }
    }

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 2,
                    '.MuiContainer-root': {
                        margin: 0
                    }
                }}>
                <Container maxWidth="xl">
                    <CallLogsHeader header="Call History" type="call-details" />

                    <Grid container>
                        <Grid item xs={12} md={9} sx={{ mt: '.5rem' }}>
                            <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                                <Typography variant="subtitle1" sx={{ marginTop: "0px !important", textTransform: "capitalize" }}>
                                    {state?.logType}
                                </Typography>
                                <Button startIcon={<ArrowBackIcon />} onClick={() => navigation("/call-log", { state: { date } })}>
                                    Back
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>

                    {
                        callHistory?.length ? (<React.Fragment>
                            {
                                callHistory?.map((his: any, indx: number) => {
                                    return (
                                        <Grid container key={indx}>
                                            <Grid item xs={12} md={9} sx={{ mt: '.5rem' }}>
                                                <Card sx={{ padding: '0px' }}>
                                                    <Grid container spacing={2} sx={{ pt: '0px !important' }}>
                                                        <Grid item xs={12} md={12}>
                                                            <Stack
                                                                direction={"row"}
                                                                alignItems="center"
                                                                sx={{ padding: '1rem 1rem 0.5rem 1rem !important' }}
                                                            >
                                                                <WhatsAppIcon style={{ color: "#ffffff", background: "#008000", padding: "2px", borderRadius: "4px" }} />
                                                                <Typography variant="h6" component="span" sx={{ paddingInlineStart: "5px" }}>
                                                                    {his?.phoneNumber}
                                                                </Typography>
                                                                <Typography variant="caption" component="span" style={{ 'textTransform': "capitalize", paddingInlineStart: "5px" }}>
                                                                    ({his?.calledOnTime})
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        state?.logCategory !== "Others" && <Grid container sx={{ mb: '.75rem' }}>
                                                            <Grid item xs={6} md={6} lg={6} sm container
                                                                sx={{ pt: '0px !important' }}
                                                            >
                                                                <CardContent
                                                                    sx={{
                                                                        flex: '1 0 auto',
                                                                        justifyContent: 'center',
                                                                        padding: '0px 1rem 0rem 1rem !important',
                                                                        lineHeight: 0
                                                                    }}
                                                                >
                                                                    <Stack spacing={1}>
                                                                        <Typography variant="caption" color="text.secondary" component="div">
                                                                            Request Status
                                                                        </Typography>
                                                                        <Typography variant="subtitle1" sx={{ marginTop: "0px !important", textTransform: "capitalize", color: `${his?.apptReqStatus?.toLowerCase() !== "confirmed" ? "red" : ""}` }}>
                                                                            {his?.apptReqStatus?.toLowerCase() === "confirmed" ? his?.apptReqStatus : 'Not Confirmed'}
                                                                        </Typography>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Grid>
                                                            <Grid item xs={6} md={6} lg={6} sm container
                                                                sx={{ pt: '0px !important' }}
                                                            >
                                                                <CardContent
                                                                    sx={{
                                                                        flex: '1 0 auto',
                                                                        justifyContent: 'center',
                                                                        padding: '0px 1rem 0rem 1rem !important',
                                                                        lineHeight: 0
                                                                    }}
                                                                >
                                                                    <Stack spacing={1}>
                                                                        <Typography variant="caption" color="text.secondary" component="div">
                                                                            Message
                                                                        </Typography>
                                                                        <Typography variant="subtitle1" sx={{ marginTop: "0px !important", textTransform: "capitalize" }}>
                                                                            {his?.apptReqStatus?.toLowerCase() === "confirmed" ? `Appt number ${his?.apptNumber}` : his?.apptReqStatus}
                                                                        </Typography>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </React.Fragment>) : (
                            <Typography variant="subtitle1" component="div" textAlign={"center"} sx={{ my: 2 }}>
                                No Data
                            </Typography>
                        )
                    }

                </Container>
            </Box >
        </React.Fragment >
    )
}