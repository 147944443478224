import React, { useState, useContext } from "react";
import { Box, Tabs, Tab, Card, Grid } from "@mui/material";
import ListCard, { AppointmentsAttrEnum, TabType } from "./list-card";
import { ApptCardViewContext } from "../contexts/card-view.context";
import TabCardHeader from "./tab-card-header";

type QueueTabsProps = {
}

interface ITabType {
    name: TabType,
    value: number
}

export default function QueueTabs(props: QueueTabsProps) {
    const cardViewCnxt = useContext(ApptCardViewContext);
    const { cardView }: any = cardViewCnxt;
    const [tab, setValue] = useState<ITabType>({ value: 1, name: "appointments" });
    const { value, name } = tab;

    /**
     * Handle the tab changes
     * @param event 
     * @param newValue 
     */
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        let tabName = "appointments";

        switch (newValue) {
            case 0:
                tabName = "no-shows"
                break;
            case 1:
                tabName = "appointments"
                break;
            case 2:
                tabName = "in-queue"
                break;
            case 3:
                tabName = "checked-out"
                break;
            default:
                break;
        }
        setValue({ value: newValue, name: tabName } as ITabType);
    };

    /**
     * Get selected tab details
     * @param tab 
     * @returns 
     */
    const getCardData = (tab: string): any[] => {
        return cardView?.appointments[tab];
    }

    /**
     * check to show the header for select all and change status
     * @returns 
     */
    const isShowHeader = (): boolean => {
        const tabName = AppointmentsAttrEnum[tab?.name];
        const tabDetails = getCardData(tabName) as any;
        return tabDetails.some((tb: any) => tb?.checked);
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} md={9}>
                    <Card sx={{ mt: 2 }}>
                        <Box sx={{ width: '100%', padding: '1rem' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="No Shows" />
                                    <Tab label="Appointments" />
                                    <Tab label="In Queue" />
                                    <Tab label="Checked Out" />
                                </Tabs>
                            </Box>
                            {
                                isShowHeader() && <TabCardHeader tab={name} cardDetails={getCardData(AppointmentsAttrEnum[tab?.name])}/>

                            }
                            {
                                value === 0 && <ListCard tab={"no-shows"} cardDetails={getCardData("NoShows")} getCardData={getCardData} />
                            }
                            {
                                value === 1 && <ListCard tab={"appointments"} cardDetails={getCardData("Appointments")} getCardData={getCardData} />
                            }
                            {
                                value === 2 && <ListCard tab={"in-queue"} cardDetails={getCardData("InQueue")} getCardData={getCardData} />
                            }
                            {
                                value === 3 && <ListCard tab={"checked-out"} cardDetails={getCardData("CheckedOut")} getCardData={getCardData} />
                            }
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
