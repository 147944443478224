import { Component, ReactNode, useContext } from "react";
import { Navigate, useLocation } from "react-router";
import { AuthContext } from "../contexts/auth-context";
import useAuth from "../hooks/use-auth";
import withHooks from "../utils/withHooks";

class GuardedRoute extends Component<any, any> {

    render(): ReactNode {
        const { auth, children, authContex, location } =  this.props;
        if (authContex.isLoading === false) {
            return ''
        }

        if (auth && location.pathname !== '/login') {
            return authContex.isLogin === true ? children : <Navigate to="/login" replace state={{ path: location.pathname }} />
        }
        
        if (authContex.isLogin === true) {
            return <Navigate to="/appointments" replace state={{ path: location.pathname }} />
        }
        
        return children;
    }
}

export default withHooks(GuardedRoute, [
    ['auth', useAuth],
    ['location', useLocation],
    ['authContex', useContext, [AuthContext]],
])