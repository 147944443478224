import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import CustomSwitch from '../../../components/CustomSwitch';

class SlotListCard extends React.Component<any, any> {

    state: any;

    constructor(props: any) {
        super(props);

        this.state = {
            expanded: false,
            confirmDialog: false
        }
    }

    render(): React.ReactNode {
        return (
            <Card sx={{ padding: '0px', mb: '0.75rem' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={2} sx={{ pt: '0px !important' }}>
                        <Grid item xs={9} md={9}>
                            <Box sx={{
                                padding: '1rem !important'
                            }}>
                                <Typography variant="subtitle1" component="span">
                                    {`${this.props.fromTime} - ${this.props.toTime}`}
                                </Typography>
                                <Stack direction="row" spacing={1} sx={{
                                    marginTop: '1rem',
                                    '& .MuiChip-root': {
                                        height: 25
                                    }
                                }}>
                                    <Chip label={`Regular: ${this.props.regularSlots}`} />
                                    <Chip label={`WalkIn: ${this.props.walkinSlots}`} />
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Box sx={{
                                padding: '1rem 0px 0px 0px !important',
                                textAlign: 'center'
                            }}>
                                <Typography variant="caption" component="div">
                                    <CustomSwitch
                                        checked={this.props.status === 1 ? true : this.props.status}
                                        onChange={this.props.onToggleSlot}
                                    />
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        )
    }
}

export default SlotListCard;