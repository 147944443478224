import  { Component} from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { FaFolderOpen, FaWhatsapp } from "react-icons/fa";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useLocation } from "react-router-dom";

import { useTheme } from "@emotion/react";
import withHooks from "../../../../../utils/withHooks";

interface CampaignExpandMenuBarProps {
  theme: any;
  bulkWhatsAppContext: any;
  isMd: boolean;
}

interface CampaignExpandMenuBarState {
  expandedOuter: string | false;
  activeAccordionOuter: string | null;
}

class CampaignExpandMenuBar extends Component<
  CampaignExpandMenuBarProps,
  CampaignExpandMenuBarState
> {
  constructor(props: CampaignExpandMenuBarProps) {
    super(props);
    this.state = {
      expandedOuter: false,
      activeAccordionOuter: null,
    };
  }

  async componentDidMount(): Promise<void> {
    
  }

  handleChangeOuter = (isExpanded: boolean) => {
    this.setState({ expandedOuter: isExpanded ? "panel2" : false });
    this.setState({ activeAccordionOuter: isExpanded ? "Campaign" : null });
  };

  render() {
    const { expandedOuter, activeAccordionOuter } = this.state;
    console.log("bul",this.props)

    return (
      <div>
        <Accordion
          expanded={expandedOuter === "panel2"}
          onChange={(event, isExpanded) => this.handleChangeOuter(isExpanded)}
          disableGutters
          sx={{
            border: "0",
            boxShadow: "none",
            backgroundColor: "transparent",
            fontSize: "14px",
            paddingLeft: "2px",
            color: activeAccordionOuter === "Campaign" ? "#fff" : "neutral.400",
            whiteSpace: "nowrap",
          }}
        >
          <AccordionSummary
            id="panel1-header"
            aria-controls="panel1-content"
            sx={{
              backgroundColor: activeAccordionOuter === "Campaign" ? "#1c1b1b" : "",
              borderRadius: "8px",
              "&.expanded": {
                marginTop: "10px",
              },
            }}
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  transform:
                    activeAccordionOuter === "Campaign"
                      ? "rotate(180deg)"
                      : "rotate(90deg)",
                }}
              />
            }
          >
            <FaFolderOpen size="17" />
            <Typography sx={{ marginLeft: "1rem" }}>Campaign</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "0", paddingLeft: "5px" }}>
            <Link to="campaign/bulk-whatsapp">
              <Button
                sx={{
                  padding: "4px 52% 4px 20px",
                  color: "#fff",
                  backgroundColor: "transparent",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "#282f3d",
                  },
                }}
                startIcon={<FaWhatsapp size="17" />}
              >
                Bulk WhatsApp
              </Button>
            </Link>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default withHooks(CampaignExpandMenuBar, [
  ["theme", useTheme],
  ["isMd", () => useMediaQuery((theme: any) => theme.breakpoints.down("md"))],
  ["location", useLocation],
]);
