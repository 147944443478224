import React from "react"

export default function HoldableSelect({ id, onSingleClick, onHold, holdTimeMs, children }: any) {

    const [timer, setTimer] = React.useState(null)

    function onPointerDown(evt: any) {
        const event = { ...evt } // convert synthetic event to real object
        const timeoutId: any = window.setTimeout(timesup.bind(null, event), holdTimeMs || 500)
        setTimer(timeoutId)
    }

    function onPointerUp(evt: any) {
        if (timer) {
            window.clearTimeout(timer)
            setTimer(null)
            // onSingleClick(evt)
        }
    }

    function timesup(evt: any) {
        setTimer(null)
        onHold(evt)
    }

    return (
        <div
            onTouchStart={onPointerDown}
            onTouchEnd={onPointerUp}
            id={id}
        >
            {children}
        </div>
    )
}