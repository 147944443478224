import React, { useState, useEffect, useCallback } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MyDatePicker from "../../components/MyDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { Stack } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableBody, tableBodyCell, mainContentStyle, contentTitleStyle, paperStyle } from "../../util/style";
import NoResultsMessage from "../../components/NoResultsMessage";
import SkeletonTableclass from "../../components/Skeleton";
import jwtDecode from "jwt-decode";
import reportService from "../../../../services/http/reports/reports.service";

interface PaymentData {
    phone: string;
    amountReceived: string;
    amountSettled: string | null;
    settlementStatus: string | null;
    utr: string | null;
    settledOn: string | null;
}

interface PaymentReportProps {
    partyId: string;
}

const decodeToken = (token: any) => {
    try {
        return jwtDecode(token);
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};
const PaymentReport: React.FC<PaymentReportProps> = ({ partyId }) => {
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<PaymentData[]>([]);
    const [decodedToken, setDecodedToken] = useState<any>(null);
    const [hasFetchedData, setHasFetchedData] = useState(false);
   

    const fetchData = useCallback(async () => {
    try {
        const partyId = decodedToken?.partyId;
        const date=selectedDate?.format("YYYY-MM-DD").toLowerCase()
        if (partyId) {
            const paymentData = await reportService.getPayment({ partyId, Date: date });
            setData(paymentData);
          } else {
            console.log("Party ID is not available, skipping the API call.");
          }
    } catch (error:any) {
        console.error('Error:', error);
    }
  }, [selectedDate, decodedToken]);

  useEffect(() => {
      const fetchDataAndDecodeToken = async () => {
          try {
              const storedToken = sessionStorage.getItem('SCHEDULA_TOKEN');
              if (storedToken && !hasFetchedData) {
                  const decoded = await decodeToken(storedToken);
                  setDecodedToken(decoded);
                  await fetchData(); 
                  setLoading(false);
                  setHasFetchedData(true)
              }
          } catch (error) {
              console.error('Error fetching data or decoding token:', error);
          }
      };
      const delay = setTimeout(fetchDataAndDecodeToken, 2000);
      return () => clearTimeout(delay);
  },);
  useEffect(() => {
    
    if (hasFetchedData) {
      fetchData();
    }
  }, [selectedDate, fetchData, hasFetchedData]);
    const handleDateSelection = (newSelectedDate: Dayjs | null) => { setSelectedDate(newSelectedDate); };

    const showRecordsFound = data.length > 0;

    return (
        <div>
           
            <Stack sx={{ ...mainContentStyle }}>
                <Typography variant="h5" sx={{ ...contentTitleStyle }}>
                    Payment Details
                </Typography>
                {loading ? (
                    <SkeletonTableclass />
                ) : (
                    <Paper elevation={2} sx={{ ...paperStyle }}>
                        <Stack sx={{ display: "flex", justifyContent: "space-between", flexDirection: 'row' }}>
                            <Stack sx={{ marginBottom: "25px" }}>
                                <MyDatePicker onDateSelect={handleDateSelection} />
                            </Stack>
                        </Stack>
                        {showRecordsFound && (
                            <Stack display={"flex"} flexDirection={'row'} gap={'5px'} >
                                Showing <strong>1-{data.length}</strong> of <strong>{data.length}</strong> items.
                            </Stack>
                        )}
                        {(
                            <TableContainer component={Stack}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">

                                    <TableHead >
                                        <TableRow>
                                            <TableCell style={{ ...tableBodyCell, width: '0%' }}>#</TableCell>
                                            <TableCell style={{ ...tableBodyCell, width: '20%' }} align="left">Phone</TableCell>
                                            <TableCell style={{ ...tableBodyCell, width: '20%' }} align="left">Amount Received</TableCell>
                                            <TableCell style={{ ...tableBodyCell, width: '20%' }} align="left">Amount Settled</TableCell>
                                            <TableCell style={{ ...tableBodyCell, width: '20%' }} align="left">Settlement Status</TableCell>
                                            <TableCell style={{ ...tableBodyCell, width: '20%' }} align="left">UTR</TableCell>
                                            <TableCell style={{ ...tableBodyCell, width: '20%' }} align="left">Settled On</TableCell>           
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((payment: PaymentData, index: number) => (
                                            <TableRow key={index} sx={{ verticalAlign: 'top', color: '#535252', border: 'solid 1px #f1f1f1', backgroundColor: "#f8f7f7", ...(index & 1 && { backgroundColor: '#ffffff' }) }}>
                                                <TableCell sx={{ ...tableBody }}>{index + 1}</TableCell>
                                                <TableCell sx={{ ...tableBody }}>{payment.phone}</TableCell>
                                                <TableCell sx={{ ...tableBody }}>{payment.amountReceived}</TableCell>
                                                <TableCell sx={{ ...tableBody }}>{payment.amountSettled || 'N/A'}</TableCell>
                                                <TableCell sx={{ ...tableBody }}>{payment.settlementStatus || 'N/A'}</TableCell>
                                                <TableCell sx={{ ...tableBody }}>{payment.utr || 'N/A'}</TableCell>
                                                <TableCell sx={{ ...tableBody }}>{payment.settledOn || 'N/A'}</TableCell>           
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {data.length > 0 && (
                                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                                        <strong>Note:</strong><br />
                                            The final settlement amount will have some variations based on Service charges deducted for bank transfers. <br />
                                            For any queries or discrepancy in settlement amount, Contact Schedula @ WhatsApp Support group.
                                    </Typography>
                                )}
                            </TableContainer>
                        )}

                        {showRecordsFound ? "" :
                            <NoResultsMessage />
                        }
                    </Paper>
                )}
            </Stack>
        </div>
    );
};

export default PaymentReport;
