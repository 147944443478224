import { useTheme } from '@emotion/react';
import React, { useContext } from "react";
// import AddIcon from '@mui/icons-material/Add';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppBar, Box, Container, Grid, Stack, Typography, useMediaQuery, Tooltip, Skeleton } from '@mui/material';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import 'react-swipeable-list/dist/styles.css';
import ConfirmDialog from '../../components/ConfirmDialog';
import { AppContext } from '../../contexts/app.context';
import practiceSessionService from '../../services/http/practice-sessions/practice-session.service';
import withHooks from '../../utils/withHooks';
import ApplyLeaveModal from './components/ApplyLeaveModal';
import { LeaveContext } from './contexts/leave.context';
import { weekDaysMapping } from './components/ApplyLeave';

class LeavePage extends React.Component<any, any> {
    state: any;

    constructor(props: any) {
        super(props);
        this.state = {
            confirmDialog: false,
            loading: true,
            openApplyLeaveModal: false,
            leaveList: [],
            selectedLeaveIds: [],
            formSubmit: false
        }
    }

    async componentDidMount(): Promise<void> {
        await this.setState({ loading: true });
        await this.props.leaveContext.loadLeaveLists();
        await this.setState({ loading: false });
    }

    deleteLeaves = async () => {
        try {
            await this.setState({ formSubmit: true });
            const resp = await practiceSessionService.deleteLeave({ leaveIds: this.state.selectedLeaveIds });
            await this.props.appContext.toast({ open: true, message: resp.message });
            await this.props.leaveContext.loadLeaveLists();
            await new Promise((resolve) => setTimeout(resolve, 100))
            await this.setState({ confirmDialog: false, selectedLeaveIds: [], formSubmit: false });
        } catch (error: any) {
            await this.props.appContext.toast({ open: true, message: error.message });
        }
    }

    render(): React.ReactNode {
       
        return <Box
            component="main"
            sx={{
                flexGrow: 1,
                pb: 2
            }}
        >

            <AppBar position="sticky" sx={{
                background: this.props.theme.palette.background.default,
                boxShadow: 'none !important',
                color: 'neutral.900',
            }}>
                <Toolbar>
                    <Grid container>
                        <Grid item xs={12} md={8}>
                            <Stack direction="row" spacing={2}>
                                <Typography variant={this.props?.isMd ? 'h6' : 'h5'} component="div" sx={{ flexGrow: 1, mt: 'auto', mb: 'auto' }} >
                                    Leave
                                </Typography>
                                <Box>
                                    <Tooltip title={'Apply Leave'}>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            aria-label="close"
                                            onClick={() => this.setState({ openApplyLeaveModal: !this.state.openApplyLeaveModal })}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Container sx={{
                width: '100%',
                maxWidth: '100% !important'
            }}>

                <Stack
                    spacing={3}>
                    <Grid container sx={{
                        '& .infinite-scroll-component__outerdiv': {
                            width: '100%'
                        }
                    }}>
                        <Grid item xs={12} md={8}>
                            {
                                this.props.leaveContext.leaveList.map((leave: any, i: number) => (
                                    <Card key={i} sx={{ mb: '1rem' }}>
                                        <Grid container spacing={2} sx={{
                                            padding: '1rem !important',
                                            '& .MuiGrid-item': {
                                                paddingTop: '10px'
                                            }
                                        }}>
                                            <Grid item xs={1} md={1} sx={{ my: 'auto !important' }}>
                                                {i + 1}
                                            </Grid>
                                            <Grid item xs={10} md={10} sx={{ my: 'auto !important' }}>
                                                <Typography variant="subtitle1">
                                                    {leave.orgName}, {leave.area}
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    {leave.consultingFromTime} - {leave.consultingToTime}
                                                </Typography>
                                                {
                                                    <Typography variant="caption">
                                                        {leave?.psDate ? `Custom (${leave.psDate})` : weekDaysMapping(leave.days.split(','))}
                                                    </Typography>
                                                }
                                            </Grid>

                                            <Grid item xs={1} md={1} sx={{ my: 'auto !important' }}>
                                                <Typography color="text.primary" component="div">
                                                    <Tooltip title={'Delete Leave'}>
                                                        <Typography color={'text.secondary'}>
                                                            <IconButton
                                                                edge="start"
                                                                color="inherit"
                                                                aria-label="close"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        confirmDialog: !this.state.confirmDialog,
                                                                        selectedLeaveIds: [leave.id]
                                                                    })
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Typography>
                                                    </Tooltip>
                                                </Typography>
                                            </Grid>
                                            <Grid item container xs={12} md={12} sx={{ pt: '0px !important', pl: '36px !important' }}>
                                                <Grid item xs={4} md={4} sx={{ my: 'auto !important' }}>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Leave From
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        {leave.fromDate}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} md={4} sx={{ my: 'auto !important' }}>

                                                    <Typography variant="caption" color="text.secondary">
                                                        Leave To
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        {leave.toDate}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} md={4} sx={{ my: 'auto !important' }}>

                                                    <Typography variant="caption" color="text.secondary">
                                                        Applied On
                                                    </Typography>
                                                    <Typography variant="subtitle2">
                                                        {leave.appliedOn}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                ))
                            }


                            {
                                (this.state.loading ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] : []).map((leave: any, i: number) => (
                                    <Card key={i} sx={{ mb: '1rem' }}>
                                        <Grid container spacing={2} sx={{
                                            padding: '1rem !important',
                                            '& .MuiGrid-item': {
                                                paddingTop: '10px'
                                            }
                                        }}>

                                            <Grid item xs={12} md={12} sx={{ my: 'auto !important' }}>
                                                <Typography variant="subtitle1">
                                                    <Skeleton width={250} />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12} sx={{ my: 'auto !important' }}>
                                                <Typography variant="subtitle2">
                                                    <Skeleton width={200} />
                                                </Typography>
                                                <Typography variant="caption">
                                                    <Skeleton width={175} />
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    </Card>
                                ))
                            }
                        </Grid>

                        {
                            this.state.loading === false && this.props.leaveContext.leaveList.length === 0 ?
                                <Grid item xs={12} md={8} sx={{ pt: '120px', display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        No Leave Found
                                    </Typography>
                                </Grid> : ''
                        }
                    </Grid>

                </Stack>

                <ConfirmDialog
                    title={`Cancel Leave Confirmation`}
                    body="Would you like to cancel the leave?"
                    open={this.state.confirmDialog}
                    buttonProps={
                        [{
                            label: 'No, Thanks'
                        },
                        {
                            label: 'Ok',
                            disabled: this.state.formSubmit
                        }]
                    }
                    onClose={() => this.setState({ confirmDialog: false })}
                    confirmCallback={() => this.deleteLeaves()} />
                {
                    <ApplyLeaveModal
                        open={this.state.openApplyLeaveModal}
                        refresh={(event: boolean) => {
                            if (event) {
                                this.props.leaveContext.loadLeaveLists();
                            }
                        }}
                        onClose={() => {
                            this.setState({ openApplyLeaveModal: !this.state.openApplyLeaveModal });
                        }}
                    />
                }
            </Container>
        </Box >
    }
}

export default withHooks(LeavePage, [
    ['theme', useTheme],
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
    ['appContext', useContext, [AppContext]],
    ['leaveContext', useContext, [LeaveContext]],
])