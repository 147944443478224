
import axios from 'axios';
import { appointmentServiceApi } from "../../../config/config";
import { setupInterceptorsTo } from "../interceptors/axios.interceptos";
const axiosInstance = setupInterceptorsTo(axios.create());

interface LeaveDayMenuUpdate {
    ivrChoicePreferenceId: number;
    onLeave: boolean;
}


interface ConnectTimingUpdate {
    connect?: boolean
    fromTime?: string;
    toTime?: string;
    connectDays?: number[];
}

class AdvancedSetttingService {

    /**
     * Get list of leave day menu listing
     * @param partyId 
     * @returns 
     */
    async getLeaveDayMenuList(partyId: number) {
        try {
            const { data } = await axiosInstance.get(`${appointmentServiceApi.baseUrl}/api/v1/settings/leave-day/ivr-preference?fromPartyId=${partyId}`);
            return data?.data;
        } catch (error) {
            throw error;
        }
    }


    /**
     * Update leave day menu
     * @param request 
     * @returns 
     */
    async updateLeaveDayMenuList(request: LeaveDayMenuUpdate) {
        try {
            const { data } = await axiosInstance.post(`${appointmentServiceApi.baseUrl}/api/v1/settings/leave-day/ivr-preference`, request);
            return data;
        } catch (error) {
            throw error;
        }
    }


    /**
     * Get list of connect timing listing
     * @param partyId 
     * @returns 
     */
    async getConnectTimingList(partyId: number) {
        try {
            const { data } = await axiosInstance.get(`${appointmentServiceApi.baseUrl}/api/v1/settings/call-connect-timings?fromPartyId=${partyId}`);
            return data?.data;
        } catch (error) {
            throw error;
        }
    }


    /**
     * Update connect timing item
     * @param request 
     * @returns 
     */
    async updateConnectTiming(connectTimeId: number, request: ConnectTimingUpdate) {
        try {
            const { data } = await axiosInstance.put(`${appointmentServiceApi.baseUrl}/api/v1/settings/call-connect-timings/${connectTimeId}`, request);
            return data;
        } catch (error) {
            throw error;
        }
    }
}

const advancedSetttingServiceSerive = new AdvancedSetttingService();

export default advancedSetttingServiceSerive;