
import React, { createContext } from 'react';
import { AppToasterProps } from '../components/AppToaster';

export interface UserProfile {
    imageUrl: string
}
export interface AppContextState {
    toaster: AppToasterProps;
    userProfile: UserProfile;
}

export type AppContextType = {
    toaster: AppToasterProps,
    toast: (value: AppToasterProps) => void;
    setUserProfile: (userProfile: UserProfile) => void;
    setToasterClose: () => void;
};

export const AppContext = createContext<AppContextType | null>(null);

class AppContextProvider extends React.Component<any, AppContextState> {

    state: AppContextState;

    constructor(props: any) {
        super(props);

        this.state = {
            userProfile: {
                imageUrl: ''
            },
            toaster: {
                severity: 'success',
                message: '',
                open: false,
                duration: 1000,
                handleClose: this.setToasterClose
            }
        }
    }

    toast = (toaster: AppToasterProps) => this.setState({ toaster: { ...this.state.toaster, ...toaster } });

    setToasterClose = () => this.setState({ toaster: { ...this.state.toaster, open: false } });

    private contextValues = () => ({
        ...this.state,
        toast: this.toast,
        setToasterClose: this.setToasterClose,
        setUserProfile: this.setUserProfile
    })

    setUserProfile = (userProfile: UserProfile) => {
        console.log(userProfile)
        this.setState({ userProfile })
    };

    render(): React.ReactNode {
        return (
            <AppContext.Provider value={this.contextValues()}>
                {this.props.children}
            </AppContext.Provider>
        )
    }
}


export default AppContextProvider;
