import UsersIcon from '@heroicons/react/24/solid/UsersIcon';
import { SvgIcon } from '@mui/material';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
// import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import EventIcon from '@mui/icons-material/Event';
import SettingsIcon from '@mui/icons-material/Settings';
import HistoryIcon from '@mui/icons-material/History';

//Menu Item
export const items = [
    {
        title: 'Appointments',
        path: '/appointments',
        icon: (
            <SvgIcon fontSize="small">
                <UsersIcon />
            </SvgIcon>
        )
    },
    {
        title: 'Leave',
        path: '/leave',
        icon: (
            <SvgIcon fontSize="small">
                <AppRegistrationOutlinedIcon />
            </SvgIcon>
        )
    },
    {
        title: 'Sessions',
        path: '/practice-session',
        icon: (
            <SvgIcon fontSize="small">
                <EventIcon />
            </SvgIcon>
        )
    },
    {
        title: 'Call Logs',
        path: '/call-log',
        icon: (
            <SvgIcon fontSize="small">
                <HistoryIcon />
            </SvgIcon>
        )
    },
    {
        title: 'Advanced Settings',
        path: '/advanced-setting',
        icon: (
            <SvgIcon fontSize="small">
                <SettingsIcon />
            </SvgIcon>
        )
    },
    // {
    //     title: 'Card View',
    //     path: '/card-view',
    //     icon: (
    //         <SvgIcon fontSize="small">
    //             <ViewAgendaIcon />
    //         </SvgIcon>
    //     )
    // }
];
