import React, { useState, useEffect, useCallback } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MyDatePicker from "../../components/MyDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { Stack } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableBody, tableBodyCell, mainContentStyle, contentTitleStyle, paperStyle } from "../../util/style";
import NoResultsMessage from "../../components/NoResultsMessage";
import SkeletonTableclass from "../../components/Skeleton";
import jwtDecode from "jwt-decode";
import reportService from "../../../../services/http/reports/reports.service";

interface ResponseObject {
    whatsAppVendor: string;
    whatsAppTemplateName: string;
    createdAt: string;
    ttl: string;
    activityId: string;
    authAssignmentId: string;
    recipient: string;
    sessionId: string;
    whatsAppParams: string;
    notificationStatus: string;
    whatsappMessagId: string;
    sk: string;
    pk: string;
    smsParams: string;
}

interface AllMessageProps {
    partyId: string;
}


const decodeToken = (token:any) => {
    try {
        return jwtDecode(token);
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

const AllMessages: React.FC<AllMessageProps> = ({ partyId }) => {
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<ResponseObject[]>([]);
    const [decodedToken, setDecodedToken] = useState<any>(null);
    const [hasFetchedData, setHasFetchedData] = useState(false);
   

    const fetchData = useCallback(async () => {
        try {
            const partyId = decodedToken?.partyId;
            const date=selectedDate?.format("YYYY-MM-DD").toLowerCase()
            if(partyId)
            {
                const whatsappData = await reportService.getWhatsapp({ partyId, Date: date });
                setData(whatsappData)
            }
            else{
                console.log("Party ID is not available, skipping the API call.");
            }
           
        } catch (error:any) {
            console.error('Error:', error);
        }
  }, [selectedDate, decodedToken]);

  useEffect(() => {
      const fetchDataAndDecodeToken = async () => {
          try {
              const storedToken = sessionStorage.getItem('SCHEDULA_TOKEN');
              if (storedToken && !hasFetchedData) {
                  const decoded = await decodeToken(storedToken);
                  setDecodedToken(decoded);

                  await fetchData(); 
                  setLoading(false);
                  setHasFetchedData(true)
              }
          } catch (error) {
              console.error('Error fetching data or decoding token:', error);
          }
      };

    
      const delay = setTimeout(fetchDataAndDecodeToken, 2000);
      return () => clearTimeout(delay);
  },);
  useEffect(() => {
    
    if (hasFetchedData) {
      fetchData();
    }
  }, [selectedDate, fetchData, hasFetchedData]);
   
    const handleDateSelection = (newSelectedDate: Dayjs | null) => { setSelectedDate(newSelectedDate); };

    const showRecordsFound = data.length > 0;

    return (
        <div>
            
            <Stack sx={{ ...mainContentStyle,overflowX: 'auto' }}>
                <Typography variant="h5" sx={{ ...contentTitleStyle }}>
                    WhatsApp Report
                </Typography>
                {loading ? (
                    <SkeletonTableclass />
                ) : (
                    <div style={{ overflowX: 'auto' }}> 
                    <Paper elevation={2} sx={{ ...paperStyle,overflowX: 'auto' }}>
                        <Stack sx={{ display: "flex", justifyContent: "space-between", flexDirection: 'row',overflowX: 'auto' }}>
                            <Stack sx={{ marginBottom: "25px" }}>
                                <MyDatePicker onDateSelect={handleDateSelection} />
                            </Stack>
                        </Stack>
                        {showRecordsFound && (
                            <Stack display={"flex"} flexDirection={'row'} gap={'5px'} >
                                Showing <strong>1-{data.length}</strong> of <strong>{data.length}</strong> items.
                            </Stack>
                        )}
                        {(
                            <TableContainer component={Stack}>
                                <Table sx={{ minWidth: 650,overflowX: 'auto' }} aria-label="simple table">

                                    <TableHead >
                                        <TableRow>
                                            <TableCell style={{ ...tableBodyCell, width: '0%' }}>#</TableCell>
                                            <TableCell style={{ ...tableBodyCell, width: '33%' }} align="left">To</TableCell>
                                            <TableCell style={{ ...tableBodyCell, width: '33%' }} align="left">Status</TableCell>
                                            <TableCell style={{ ...tableBodyCell, }} align="left">Message</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((session: ResponseObject, index: number) => {
                                            const whatsAppParamsArray = JSON.parse(session.whatsAppParams);

                                            return (
                                                <TableRow key={index} sx={{ verticalAlign: 'top', color: '#535252', border: 'solid 1px #f1f1f1', backgroundColor: "#f8f7f7", ...(index & 1 && { backgroundColor: '#ffffff' }) }}>
                                                    <TableCell sx={{ ...tableBody }}>{index + 1}</TableCell>
                                                    <TableCell sx={{ ...tableBody }}>{session.recipient}</TableCell>
                                                    <TableCell sx={{ ...tableBody }}>{session.notificationStatus}</TableCell>
                    
                                                    <TableCell sx={{ ...tableBody }}>
                                                        {whatsAppParamsArray.map((param: { default: string }, paramIndex: number) => (
                                                            <div key={paramIndex}>{param.default}</div>
                                                        ))}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}

                        {showRecordsFound ? "" :
                            <NoResultsMessage />
                        }
                    </Paper>
                    </div>
                )}
            </Stack>
        </div>
    );
};

export default AllMessages;
