import { differenceInCalendarYears, format, parse } from "date-fns";

export const calculateAge = (dob: any) => {
    const date = parse(dob, "yyyy-MM-dd", new Date());
    const age = differenceInCalendarYears(new Date(), date);
    return age;
}

export const timeStrFormat = (timeStr: string) => {
    const currentDate = format(new Date(), 'yyyy-MM-dd');
    const parsedDate = parse(`${currentDate} ${timeStr}`, 'yyyy-MM-dd HH:mm:ss', new Date())
    return format(parsedDate, 'hh:mm a')
} 