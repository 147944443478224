import React, { createContext } from 'react';

type ApptCallLogsContextType = {
    setSessionCallLogs: (item: any[]) => void;
    setCallHistory: (item: any[]) => void;
    sessionCallLogs: any[];
    callHistory: any[];
};

export const ApptCallLogsContext = createContext<ApptCallLogsContextType | null>(null);

class ApptCallLogsContextProvider extends React.Component<any, any> {

    state: any;

    constructor(props: any) {
        super(props);

        this.state = {
            sessionCallLogs: [],
            callHistory: [],
            date: new Date()
        }
    }

    setSessionCallLogs = (sessionCallLogs: any) => {
        this.setState({ sessionCallLogs })
    }

    setCallHistory = (callHistory: any) => {
        this.setState({ callHistory })
    }

    setDate = (date: Date) => {
        this.setState({ date })
    }

    private contextValues = () => ({
        ...this.state,
        setSessionCallLogs: this.setSessionCallLogs,
        setCallHistory: this.setCallHistory,
        setDate: this.setDate
    })

    render(): React.ReactNode {
        return (
            <ApptCallLogsContext.Provider value={this.contextValues()}>
                {this.props.children}
            </ApptCallLogsContext.Provider>
        )
    }
}


export default ApptCallLogsContextProvider;
