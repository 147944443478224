
import axios from 'axios';
// import CallLogsMock from "../../../mock/call-logs.json";
import { appointmentServiceApi } from "../../../config/config";
import { setupInterceptorsTo } from "../interceptors/axios.interceptos";
const axiosInstance = setupInterceptorsTo(axios.create());

class CallLogService {

    /**
     * get call logs details for the selected date
     * @param partyId 
     * @param date 
     * @returns 
     */
    async getCallLogs(partyId: number, date: string) {
        try {
            const { data } = await axiosInstance.get(`${appointmentServiceApi.baseUrl}/api/v1/report/call-log/summary?fromPartyId=${partyId}&calledOn=${date}`);
            return data?.data;
        } catch (error) {
            throw error;
        }
    }


    /**
     * Get call log history for the selected practice session
     * @param practiceSessionId 
     * @returns 
     */
    async getCallLogsDetails(partyId: number, date: string, practiceSessionId: number, choiceName: string) {
        try {
            const { data } = await axiosInstance.get(`${appointmentServiceApi.baseUrl}/api/v1/report/call-log/summary/details?fromPartyId=${partyId}&calledOn=${date}&choiceName=${choiceName}&practiceSessionId=${practiceSessionId}`);
            return data;
        } catch (error) {
            throw error;
        }
    }
}

const callLogSerive = new CallLogService();

export default callLogSerive;