import React, { createContext } from 'react';

type ApptContextType = {
    cardView: {},
    setCardView: (cardView: any) => void;
};

export const ApptCardViewContext = createContext<ApptContextType | null>(null);

class ApptCardViewContextProvider extends React.Component<any, any> {

    state: any;

    constructor(props: any) {
        super(props);

        this.state = {
            cardView: {}
        }
    }

    setCardView = (cardView: any) => {
        this.setState({cardView})
    }


    private contextValues = () => ({
        ...this.state,
        setCardView: this.setCardView,
    })

    render(): React.ReactNode {
        return (
            <ApptCardViewContext.Provider value={this.contextValues()}>
                {this.props.children}
            </ApptCardViewContext.Provider>
        )
    }
}


export default ApptCardViewContextProvider;
