import React, { useContext } from 'react'
import { Box, Card, Grid, Typography, CardContent, Stack } from '@mui/material';
import { ApptCallLogsContext } from '../context/call-logs.context';
import { useNavigate } from 'react-router-dom';

type SessionsMainCardProps = {

}

const apptCountTypeStle = {
    width: "100%", textAlign: "start", borderRadius: "6px"
}


export default function SessionsMainCard(props: SessionsMainCardProps) {
    const naviagte = useNavigate();
    const callLogCntx: any = useContext(ApptCallLogsContext);
    const { sessionCallLogs, date } = callLogCntx;
    const otherCallsType = sessionCallLogs.filter((call: any) => call.logCategory === "Others");
    const appointmentCalls = sessionCallLogs.filter((call: any) => call.logCategory !== "Others");

    return (
        <React.Fragment>
            {/**Appointment Calls UI */
                appointmentCalls.map((ses: any, indx: number) => {
                    const { totalCalls, practiceSessionId, practiceSessionName, choiceName, logCategory, uniqueCalls } = ses;
                    return (
                        <Grid container key={indx}>
                            <Grid item xs={12} md={9} sx={{ mt: '1rem' }}>
                                <Card sx={{ padding: '0px' }}>
                                    <Grid container spacing={2} sx={{ pt: '0px !important' }}>
                                        <Grid item xs={12} md={12}>
                                            <Box sx={{
                                                padding: '1rem !important'
                                            }}>
                                                <Typography variant="h6" component="span">
                                                    {practiceSessionName}
                                                </Typography>
                                                <Typography variant="caption" component="div" style={{ 'textTransform': "capitalize" }}>
                                                    {/* {sessionTime} */}
                                                    {/* {'Morning'} */}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mb: '1rem' }}>
                                        <Grid item xs={6} md={6} lg={6} sm container
                                            sx={{ pt: '0px !important', cursor: "pointer" }}
                                        >
                                            <CardContent
                                                sx={{
                                                    flex: '1 0 auto',
                                                    justifyContent: 'center',
                                                    padding: '0px 1rem 0rem 1rem !important',
                                                    lineHeight: 0
                                                }}
                                                onClick={() => naviagte('/call-log/details', { state: { practiceSessionId, logType: choiceName, date, logCategory } })}
                                            >
                                                <Stack spacing={1}>
                                                    <Typography variant="caption" color="text.secondary" component="div">
                                                        Appointments
                                                    </Typography>
                                                    <Typography variant="h3" display="block"
                                                        sx={apptCountTypeStle}
                                                    >
                                                        {`${uniqueCalls ?? 0}/${totalCalls ?? 0}`}
                                                    </Typography>
                                                </Stack>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    )
                })
            }

            {   /**Other calls UI */
                <Grid container>
                    <Grid item xs={12} md={9} sx={{ mt: '1rem' }}>
                        <Card sx={{ padding: '0px' }}>
                            <Grid container spacing={2} sx={{ pt: '0px !important' }}>
                                <Grid item xs={12} md={12}>
                                    <Box sx={{
                                        padding: '1rem !important'
                                    }}>
                                        <Typography variant="h6" component="span">
                                            {"Others"}
                                        </Typography>
                                        <Typography variant="caption" component="div" style={{ 'textTransform': "capitalize" }}>
                                            {/* {sessionTime} */}
                                            {/* {'Morning'} */}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <React.Fragment>
                                <Grid container sx={{ mb: '1rem' }}>
                                    {
                                        otherCallsType.map((item: any, indx: number) => {
                                            return (
                                                <Grid item xs={3} md={3} lg={3} sm container
                                                    sx={{ pt: '0px !important', cursor: "pointer" }}
                                                    key={indx}
                                                >
                                                    <CardContent
                                                        sx={{
                                                            flex: '1 0 auto',
                                                            justifyContent: 'center',
                                                            padding: '0px 1rem 0rem 1rem !important',
                                                            lineHeight: 0
                                                        }}
                                                        onClick={() => naviagte('/call-log/details', { state: { practiceSessionId: item?.practiceSessionId, logType: item?.choiceName, date, logCategory: item?.logCategory } })}
                                                    >
                                                        <Stack spacing={1}>
                                                            <Typography variant="caption" color="text.secondary" component="div">
                                                                {item?.choiceName ?? ""}
                                                            </Typography>
                                                            <Typography variant="h5" display="block"
                                                                sx={{ ...apptCountTypeStle }}
                                                            >
                                                                {`${item?.uniqueCalls ?? 0}/${item?.totalCalls ?? 0}`}
                                                            </Typography>
                                                        </Stack>
                                                    </CardContent>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </React.Fragment>
                        </Card>
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    )
}
