import React, { useContext } from "react";
import { Grid, Stack, Button, Checkbox, useMediaQuery } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppointmentsAttrEnum, TabType, CardStatusMapping } from "./list-card";
import { ApptCardViewContext } from "../contexts/card-view.context";
import appointmentService from "../../../services/http/appointments/appointment.service";
import { AppContext } from "../../../contexts/app.context";
import { useLocation } from "react-router-dom";


interface TabCardHeaderProps {
    tab: TabType,
    cardDetails: any[],
}


export default function TabCardHeader(props: TabCardHeaderProps) {
    const { tab, cardDetails } = props;
    const cardViewCnxt = useContext(ApptCardViewContext);
    const appContext: any = useContext(AppContext)
    const isMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
    const { state } = useLocation();
    const { setCardView, cardView }: any = cardViewCnxt;


    /**
     * Change the card view status
     * @param appt 
     */
    const changeStatus = async (appt: any, tab: any): Promise<void> => {
        try {
            const payload = {
                appointmentIds: getSelectedApptIds(appt),
                status: CardStatusMapping[tab]
            }
            // console.log("payload-->", payload)
            const { message } = await appointmentService.cardViewChangeStatus(payload);
            getCardViewDetails();
            appContext.toast({ open: true, message, severity: "success" });
        } catch (error) {
            console.error("Error in changeStatus", error);
        }
    }

    /**
     * Get the latest carview details & update the state
     */
    const getCardViewDetails = async (): Promise<void> => {
        try {
            const resp = await appointmentService.getCardViewDetails(state?.practiceSessionId);
            setCardView(resp);
        } catch (error) {
            console.error("Error in getCardViewDetails", error);
        }
    }


    /**
     * get list of selected appt id
     * @param appt 
     * @returns 
     */
    const getSelectedApptIds = (appt: any): number[] => {
        return appt.filter((appt: any) => appt?.checked).map((item: any) => item.id);
    }


    /**
     * onChage select all checkbox
     * @param e 
     */
    const onChangeSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e?.target?.checked;
        const _cardDetails = JSON.parse(JSON.stringify(cardDetails));

        _cardDetails.map((appt: any) => {
            appt["checked"] = checked;
            return appt;
        });

        cardView['appointments'][AppointmentsAttrEnum[tab]] = _cardDetails;

        setCardView(cardView);
    }

    /**
     * select all checkbox status
     * @returns 
     */
    const isAllChecked = () => {
        const isChecked = cardDetails.every((appt: any) => appt?.checked);
        return isChecked;
    };


    /**
     * Select all checkbox btn
     * @returns 
     */
    const CheckBoxCum = () => {
        return (
            <Checkbox
                size={'small'}
                sx={{ marginRight: `calc(100% - ${!isMd ? '100%' : '100%'}) !important`, padding: '11px' }}
                checked={isAllChecked()}
                onChange={(e) => onChangeSelectAll(e)}
            />
        )
    }


    return (
        <React.Fragment>
            <Grid container sx={{ my: 1 }}>
                <Grid item xs={12}>
                    {
                        (tab === 'appointments' || tab === 'in-queue') ? (
                            <Stack direction="row" spacing={2} justifyContent={'flex-start'} alignItems={'center'}>
                                <Button
                                    sx={{ width: "100%", justifyContent: 'start', pl: 0 }}
                                    size="small"
                                    startIcon={<ArrowBackIcon />}
                                    onClick={() => changeStatus(cardDetails, tab === 'appointments' ? 'No_Shows' : "Appointments")}
                                >
                                    {tab === 'appointments' ? 'No Shows' : "Appointments"}
                                </Button>
                                <Button
                                    sx={{ width: "100%", justifyContent: 'start', pl: 0 }}
                                    size="small"
                                    endIcon={<ArrowForwardIcon />}
                                    onClick={() => changeStatus(cardDetails, tab === 'appointments' ? 'Queue' : "Check_Out")}
                                >
                                    {tab === 'appointments' ? 'Queue' : "Check Out"}
                                </Button>
                                <CheckBoxCum />
                            </Stack>
                        ) : (
                            <Stack direction="row" spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                {
                                    tab === 'no-shows' && <Button
                                        sx={{ width: "100%", justifyContent: 'start', pl: 0 }}
                                        size="small"
                                        endIcon={<ArrowForwardIcon />}
                                        onClick={() => changeStatus(cardDetails, "Appointments")}
                                    >
                                        {"Appointments"}
                                    </Button>
                                }
                                {
                                    tab === 'checked-out' && <Button
                                        sx={{ width: "100%", justifyContent: 'start', pl: 0 }}
                                        size="small"
                                        startIcon={<ArrowBackIcon />}
                                        onClick={() => changeStatus(cardDetails, "Queue")}
                                    >
                                        {"Queue"}
                                    </Button>
                                }
                                <CheckBoxCum />
                            </Stack>
                        )
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    )
}