import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export default class NoResultsMessage extends Component{
  render() {
    return (
      <Stack>
        <Typography variant="h6" sx={{ padding: '8px', fontWeight: 'light', color: '#353434', fontSize: '14px', border: 'solid 1px #f1f1f1', backgroundColor: '#f8f7f7' }}>
          No results found.
        </Typography>
      </Stack>
    );
  }
}



