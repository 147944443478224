import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
 // width: '72px !important',
    // padding: 8,
    // '& .MuiSwitch-track': {
    //     background: 'red',
    //     height: 28,
    //     borderRadius: 22 / 2,
    //     opacity: 0.50,
    //     '&:before, &:after': {
    //         content: '""',
    //         position: 'absolute',
    //         top: '50%',
    //         transform: 'translateY(-30%)',
    //         color: "white",
    //         fontSize: '9px'
    //     },
    //     '&:before': {
    //         content: "'ON'",
    //         left: 12,
    //         fontSize: '10px',
    //         fontWeight: 'bold'
    //     },
    //     '&:after': {
    //         content: "'OFF'",
    //         right: 11,
    //         fontSize: '10px',
    //         fontWeight: 'bold'
    //     },
    // },
    // '& .MuiSwitch-thumb': {
    //     boxShadow: 'none',
    //     width: 30,
    //     height: 23,
    //     margin: 2,
    //     borderRadius: '10px'
    // },
    // '& .Mui-checked': {
    //     '& .MuiSwitch-thumb': {
    //         transform :'translate(1px)'
    //     }
    // }
const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: '70px !important',
    padding: 8,
    '& .MuiSwitch-switchBase': {
        padding: 11
    },
    '& .MuiSwitch-track': {
        background: 'red',
        height: 27,
        borderRadius: 22 / 2,
        opacity: 0.50,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-30%)',
            color: "white",
            fontSize: '9px'
        },
        '&:before': {
            content: "'ON'",
            left: 12,
            fontSize: '10px',
            fontWeight: 'bold'
        },
        '&:after': {
            content: "'OFF'",
            right: 13,
            fontSize: '10px',
            fontWeight: 'bold'
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 25,
        height: 21,
        borderRadius: 18 / 2
    },
    '& .Mui-checked': {
        '& .MuiSwitch-thumb': {
            transform :'translate(3px)'
        }
    }
}));

export default CustomSwitch;