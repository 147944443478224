import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Component, ReactNode } from "react";

class ConfirmDialog extends Component<any, any> {

    handleConfirm = () => {
        if (this.props.confirmCallback) {
            this.props.confirmCallback();
        }
    }

    handleOk = () => {
        if (this.props.callBack) {
            this.props.callBack();
        }
    }

    render(): ReactNode {
        const { buttonProps } = this.props;

        return <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={this.props.open}
        >
            <DialogTitle>{this.props.title}</DialogTitle>
            <DialogContent>
                {this.props.body}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={this.props.onClose}>
                    {buttonProps ? buttonProps[0]['label'] : 'Cancel'}
                </Button>
                <Button onClick={this.handleConfirm} disabled={
                    buttonProps ? buttonProps[1]?.disabled : false 
                }
                >{buttonProps ? buttonProps[1]['label'] : 'Ok'}</Button>
            </DialogActions>
        </Dialog>;
    }
}

export default ConfirmDialog