import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    DialogContent, useMediaQuery,
    useTheme
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import withHooks from '../../../utils/withHooks';
import { ApptContext } from '../../appointments/contexts/appointment.context';
import ApplyLeave from './ApplyLeave';
import { AppContext } from '../../../contexts/app.context';
import { LeaveContext } from '../contexts/leave.context';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ApplyLeaveModal extends React.Component<any, any, {}> {

    state: any;

    applyLeaveRef: any;

    constructor(props: any) {
        super(props);

        this.state = {
            open: props.open ?? false,
            isFormDisabled: false
        }
    }

    render(): React.ReactNode {
        return <>
            <Dialog
                fullScreen={this.props.isMd}
                open={this.props.open}
                TransitionComponent={Transition}
            // sx={{
            //     '.MuiDialogContent-root' : {
            //         padding: '20px 16px'
            //     }
            // }}
            >
                <AppBar sx={{
                    position: 'relative',
                    background: 'none',
                    boxShadow: 'none !important',
                    color: 'neutral.900',
                }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            Apply Leave
                        </Typography>

                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            onClick={() => this.props.onClose()}

                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <DialogContent>
                    <ApplyLeave
                        ref={this.applyLeaveRef}
                        showApplyButton={true}
                        returnRef={true}
                        formDisabled={(status: boolean) => {
                            this.setState(status)
                        }}

                        {...this.props}
                        refresh={(event: boolean) => {
                            if (event) {
                                this.props.onClose()
                                this.props.refresh(event);
                            }
                        }}
                    />
                </DialogContent>

            </Dialog>
        </>
    }
}

export default withHooks(ApplyLeaveModal, [
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
    ['isSm', (() => useMediaQuery((theme: any) => theme.breakpoints.down('sm')))],
    ['apptContext', React.useContext, [ApptContext]],
    ['appContext', React.useContext, [AppContext]],
    ['leaveContext', React.useContext, [LeaveContext]],
    ['theme', useTheme]
]);
