import { useEffect, useState, useContext } from "react";
import _ from "lodash";
import {
    Box, Card, Grid,
    Typography, Stack
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import "../styles/connect-timing-main-card.scss";
import CustomSwitch from '../../../components/CustomSwitch';
import advancedSetttingServiceSerive from "../../../services/http/advanced-settings/advanced-settings.services";
import { getAttributeFromToken } from "../../../hooks/use-auth";
import { AppContext } from "../../../contexts/app.context";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type LeaveDaymenuMainCardProps = {
}


export default function LeaveDaymenu(props: LeaveDaymenuMainCardProps) {
    const appContext: any = useContext(AppContext)
    const [connectTimings, setConnectTimings] = useState<any>([]);
    const [sessions, setSessions] = useState<any[]>([]);

    useEffect(() => {
        getLeaveDayMenu()
        return () => { }
    }, [])

    /**
     * Handle accordian
     * @param indx 
     * @returns 
     */
    const handleChange =
        (indx: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setSessions((prevState) => {
                prevState[indx]["expanded"] = isExpanded;
                return prevState
            })
        };

    /**
     * Get list of connect timings options
     */
    const getLeaveDayMenu = async (): Promise<void> => {
        try {
            const partyId = getAttributeFromToken('partyId')
            const resp = await advancedSetttingServiceSerive.getLeaveDayMenuList(partyId);

            resp.map((item: any) => {
                item["checked"] = !!item?.onLeave;
                return item;
            });

            const sessions = _.uniqBy(resp, (obj: any) => obj.orgName);
            const groupedSessions = _.mapValues(_.groupBy(resp, 'orgName'));

            setSessions(sessions);
            setConnectTimings(groupedSessions);
        } catch (error) {
            console.error("Error in getConnectTimings", error);
        }
    }


    /**
     * Update connect timings
     */
    const updateLeaveDayMenu = async (e: React.ChangeEvent<HTMLInputElement>, data: any): Promise<void> => {
        try {
            const payLoad = {
                ivrChoicePreferenceId: data?.id,
                onLeave: e.target.checked
            }
            const { message } = await advancedSetttingServiceSerive.updateLeaveDayMenuList(payLoad);
            getLeaveDayMenu();
            appContext.toast({ open: true, message, severity: "success" });
        } catch (error) {
            console.error("Error in updateConnectTiming", error);
        }
    }

    return (
        <Grid container className="connect-timing-card-root"
            sx={{
                '.MuiPaper-root': {
                    width: "100%"
                }
            }}>
            {
                sessions.map((item: any, indx: number) => {
                    return (
                        <Accordion
                            sx={{ boxShadow: 'unset', backgroundColor: '#f9f9f9 !important', '.Mui-expanded': { my: "0px !important" } }}
                            expanded={item?.expanded}
                            onChange={handleChange(indx)}
                            key={indx}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{ padding: "0px", m: 0, minHeightL: "30px !important" }}
                            >
                                <Typography variant="h6" gutterBottom>
                                    {item?.orgName}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "0px 0px 0px" }}>
                                {
                                    connectTimings[item.orgName].map((item: any, indx: number) => {
                                        return (
                                            <Grid item xs={12} md={12} key={indx} >
                                                <Card sx={{ padding: '0px', mb: 1 }}>
                                                    <Grid container spacing={2} sx={{ pt: '0px !important' }}>
                                                        <Grid item xs={12} md={12}>
                                                            <Box sx={{
                                                                padding: '1rem 0.5rem 1rem 1rem !important'
                                                            }}>
                                                                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                                                    <Typography variant="h6" component="span">
                                                                        {item?.description}
                                                                    </Typography>
                                                                    <CustomSwitch
                                                                        checked={item?.checked}
                                                                        onChange={(e) => updateLeaveDayMenu(e, item)}
                                                                    />
                                                                </Stack>
                                                                <Typography variant="caption" color="text.secondary" component="div">
                                                                    Digit
                                                                </Typography>
                                                                <Typography variant="subtitle1" display="block" sx={{ margin: 0 }}>
                                                                    {item?.digit}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid >
                                        )
                                    })
                                }
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </Grid >
    )
}
