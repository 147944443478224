import React, { Component } from 'react';
import { format } from 'date-fns';
import {
    Box,
    Grid,
    Tooltip,
    Button,
    Card,
    CardContent,
    Typography,
    IconButton,
    SvgIcon,
} from '@mui/material';
import EditIcon from '@mui/icons-material/BorderColorSharp';
import { timeStrFormat } from '../../../utils/common';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../../components/ConfirmDialog';
import CustomSwitch from '../../../components/CustomSwitch';
import practiceSessionService from '../../../services/http/practice-sessions/practice-session.service';

class PracticeSessionCard extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            expanded: false,
            confirmDialog: false,
        };
    }

    formatedDate = () => {
        const dateNow = format(Date.now(), 'dd-MM-yyyy');
        const scheduleOn = format(this.props.scheduleOn, 'dd-MM-yyyy');
        return dateNow === scheduleOn ? 'Today' : scheduleOn;
    };

    isShowConsultingOnOff = () => {
        const selectedDate = format(this.props.scheduleOn, 'yyyy-MM-dd');
        const todayDate = format(new Date(), 'yyyy-MM-dd');
        const {isClinicLeave}=this.props;

        return selectedDate >= todayDate && this.props?.consultingStateChange&& isClinicLeave==="No";

    };

    showAppointmentCount = () => {
        const { apptContext } = this.props;
        return apptContext.filter.apptStatus === '2'
            ? this.props.cancelledAppt
            : this.props.confirmedAppt;
    };

    consultingStateChange = async () => {
        let { practiceSessionId, practiceStatus, scheduleOn, practiceToggleId, practiceSesContext } = this.props;
        const [selectedSession] = practiceSesContext.practiceSessions.filter((ses: any) => ses.practiceSessionId === practiceSessionId);

        practiceStatus = +!practiceStatus;
        scheduleOn = format(this.props.scheduleOn, 'yyyy-MM-dd');

        const { data } = await practiceSessionService.togglePracticeSession({
            id: practiceToggleId,
            practiceSessionId,
            sessionDate: scheduleOn,
            practiceStatus,
            session: selectedSession ?? {}
        });
        practiceSesContext.setPracticeToggle(data.id, data.practiceStatus, selectedSession);
    }

    onClickPrctSessCard = (e: any) => {
        e.stopPropagation();
        if (e.target.tagName === "DIV") { //handling onChange event propogation
            const { practiceSessionId, navigate, apptContext } = this.props;
            navigate("/appointment-list", { state: { practiceSessionId } });
            apptContext.setFilterValues('practiceSessionId', practiceSessionId);
        }
    }

    render() {
        const { scheduleOn, showCardViewBtn, navigate } = this.props;
        return (
            <Card sx={{ padding: '0px', mb: '1rem', cursor: 'pointer' }} onClick={(e) => this.onClickPrctSessCard(e)}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={2} sx={{ pt: '0px !important' }}>
                        <Grid item xs={9} md={9}>
                            <Box sx={{ padding: '1rem !important' }}>
                                <Typography variant="h6" component="span">
                                    {this.props.orgName}, {this.props.area}
                                </Typography>

                                <Typography variant="caption" component="div">
                                    {this.formatedDate()}, {timeStrFormat(this.props.consultingFromTime)} -{' '}
                                    {timeStrFormat(this.props.consultingToTime)}
                                </Typography>
                                {showCardViewBtn ? (
                                    <Button
                                        sx={{ paddingLeft: 0, cursor: 'pointer' }}
                                        variant="text"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            const { practiceSessionId } = this.props;
                                            navigate('/card-view', { state: { practiceSessionId } });
                                        }}
                                    >
                                        Card View
                                    </Button>
                                ) : ''}
                            </Box>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Box sx={{
                                padding: '1rem 0px 0px 0px !important',
                                textAlign: 'center'
                            }}>
                                {this.isShowConsultingOnOff()&&(
                                    <Tooltip title="Consulting On/Off">
                                        <CustomSwitch
                                            inputProps={{ 'aria-label': 'Consulting On/Off Switch' }}
                                            checked={this.props.practiceStatus === 1}
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                this.setState({ confirmDialog: !this.state.confirmDialog });
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </Box>
                        </Grid>

                        {this.props.isClinicLeave === 'No' ? (
                            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', pt: '0px !important' }}>
                                <Grid item xs={4} md={4} sm container sx={{ pt: '0px !important' }}>
                                    <CardContent
                                        sx={{
                                            flex: '1 0 auto',
                                            justifyContent: 'center',
                                            padding: '0px 0px 1rem 1rem !important',
                                        }}
                                    >
                                        <Typography variant="caption" color="text.secondary" component="div">
                                            Session Type
                                        </Typography>
                                        <Typography variant="subtitle1" display="block" gutterBottom>
                                            {this.props.sessionType}
                                        </Typography>
                                    </CardContent>
                                </Grid>
                                <Grid item xs={4} md={4} sm container sx={{ pt: '0px !important' }}>
                                    <CardContent
                                        sx={{
                                            flex: '1 0 auto',
                                            textAlign: 'center',
                                            padding: '0px 0rem !important',
                                        }}
                                    >
                                        <Typography variant="caption" color="text.secondary" component="div">
                                            Confirmed Appts
                                        </Typography>
                                        <Typography variant="h6" display="block" gutterBottom sx={{ lineHeight: 2 }}>
                                            {this.showAppointmentCount()}
                                        </Typography>
                                    </CardContent>
                                </Grid>
                                <Grid item xs={4} md={4} sm container sx={{ pt: '0px !important' }}>
                                    <CardContent
                                        sx={{
                                            flex: '1 0 auto',
                                            textAlign: 'center',
                                            padding: '0px 10px !important',
                                        }}
                                    >
                                        <Typography variant="caption" color="text.secondary" component="div">
                                            Manage Slots
                                        </Typography>
                                        <Typography color="text.primary" component="div">
                                            <IconButton
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    this.props.practiceSesContext.loadSlots(
                                                        this.props.practiceSessionId,
                                                        scheduleOn,
                                                        true
                                                    );
                                                }}
                                                disabled={!this.isShowConsultingOnOff()}
                                            >
                                                <SvgIcon fontSize="small">
                                                    <EditIcon />
                                                </SvgIcon>
                                            </IconButton>
                                        </Typography>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        ) : ''}

                        {this.props.isClinicLeave === 'Yes' ? (
                            <Grid item xs={12}>
                                <CardContent
                                    sx={{
                                        flex: '1 0 auto',
                                        justifyContent: 'center',
                                        padding: '0px 0px 1rem 1rem !important',
                                    }}
                                >
                                    <Typography variant="h6" color="text.secondary" component="div" sx={{ textAlign: 'center' }}>
                                        Clinic is on leave
                                    </Typography>
                                </CardContent>
                            </Grid>
                        ) : ''}

                        <ConfirmDialog
                            title={`Consulting On/Off`}
                            body={
                                this.props.practiceStatus
                                    ? "Callers won't be able to make any more appointments for today only. Are you sure?"
                                    : 'Caller will be able to make an appointment until regular appointment booking time is over. Are you sure?'
                            }
                            open={this.state.confirmDialog}
                            onClose={() => this.setState({ confirmDialog: !this.state.confirmDialog })}
                            confirmCallback={() => {
                                this.setState({ confirmDialog: !this.state.confirmDialog });
                                this.consultingStateChange();
                            }}
                        />
                    </Grid>
                </Box>
            </Card>
        );
    }
}

export default function CardWithRoute(props: any) {
    const navigate = useNavigate();
    return <PracticeSessionCard navigate={navigate} {...props} />;
}
