import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

interface SkeletonProps {
    iteration?: number;
    isMd?: boolean;
}

const AppointmentListCardSkeleton = (props: SkeletonProps) => {
    return (
        <Card sx={{ padding: '0px', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container sx={{ padding: '0rem' }}>
                    <Grid item xs={4} sx={{
                        padding: '0rem',
                        ...(props.isMd && {
                            background: '#0c3376',
                            color: '#f9f9f9',
                            '& .schedule-datetime': {
                                color: '#f9f9f9',
                                fontWeight: '400'
                            }
                        })
                    }}>
                        <CardContent sx={{
                            flex: '1 0 auto',
                            textAlign: 'center',
                            padding: '15px 15px !important'
                        }}>
                            <Typography component="div" variant="h3">
                                <Skeleton />
                            </Typography>
                            <Typography className="schedule-datetime" variant="subtitle2" color="text.secondary" component="div">
                                <div><Skeleton /></div>
                                <div><Skeleton /></div>
                            </Typography>
                            <Typography variant="caption" gutterBottom sx={{ fontSize: '8px', mt: '20px', fontWeight: '300' }}>
                                <Skeleton />
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Grid item xs={6} sm container sx={{ padding: '0rem' }}>
                        <CardContent sx={{
                            flex: '1 0 auto', justifyContent: 'center',
                            padding: '15px 15px !important'
                        }}>
                            <Typography component="div" variant="h6" >
                                <Skeleton />
                            </Typography>
                            <Typography sx={{ mt: 1.5 }} component="div" variant="subtitle1" >
                                <Skeleton />
                            </Typography>
                            <Typography sx={{ mt: 1.5 }} color="text.secondary">
                                <Skeleton />
                            </Typography>
                            <Typography color="text.primary" variant="subtitle1" component="div">
                                <Skeleton />
                            </Typography>
                        </CardContent>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}

export default AppointmentListCardSkeleton