import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from "react";

export interface AppToasterProps {
    severity: AlertColor;
    message: string;
    open: boolean;
    handleClose: Function;
    duration?: number;
    type?: string;
}


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AppToaster(props: AppToasterProps) {
    return (
        <Snackbar open={props.open} autoHideDuration={props.duration} onClose={() => props.handleClose()} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert onClose={() => props.handleClose()} severity={props.severity} sx={{ width: '100%' }}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}