
import { Box, Divider, MenuItem, MenuList, Popover, Typography, IconButton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { getAttributeFromToken } from '../../../../../hooks/use-auth';
import { common } from '../../../../../config/config';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
export const AccountPopover = (props: any) => {
    const navigate = useNavigate();
    const { anchorEl, onClose, open } = props;
    let userName = getAttributeFromToken('username');

    /**
     * On Click Edit Icon
     */
    const onClickEditProfile = () => {
        navigate("/edit-profile");
        onClose()
    }

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            onClose={onClose}
            open={open}
            PaperProps={{ sx: { width: 200 } }}
        >
            <Box
                sx={{
                    py: 1.5,
                    px: 2
                }}
            >
                <Stack direction="row" alignItems="center">
                    <Typography variant="overline">
                        Account
                    </Typography>
                    <span>
                        <IconButton size="small" onClick={() => onClickEditProfile()}>
                            <EditIcon />
                        </IconButton>
                    </span>
                </Stack>
                <Typography
                    color="text.secondary"
                    variant="body2"
                >
                    {userName}
                </Typography>
            </Box>
            <Divider />
            <MenuList
                disablePadding
                dense
                sx={{
                    p: '8px',
                    '& > *': {
                        borderRadius: 1
                    }
                }}
            >
                <MenuItem onClick={() => {
                    window.location.replace(common.schedulaLegacyAppUrl + 'sign-in/logout')
                }}>
                    Sign out
                </MenuItem>
            </MenuList>
        </Popover>
    );
};

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired
};
