import { useTheme } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import withHooks from '../../../utils/withHooks';
import SideNav from './components/side-menu-bar/SideMenuBar';
import TopBar from './components/top-bar/TobBar';
import { AppContext } from '../../../contexts/app.context';
import AppToaster from '../../../components/AppToaster';

const SIDE_NAV_WIDTH = 280;

const LayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%'
});

class AppLayout extends React.Component<any> {

    state: any;

    constructor(props: any) {
        super(props);

        this.state = {
            openNav: false
        }
    }

    componentDidMount(): void {
    }

    setOpenNav = () => {
        this.setState({ ...this.state, openNav: !this.state.openNav });
    }

    render(): React.ReactNode {
        const { appContext } = this.props;
        return <>
            <TopBar onNavOpen={() => this.setOpenNav()} isOpen={this.state.openNav} />
            <SideNav
                onClose={() => this.setOpenNav()}
                open={this.state.openNav}
            />
            <Box
                component="main"
                sx={{
                    display: 'flex',
                    flex: '1 1 auto',
                    marginLeft: {
                        lg: `${!this.state.openNav ? SIDE_NAV_WIDTH : 0}px !important`
                    },
                    top: 0,
                    zIndex: (theme) => this.props.theme.zIndex.appBar
                }}
            >
                <LayoutContainer>
                    <Outlet />
                </LayoutContainer>

                {/* App Toaster */}
                <AppToaster {...appContext.toaster}/>
            </Box>
        </>
    }
}

export default withHooks(AppLayout, [
    ['theme', useTheme],
    ['appContext', useContext, [AppContext]]
])