import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ManIcon from '@mui/icons-material/Man';
import SearchIcon from '@mui/icons-material/Search';
import WomanIcon from '@mui/icons-material/Woman';
import {
    Button,
    Card,
    Chip,
    Fab,
    FormControl,
    Grid,
    InputAdornment,
    MenuItem, Stack, TextField, Typography, IconButton
} from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { format, parse } from 'date-fns';
import dayjs from 'dayjs';
import React, { Component, ReactNode, useContext } from "react";

import { AppContext } from "../../../../contexts/app.context";
import appointmentService from "../../../../services/http/appointments/appointment.service";
import practiceSessionService from "../../../../services/http/practice-sessions/practice-session.service";
import { calculateAge, timeStrFormat } from "../../../../utils/common";
import withHooks from '../../../../utils/withHooks';
import { PracticeSesContext } from '../../../practice-session/contexts/practice-session.context';
import { ApptContext } from '../../contexts/appointment.context';

type UserDetailsType = {
    firstname: string,
    gender: string,
    age: number,
    weight: number,
    height: number
}

const steps = ['Select Sessions', 'Create Appointment'];

class AddAppointmentForm extends Component<any, any> {

    state: any;

    constructor(props: any) {
        super(props);

        this.state = {
            isPhnNumberValid: false,
            isDetailsForm: false,
            phnNumber: '',
            userDetails: [],
            open: props.open ?? false,
            practiceSessionError: false,
            apptStatusError: false,
            patientSelected: null,
            practiceSession: [],
            sessionSlots: [],
            selectedDate: new Date(),
            selectedSession: '',
            selectedSlot: '',
            curretStep: 1,
            activeStep: 0,
            completed: {},
            addDetailsForm: {
                firstname: "",
                lastname: "",
                dob: "",
                age: "",
                height: "",
                weight: "",
                gender: "",
            },
            formValidation: {
                phnNumber: false
            },
        }
    }

    async componentDidMount(): Promise<any> {
        await this.getPracticeSessions(new Date(), this.apptContext.partyId)
    }

    async getPatientDetails(): Promise<any> {
        try {
            const { data } = await appointmentService.getPatient({ phnNumber: `0${this.state.phnNumber}` });
            this.setState({
                userDetails: (data) ?? [],
                isDetailsForm: !this.state.isDetailsForm ? false : !(data.length > 0)
            })
        } catch (error) {
            console.error("getPatient error-->", error);
        }
    }

    onChangeDate = async (date: any) => {
        const parsedDob = parse(date, "dd-MM-yyyy", new Date()) //format -> yyyy:mm:dd
        const dob = format(parsedDob, 'yyyy-MM-dd')//format -> yyyy:mm:dd
        const { addDetailsForm } = this.state;
        this.setState({
            addDetailsForm: { ...addDetailsForm, dob, age: calculateAge(dob) }
        })
    }

    onChangeApptDate = (e: React.ChangeEvent<any>) => {
        this.setState({ selectedDate: e, practiceSession: [], sessionSlots: [], selectedSession: '', selectedSlot: '' });
        this.getPracticeSessions(e, this.apptContext.partyId);
    }

    getSessionObject = (sessionId: number) => {
        return this.state.practiceSession.find(({practiceSessionId}: any) => practiceSessionId === sessionId);
    }

    onChangePracticeSession = (e: React.ChangeEvent<any>) => {
        this.setState({ selectedSession: e.target.value });
        this.getSessionSlots(e.target.value);
    }

    onChangeSessionSlots = (e: React.ChangeEvent<any>) => {
        this.setState({ selectedSlot: e.target.value });
    }

    onAddExistingPatient = (idx: number): void => {
        let patientDetails = this.state.userDetails[idx];
        patientDetails = {
            ...patientDetails,
            dob: patientDetails.dob ? dayjs(patientDetails.dob) : patientDetails.dob,
            age: patientDetails.dob ? calculateAge(patientDetails.dob) : ''
        }
        this.setState({ isDetailsForm: true, addDetailsForm: patientDetails, patientSelected: idx })
    }

    addAppointment = async () => {
        const { phnNumber, selectedSlot, selectedSession, selectedDate } = this.state;
        const patientDetails = this.state.addDetailsForm

        //remove empty keys from details
        Object.keys(patientDetails).forEach((key) => (patientDetails[key] === null || !patientDetails[key]) && delete patientDetails[key]);
        delete patientDetails.age;
        const session = this.getSessionObject(selectedSession);
        try {
            const payload = {
                "sessionId": this.state.selectedSession,
                "phoneNumber": phnNumber,
                "appointmentDate": format(selectedDate, 'yyyy-MM-dd'),
                "slotId": selectedSlot,
                "partyId": session.fromPartyId,
                "requestMode": "Desktop",
                "userProfile": {
                    ...patientDetails
                }
            }
            // return
            const { data } = await appointmentService.makeAppointment(payload);
            this.apptContext.setApptModal(false);
            this.appContext.toast({ open: true, message: data.message, severity: 'success', duration: 2000 });
            this.apptContext.loadAppointments();
            window.location.reload();
        } catch (error: any) {
            this.apptContext.setApptModal(false);
            this.appContext.toast({ open: true, message: error.response.data.message, severity: 'warning', duration: 2000 });
        }
    }

    addDetails = async () => {
        if (!this.validateAddDetailsForm()) return;
        this.setState((prevState: any) => {
            let details: any;
            const { firstname, age, gender, weight, height, id } = prevState.addDetailsForm;
            const newUser: UserDetailsType = {
                firstname, age, gender, weight, height
            }
            if (id) {
                const indx = prevState.userDetails.findIndex((user: any) => user.id === id);
                details = [...prevState.userDetails];
                details[indx] = { ...newUser, id };
            } else {
                details = [...prevState.userDetails, newUser]
            }
            return {
                userDetails: details,
                isDetailsForm: false,
                patientSelected: null
            }
        })
        await this.addAppointment();
    }

    getPracticeSessions = async (d: any, partyId: number) => {
        try {
            const date = format(d, 'yyyy-MM-dd') //format -> yyyy:mm:dd
            const { data } = await practiceSessionService.getPracticeSessions({ date, partyId });
            this.setState({ practiceSession: data?.data ?? [] })
        } catch (error) {
            console.error(error)
        }
    }

    getSessionSlots = async (id: number) => {
        try {
            const { data } = await practiceSessionService.listSlots({ practiceSessionId: id, date: format(this.state.selectedDate, 'yyyy-MM-dd') });
            this.setState({ sessionSlots: data ?? [] });
        } catch (error) {
            console.error(error)
        }
    }

    onFormInputChange = (e: any, attr: string) => {
        let value;
        if (attr !== "gender") {
            value = e.target.value;
            if (attr === "weight" || attr === "height") value = parseInt(e.target.value);
        }

        const { addDetailsForm } = this.state;
        this.setState({
            addDetailsForm: { ...addDetailsForm, [attr]: attr !== "gender" ? value : e }
        })
    }

    onPhnNumberChange = (e: any) => {
        this.setState({ formValidation: { phnNumber: false } })
        this.setState({ phnNumber: e.target.value });
    }

    handleKeyDown = async (e: any, type: string) => {
        const { phnNumber } = this.state;

        if (type === "enter" && e.key !== 'Enter') return

        if (isNaN(phnNumber) || phnNumber.length < 10) {
            this.setState({ formValidation: { phnNumber: true } })
            return
        }
        await this.getPatientDetails();
        this.setState({ isPhnNumberValid: true });
        this.setState({ formValidation: { phnNumber: false } })
    }

    validateAddDetailsForm = () => {
        const { addDetailsForm } = this.state;
        // if (!addDetailsForm?.firstname || !addDetailsForm?.age || !addDetailsForm?.gender
        //     || !addDetailsForm?.weight || !addDetailsForm?.height) return false;
        if (!addDetailsForm?.firstname) return false;
        return true;
    }

    validateScreen1 = () => {
        const { selectedDate, selectedSession, selectedSlot } = this.state;
        if (!selectedDate || !selectedSession || !selectedSlot) return false;
        return true
    }

    resetForm = (): void => {
        const { addDetailsForm } = this.state;
        Object.keys(addDetailsForm).forEach(function (key) {
            if (key !== "gender") addDetailsForm[key] = "";
        });
        this.setState({ addDetailsForm });
    }

    get apptContext() {
        return this.props.apptContext;
    }

    get appContext() {
        return this.props.appContext;
    }

    get practiceSesContext() {
        return this.props.practiceSesContext;
    }

    genderIcon = (gender: string) => {
        if (gender === "male") return <ManIcon />
        if (gender === "female") return <WomanIcon />
    }

    totalSteps = () => {
        return steps.length;
    };

    completedSteps = () => Object.keys(this.state.completed).length;

    isLastStep = () => this.state.activeStep === this.totalSteps() - 1

    allStepsCompleted = () => this.completedSteps() === this.totalSteps()

    handleNext = () => {
        const newActiveStep =
            this.isLastStep() && !this.allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in this.state.completed))
                : this.state.activeStep + 1;
        this.setState({
            activeStep: newActiveStep
        })
    };

    handleBack = () => {
        const { activeStep } = this.state;

        this.setState({
            activeStep: activeStep - 1
        });

        // this.setState(({activeStep}: any) => {activeStep})
    };

    handleStep = (activeStep: number) => () => this.setState({ activeStep })

    handleComplete = () => {
        let { completed } = this.state;
        completed[this.state.activeStep] = true;
        this.setState({
            completed
        })
        this.handleNext();
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
            completed: {}
        })
    };

    disableSessionSelection = (details: any): boolean => {
        const { isConsultingOver, isClinicLeave, orgName } = details;
        const selectedDate = format(this.state.selectedDate, 'yyyy-MM-dd');
        const todayDate = format(new Date(), 'yyyy-MM-dd');
        return isClinicLeave === "Yes" || orgName.toLowerCase() === "online" || (isConsultingOver === 1 && (selectedDate === todayDate))
    }

    disableSlotSelection = (details: any): boolean => {
        const { isSlotIsOpen, totalAppt, regularSlots, selectedSlot } = details;
        return isSlotIsOpen === 0 || selectedSlot === 0 || regularSlots === parseInt(totalAppt) 
    }

    render(): ReactNode {
        const { formError } = this.props;
        const { isDetailsForm, isPhnNumberValid,
            userDetails, phnNumber, formValidation, addDetailsForm,
            selectedSession, practiceSession, sessionSlots, selectedSlot, selectedDate } = this.state;
        return <>
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '100%',
                }}
            >
                <Stepper nonLinear activeStep={this.state.activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={this.state.completed[index]}>
                            <StepButton color="inherit">
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                <div>
                    <React.Fragment>
                        {
                            this.state.activeStep === 0 && <React.Fragment>
                                <Stack
                                    spacing={2}
                                    direction="row"
                                    sx={{
                                        mt: 3,
                                        '& .MuiStack-root': {
                                            width: "100%",
                                        }
                                    }}
                                >
                                    <DemoItem label="Select Appointment Date">
                                        <MobileDatePicker
                                            label="Date"
                                            closeOnSelect={true}
                                            value={selectedDate}
                                            minDate={new Date()}
                                            onChange={(date) => this.onChangeApptDate(date)}
                                            format="dd-MMMM-yyyy"
                                            defaultValue={dayjs('2022-04-17')}
                                        />
                                    </DemoItem>
                                </Stack>
                                <FormControl variant="standard" sx={{ mt: 3, minWidth: '100%' }}>
                                    <TextField
                                        label="Choose Session"
                                        fullWidth
                                        id="practiceSessionId"
                                        select
                                        helperText={formError?.practiceSessionId ? 'Please select practice session' : ''}
                                        onChange={this.onChangePracticeSession}
                                        value={selectedSession ?? ''}
                                        error={formError?.practiceSessionId}
                                        variant="standard"
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {
                                            practiceSession.map((pses: any, i: number) => (
                                                <MenuItem value={pses.practiceSessionId} key={i} disabled={this.disableSessionSelection(pses)}>
                                                    <Stack>
                                                        <Typography variant="subtitle2">
                                                            {pses.orgName}, {pses.area}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {timeStrFormat(pses.consultingFromTime)} - {timeStrFormat(pses.consultingToTime)}
                                                        </Typography>
                                                    </Stack>
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </FormControl>
                                <FormControl variant="standard" sx={{ mt: 3, minWidth: '100%' }}>
                                    <TextField
                                        label="Choose Slot"
                                        fullWidth
                                        id="id"
                                        select
                                        helperText={formError?.selectedSlot ? 'Please select session slot' : ''}
                                        onChange={this.onChangeSessionSlots}
                                        value={selectedSlot ?? ''}
                                        error={formError?.selectedSlot}
                                        variant="standard"
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        {
                                            sessionSlots.map((slot: any, i: number) => (
                                                <MenuItem value={slot.id} key={i} disabled={this.disableSlotSelection(slot)}>
                                                    <Stack>
                                                        <Typography variant="subtitle2">
                                                            {slot.fromTimeFrmt} - {slot.toTimeFrmt}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {`${"Regular"} - ${slot.regularSlots}`} |  {`${"Walkin"} - ${slot.totalSlots - slot.regularSlots}`} | {`Avail - ${slot.regularSlots - parseInt(slot.totalAppt)}`}
                                                        </Typography>
                                                    </Stack>
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </FormControl>
                            </React.Fragment >
                        }
                        {
                            this.state.activeStep === 1 && <React.Fragment>
                                <TextField
                                    sx={{
                                        mt: 3,
                                        '& .MuiInputBase-input': {
                                            padding: "4%",
                                        }
                                    }}
                                    placeholder='Search phone number'
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={(e) => this.handleKeyDown(e, "search")}>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                    helperText={formValidation.phnNumber ? "Incorrect phone number" : ""}
                                    onChange={this.onPhnNumberChange}
                                    onKeyDown={(e) => this.handleKeyDown(e, 'enter')}
                                    value={phnNumber}
                                    inputProps={{ maxLength: 10 }}
                                />
                                {
                                    isPhnNumberValid &&
                                    <React.Fragment>
                                        <Stack
                                            direction="row"
                                            spacing={2} sx={{ mt: 2 }}
                                            alignItems="center"
                                        >
                                            <Typography variant="h6" gutterBottom sx={{ flex: 1, m: 0 }}>
                                                Details
                                            </Typography>
                                            {
                                                !isDetailsForm && <Button
                                                    onClick={() => {
                                                        this.resetForm()
                                                        this.setState({ isDetailsForm: !isDetailsForm })
                                                    }}
                                                    startIcon={<AddIcon />}
                                                    size="small"
                                                >
                                                    New
                                                </Button>
                                            }
                                        </Stack>
                                        {
                                            (!isDetailsForm && !userDetails?.length) ? (
                                                <Box sx={{ textAlign: "left" }}>
                                                    <Typography variant="body1">
                                                        Please add your details
                                                    </Typography>
                                                </Box>
                                            ) : ("")
                                        }
                                        {/**User lists */}
                                        {
                                            !isDetailsForm && userDetails.map((user: any, i: number) => {
                                                return (
                                                    <Stack spacing={2} key={i} sx={{ my: 2 }}>
                                                        <Card
                                                        >
                                                            <Grid container spacing={2} >
                                                                <Grid item xs={9}>
                                                                    <Stack
                                                                        sx={{
                                                                            p: 2
                                                                        }}
                                                                    >
                                                                        <Typography variant="h6" sx={{ fontWeight: '600', mb: 1 }}>
                                                                            {user?.firstname}
                                                                        </Typography>
                                                                        <Typography variant="subtitle1" sx={{ fontWeight: '400' }}>
                                                                            Physique
                                                                        </Typography>
                                                                        <Typography variant="subtitle2" sx={{ fontWeight: '600' }}>
                                                                            {user?.gender} | Age - {user?.age} |  W - {user?.weight}kg | H - {user?.height}Cm
                                                                        </Typography>
                                                                    </Stack>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Stack
                                                                        alignItems={"center"}
                                                                        sx={{
                                                                            py: 3
                                                                        }}>
                                                                        <Fab color="primary" aria-label="add" size="small" onClick={() => this.onAddExistingPatient(i)}>
                                                                            <AddIcon />
                                                                        </Fab>
                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>
                                                        </Card>
                                                    </Stack>
                                                )
                                            })
                                        }

                                        {/**Details form */}
                                        {
                                            isDetailsForm && <React.Fragment>
                                                <TextField
                                                    sx={{
                                                        mt: 1,
                                                        '& .MuiInputBase-input': {
                                                            padding: "4%",
                                                        }
                                                    }}
                                                    onChange={(e) => this.onFormInputChange(e, "firstname")}
                                                    value={addDetailsForm.firstname}
                                                    placeholder='First Name'
                                                    fullWidth
                                                />
                                                <TextField
                                                    sx={{
                                                        mt: 2,
                                                        '& .MuiInputBase-input': {
                                                            padding: "4%",
                                                        }
                                                    }}
                                                    onChange={(e) => this.onFormInputChange(e, "lastname")}
                                                    value={addDetailsForm.lastname}
                                                    placeholder='Last name'
                                                    fullWidth
                                                />
                                                <Stack
                                                    direction="row"
                                                    spacing={2} sx={{ mt: 2 }}
                                                >
                                                    {
                                                        ["male", "female", "others"].map((gndr) => {
                                                            return <Chip
                                                                onClick={() => this.onFormInputChange(gndr, "gender")}
                                                                key={gndr}
                                                                icon={this.genderIcon(gndr)}
                                                                label={gndr}
                                                                {...(addDetailsForm?.gender !== gndr ? { variant: 'outlined' } : {})}
                                                            />
                                                        })
                                                    }
                                                </Stack>

                                                <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                        <DateField
                                                            format="DD-MM-YYYY"
                                                            onBlur={(e) => this.onChangeDate(e.target.value)}
                                                            // value={addDetailsForm.dob}
                                                            // defaultValue={dayjs('26-09-1996')}
                                                            sx={{
                                                                '& .MuiInputBase-input': {
                                                                    padding: "9%",
                                                                },
                                                                flex: '1 1 0',
                                                                width: '0',
                                                            }} />
                                                    </LocalizationProvider>
                                                    <TextField
                                                        sx={{
                                                            '& .MuiInputBase-input': {
                                                                padding: "9%",
                                                            },
                                                            flex: '1 1 0',
                                                            width: '0',
                                                        }}
                                                        placeholder='Age'
                                                        type='number'
                                                        inputProps={{ maxLength: 3 }}
                                                        onChange={(e) => this.onFormInputChange(e, "age")}
                                                        value={addDetailsForm.age}
                                                    />
                                                </Stack>
                                                <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
                                                    <TextField
                                                        className="user-wh-details"
                                                        sx={{
                                                            '& .MuiInputBase-input': {
                                                                padding: "9%",
                                                            },
                                                            flex: '1 1 0',
                                                            width: '0',
                                                        }}
                                                        placeholder='Height'
                                                        type='number'
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">Cm</InputAdornment>,
                                                        }}
                                                        onChange={(e) => this.onFormInputChange(e, "height")}
                                                        value={addDetailsForm.height}
                                                    />
                                                    <TextField
                                                        className="user-wh-details"
                                                        sx={{
                                                            '& .MuiInputBase-input': {
                                                                padding: "9%",
                                                            },
                                                            flex: '1 1 0',
                                                            width: '0',
                                                        }}
                                                        placeholder='Weight'
                                                        type='number'
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                                                        }}
                                                        inputProps={{ maxLength: 3 }}
                                                        onChange={(e) => this.onFormInputChange(e, "weight")}
                                                        value={addDetailsForm.weight}
                                                    />
                                                </Stack>

                                                {/* <DialogActions sx={{ mt: '1rem' }}>
                                                    <Button onClick={() => this.setState({ isDetailsForm: !isDetailsForm })}>Cancel</Button>
                                                    <Button
                                                        onClick={() => this.addDetails()}
                                                        disabled={!(this.validateAddDetailsForm())}
                                                    >
                                                        Add
                                                    </Button>
                                                </DialogActions> */}
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
                            {
                                this.state.activeStep > 0 && <Button
                                    size="small"
                                    color="inherit"
                                    disabled={this.state.activeStep === 0}
                                    startIcon={<ArrowBackIcon />}
                                    onClick={this.handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Prev
                                </Button>
                            }
                            <Box sx={{ flex: '1 1 auto' }} />
                            {
                                !this.isLastStep() && <Button
                                    size="small"
                                    endIcon={<ArrowForwardIcon />}
                                    disabled={!this.validateScreen1()}
                                    onClick={this.handleNext} sx={{ mr: 1 }}>
                                    Next
                                </Button>
                            }
                            {
                                this.state.activeStep === 1 && <>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={() => this.setState({ isDetailsForm: !isDetailsForm })}>Cancel</Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={() => this.addDetails()}
                                        disabled={!(this.validateAddDetailsForm())}
                                        sx={{ ml: '5px' }}
                                    >
                                        Add
                                    </Button>
                                </>
                            }
                        </Box>
                    </React.Fragment>

                </div>
            </Box>
        </>
    }
}

export default withHooks(AddAppointmentForm, [
    ['apptContext', useContext, [ApptContext]],
    ['appContext', useContext, [AppContext]],
    ['practiceSesContext', useContext, [PracticeSesContext]]
]);