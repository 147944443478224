import axios from 'axios';
import { appointmentServiceApi } from "../../../config/config";
// import CardViewMock from "../../../mock/card-view.json";
import { setupInterceptorsTo } from "../interceptors/axios.interceptos";
const axiosInstance = setupInterceptorsTo(axios.create());

export interface UpateUserProfile {
    firstname?: string,
    lastname?: string,
    gender?: "M" | "F" | "O",
    weight?: number,
    height?: number,
    dob?: Date,
    image_path?: string
}

class AppointmentService {

    async getAppointments({ practiceSessionId, apptStatus, scheduleOn }: any): Promise<any> {
        try {
            const { data } = await axiosInstance.get(`${appointmentServiceApi.baseUrl}/api/v1/session/${practiceSessionId}/appointments`, {
                params: {
                    date: scheduleOn,
                    apptStatus: apptStatus
                }
            });
            return data;
        } catch (error) {
            console.log('Error in Get Appointment')
            return {
                data: []
            }
        }
    }

    async makeAppointment(params: any): Promise<any> {
        try {
            return await axiosInstance.post(`${appointmentServiceApi.baseUrl}/api/v1/appointment/make`, params);
        } catch (error) {
            console.log('Error in Get Appointment')
            throw error;
        }
    }

    async cancelAppointment(params: any): Promise<any> {
        try {
            return await axiosInstance.put(`${appointmentServiceApi.baseUrl}/api/v1/appointment/cancel`, params);
        } catch (error) {
            console.error('Error in Cancel Appointment')
            throw error;
        }
    }

    async getPatient({ phnNumber }: any): Promise<any> {
        try {
            const { data } = await axiosInstance.get(`${appointmentServiceApi.baseUrl}/api/v1/patient/search?phoneNumber=${phnNumber}`);
            return data;
        } catch (error) {
            console.log('Error in getPatient', error)
            throw error;
        }
    }

    async reRescheduleAppt(params: any): Promise<any> {
        try {
            return await axiosInstance.put(`${appointmentServiceApi.baseUrl}/api/v1/appointment/reschedule`, params);
        } catch (error) {
            console.error('Error in reRescheduleAppt')
            throw error;
        }
    }

    /**
     * Get the card view details
     * @param practiceSessionId
     * @returns 
     */
    async getCardViewDetails(practiceSessionId: number) {
        try {
            const { data } = await axiosInstance.get(`${appointmentServiceApi.baseUrl}/api/v1/card-view?practiceSessionId=${practiceSessionId}`);
            return data?.data;
            // return CardViewMock;
        } catch (error) {
            throw error;
        }
    }

    /**
     * Change card view status or move the apoointment status
     * @param params 
     */
    async cardViewChangeStatus(params: any) {
        try {
            const { data } = await axiosInstance.post(`${appointmentServiceApi.baseUrl}/api/v1/card-view`, params);
            return data;
        } catch (error) {
            throw error;
        }
    }


    /**
     * Update the user profile details
     * @param params 
     * @returns 
     */
    async upateUserProfile(id: number, params: UpateUserProfile) {
        try {
            const { data } = await axiosInstance.put(`${appointmentServiceApi.baseUrl}/api/v1/user/profile/${id}`, params);
            return data;
        } catch (error) {
            throw error;
        }
    }


    async  notifyPatientsWithAppointments(appointmentIds: number[], message: string): Promise<any> {
        try {
       
            const notificationResponse = await axiosInstance.post(`${appointmentServiceApi.baseUrl}/api/v1/appointment/notify-patient`, {
                appointmentIds: appointmentIds,
                message: message
            });
    
            return notificationResponse.data; 
        } catch (error) {
            console.log('Error in Notify Patients with Appointments', error);
            throw error; 
        }
    }
}
const appointmentService = new AppointmentService();
export default appointmentService;