import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { AppContext } from '../../contexts/app.context';
import { getAttributeFromToken } from '../../hooks/use-auth';
import editProfileService from '../../services/http/edit-profile/edit-profile-service';

type EditProfileProps = {

}

const defaultImg = "";

export default function EditProfile(props: EditProfileProps) {
    const isMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
    const appContext: any = useContext(AppContext);
    const [image, setImage] = useState<string>("");
    const [accountInfo, setAccountInfo] = useState<any>({});
    const [imgType, setImageType] = useState<string>("jpeg")
    const [scale, setScale] = useState<number>(1);
    const [editor, setEditor] = useState<any>({});

    useEffect(() => {
        getUserProfile();
        return () => { }
    }, [])


    /**
     * Get user profile details
     */
    const getUserProfile = async (): Promise<any> => {
        try {
            const partyId = getAttributeFromToken('partyId')
            const resp = await editProfileService.getAccountProfile(partyId);
            const imageType = resp?.image_path ? resp?.image_path.split(".")[1] : "jpeg";
            setImageType(imageType);
            setAccountInfo(resp);
            setImage(resp?.image_path ? `${resp?.image_path}?time=${Date.now()}` : defaultImg);
        } catch (error) {
            console.error("Error in getUserProfile", error);
        }
    }

    /**
     * Upload image from local
     * @param dropped 
     */
    const handleDrop = (dropped: any) => {
        const { type } = dropped[0]
        const imageType = type ? type.split("/")[1] : "jpeg";
        setImageType(imageType);
        setImage(dropped[0]);
    }

    const setEditorRef = (editor: any) => {
        setEditor(editor)
    }

    /**
     * Handle zoom
     * @param e 
     * 
     */
    const handleScale = (e: React.ChangeEvent<HTMLInputElement>) => {
        const scale = parseFloat(e.target.value)
        setScale(scale)
    }

    /**
     * Save the avatar image to db & s3
     */
    const saveImg = async () => {
        try {
            const { uploadUrl } = await getSignedUrl();
            const canvas = editor.getImage().toDataURL();
            const file = await dataURItoBlob(canvas, imgType);

            await editProfileService.uploadProfileImg(uploadUrl, file, imgType);
            const { pathname } = new URL(uploadUrl);
            await updateUserProfileInfo(pathname.slice(1));
            appContext.toast({ open: true, message: "Profile Updated Successfully", severity: "success" });
        } catch (error) {
            console.error("Error in saveImg", error);
        }
    }

    /**
     * Convert base64 image to blob
     * @param dataURI 
     * @param imgType 
     * @returns 
     */
    async function dataURItoBlob(dataURI: any, imgType: string) {
        const binary = atob(dataURI.split(',')[1]);
        const array = [];
        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: `image/${imgType}` });
    }

    /**
     * Get s3 signed url for upload
     * @returns 
     */
    const getSignedUrl = async (): Promise<any> => {
        try {
            const partyId = getAttributeFromToken('partyId');
            return await editProfileService.getSignedUrl(partyId, `profile-img.${imgType}`);
        } catch (error) {
            console.error("Error in getSignedUrl", error);
        }
    }

    /**
     * Update db
     */
    const updateUserProfileInfo = async (imagePath: string): Promise<any> => {
        try {
            const updateResp = await editProfileService.updateAccountProfile(accountInfo?.id, { image_path: imagePath });
            appContext.setUserProfile({ imageUrl: updateResp.data?.image_path })
            console.log('UpdateResp', updateResp)
        } catch (error) {
            console.error("Error in updateUserProfileInfo", error);
        }
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 2,
                '.MuiContainer-root': {
                    margin: 0
                }
            }}>
            <Container maxWidth="xl">
                <Typography variant={isMd ? 'h6' : 'h5'} component="div" sx={{ flexGrow: 1, mt: 'auto', mb: 'auto' }}>
                    Edit Profile
                </Typography>
                <div style={{ textAlign: "center", marginTop: "5%" }}>
                    <Dropzone
                        onDrop={handleDrop}
                    // noClick
                    // noKeyboard
                    // style={{ width: '250px', height: '250px' }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <AvatarEditor
                                    // crossOrigin={""}
                                    ref={setEditorRef}
                                    width={300}
                                    height={300}
                                    scale={scale}
                                    image={image}
                                    borderRadius={200}
                                />
                                <input {...getInputProps()} />
                            </div>
                        )}
                    </Dropzone>
                </div>
                <input
                    style={{ width: "100%", marginTop: "5%", textAlign: "end" }}
                    name="scale"
                    type="range"
                    onChange={(e) => handleScale(e)}
                    min={'1'}
                    max="2"
                    step="0.01"
                    defaultValue="1"
                />
                <div style={{ textAlign: "end" }}>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => saveImg()}
                    >Save</Button>
                </div>
            </Container>
        </Box>
    )
}
