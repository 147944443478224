import React, { useContext, useState, useEffect } from "react";
import {
    Button, Dialog, DialogActions, DialogContent, FormControl, TextField,
    Slide, AppBar, Toolbar, Typography, IconButton, useMediaQuery, Stack
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
// import CancelIcon from "@mui/icons-material/Cancel";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ApptContext } from "../../contexts/appointment.context";
import { AppContext } from "../../../../contexts/app.context";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import MenuItem from '@mui/material/MenuItem';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import appointmentService from "../../../../services/http/appointments/appointment.service";
import { format } from "date-fns";



/**Appointment time in minutes */
export enum ApptRescheduleTime {
    MIN_TIME = 10,
    MAX_TIME = 180,
    TOTAL_TIME_IN_DAY = 1440
}

type ApptRescheduleProps = {
    isOpen: boolean;
    setApptRescheduleDrawer: Function;
    rescheduleAnchorEl: null | HTMLElement;
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const rescheduleTypes: string[] = ["Cancel Selected", "Postpone Selected", "Prepone Selected", "Postpone All", "Prepone All"];

export default function ApptReschedule(props: ApptRescheduleProps) {
    const appContext: any = useContext(AppContext);
    const apptContext: any = useContext(ApptContext);
    const { isOpen, setApptRescheduleDrawer } = props;
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [rescheduleType, setrescheduleType] = useState('');
    const [rescheduleTime, setRescheduleTime] = useState<any>(null);
    const [timeError, setTimeError] = useState<boolean>(false);
    const isMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

    useEffect(() => {
        return () => {
            setApptRescheduleDrawer(false);
            apptContext.selectAllAppointments(true);
        }
        // eslint-disable-next-line
    }, []);

    const handleClose = () => {
        setApptRescheduleDrawer(null);
    };

    const onClickReschedule = async (type: string): Promise<any> => {
        try {
            let message;
            const { appointments, loadAppointments, filter } = apptContext;
            const apptIds = appointments.filter(({ isSelect }: any) => isSelect).map((appt: any, indx: number) => {
                const { id } = appt;
                return id
            });

            if (type === "Postpone Selected" && !validateRescheduleTime()) {
                appContext.toast({
                    open: true,
                    message: `Can't reschedule appointments due to consulting time after 12 PM, Please reduce the time and try.`,
                    severity: "warning",
                    duration: 10000
                });
                setApptRescheduleDrawer(false);
                apptContext.selectAllAppointments(true);
                setConfirmDialog(false);
                resetForm();
                return
            }

            if (type === "Cancel Selected") {
                const { data } = await appointmentService.cancelAppointment({ apptIds });
                message = data?.message;
            } else if (type === "Postpone Selected" || type === "Prepone Selected") {
                const payload = {
                    type: type === "Postpone Selected" ? "Postpone" : "Prepone",
                    apptIds,
                    timeStr: rescheduleTime?.format("HH:mm"),
                    // validateOnly: true,
                    sessionId: filter?.practiceSessionId,
                    appointmentDate: format(filter?.scheduleOn, 'yyyy-MM-dd')
                }
                const { data } = await appointmentService.reRescheduleAppt(payload);
                message = data?.message;
            } else {
                const payload = {
                    type: type === "Postpone All" ? "PostponeAll" : "PreponeAll",
                    apptIds: [],
                    timeStr: rescheduleTime?.format("HH:mm"),
                    // validateOnly: true,
                    sessionId: filter?.practiceSessionId,
                    appointmentDate: format(filter?.scheduleOn, 'yyyy-MM-dd')
                }
                console.log("API Call to Postpone or Prepond All");
                const { data } = await appointmentService.reRescheduleAppt(payload);
                message = data?.message;
            }
            setApptRescheduleDrawer(false);
            apptContext.selectAllAppointments(true);
            setConfirmDialog(false);
            resetForm();
            loadAppointments();
            appContext.toast({ open: true, message, severity: "success" });
            window.location.reload();
        } catch (error: any) {
            setConfirmDialog(false);
            setrescheduleType('');
            appContext.toast({ open: true, message: error?.response?.data?.error, severity: "warning" });
            console.error(error);
        }
    };

    const validateRescheduleTime = (): boolean => {
        const { appointments } = apptContext;

        const status = appointments.filter(({ isSelect }: any) => isSelect).every((appt: any) => {

            const scheduleTime = new Date(appt.scheduledOn).toLocaleTimeString('en',
                { timeStyle: 'short', hour12: false, timeZone: 'UTC' });
            const [hour, minutes]: any = scheduleTime.split(":");
            const time = (Math.floor(parseInt(hour) * 60) + parseInt(minutes)) + (Math.floor(rescheduleTime?.hour() * 60) + rescheduleTime?.minute())

            if (time > ApptRescheduleTime.TOTAL_TIME_IN_DAY) return false;

            return true;
        });
        return status;
    }

    const onSelectChange = (e: any): void => {
        const value = e?.target?.value;
        setrescheduleType(value ?? '');
    }

    const resetForm = (): void => {
        setrescheduleType('');
        setRescheduleTime(null);
    }

    const validateForm = (): boolean => {
        const time = Math.floor(rescheduleTime?.hour() * 60) + rescheduleTime?.minute();
        if (!rescheduleType || (rescheduleType !== "Cancel Selected" &&
            (!rescheduleTime || validateTime(time)))) return true
        return false
    }

    const validateTime = (time: number): boolean => {
        return time < ApptRescheduleTime.MIN_TIME || time > ApptRescheduleTime.MAX_TIME
    }

    const onSelectTime = (dateTime: any) => {
        const time = Math.floor(dateTime?.hour() * 60) + dateTime?.minute();
        if (validateTime(time)) setTimeError(true);
        setRescheduleTime(dateTime);
    }

    const errorMessage = () => {
        const time = Math.floor(rescheduleTime?.hour() * 60) + rescheduleTime?.minute();
        if (time < ApptRescheduleTime.MIN_TIME) return "Duration should not be less than 10 mins"
        if (time > ApptRescheduleTime.MAX_TIME) return "Duration should not be Greater than 3 hours"
    }

    const checkIsApptSelectd = (type: string): boolean => {
        if (type === "Postpone All" || type === "Prepone All") return true;

        const { appointments } = apptContext;
        const apptIds = appointments.filter(({ isSelect }: any) => isSelect);
        
        return apptIds?.length ? true : false;
    }

    // const getIcon = (type: string) => {
    //     if (type === "Cancel Selected") return <CancelIcon />;
    //     if (type === "Postpone Selected") return <ArrowForwardIcon />;
    //     if (type === "Prepone Selected") return <ArrowBackIcon />;   
    // };

    return (
        <React.Fragment>
            <ConfirmDialog
                title={`Reschedule Confirmation`}
                body={`Would you like to ${rescheduleType.toLocaleLowerCase()} appointment ?`}
                open={confirmDialog}
                buttonProps={
                    [{
                        label: 'Cancel'
                    },
                    {
                        label: 'Ok'
                    }]
                }
                onClose={() => setConfirmDialog(false)}
                confirmCallback={() => onClickReschedule(rescheduleType)}
            />
            <Dialog
                fullScreen={false}
                open={isOpen}
                TransitionComponent={Transition}
            >
                <AppBar sx={{
                    width: `${!isMd ? '500px' : '300px'}`,
                    position: 'relative',
                    background: 'none',
                    boxShadow: 'none !important',
                    color: 'neutral.900'
                }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            Reschedule Appointment
                        </Typography>

                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            onClick={() => handleClose()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <DialogContent>
                    <FormControl variant="standard" sx={{ mb: 2, minWidth: '100%' }}>
                        <TextField
                            label="Rescheduling Options"
                            fullWidth
                            select
                            // helperText={formError?.practiceSessionId ? 'Please select practice session' : ''}
                            onChange={onSelectChange}
                            value={rescheduleType ?? ''}
                            // error={formError?.practiceSessionId}
                            variant="standard"
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            {
                                rescheduleTypes.map((type: any, i: number) => (
                                    <MenuItem value={type} key={i} disabled={!checkIsApptSelectd(type)}>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginLeft: "1%"
                                            }}
                                        >
                                            {/* {getIcon(type)}{" "} */}
                                            {type}
                                        </Typography>
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </FormControl>

                    {

                        rescheduleType && rescheduleType !== "Cancel Selected" ? (
                            <Stack direction="row" sx={{ '.MuiStack-root': { width: '100%', padding: 0 }, '.MuiInputBase-root': { boxShadow: 'unset' } }}>
                                <DemoItem label="Choose Time">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['TimeField', 'TimeField']}>
                                            <TimeField
                                                format="HH:mm"
                                                value={rescheduleTime}
                                                slotProps={{
                                                    textField: {
                                                        helperText: timeError ? errorMessage() : "",
                                                    },
                                                }}
                                                onChange={(newValue) => onSelectTime(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </DemoItem>
                            </Stack>
                        ) : ("")
                    }

                    <DialogActions sx={{ mt: '1rem' }}>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            disabled={validateForm()}
                            onClick={() => {
                                handleClose();
                                setConfirmDialog(true);
                                // window.location.reload();
                            }}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}