import React from "react";
import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

interface SkeletonProps {
    iteration?: number;
    isMd?: boolean;
}

const ReportsSkeleton = (props: SkeletonProps) => {
    return (
        <React.Fragment>
           
            <Card sx={{ mb: '1rem', width: '100%' }}  >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container sx={{ padding: '0rem' }}>
                    <Grid container >

                        <Grid item xs={6} sm container sx={{ padding: '0rem 15rem 0rem 0.5rem' }}>
                            <CardContent sx={{
                                flex: '1 0 auto', justifyContent: 'center',
                                padding: '6px 6px !important',
                              
                            }}>
                                <Typography component="div" variant="h3" >
                                    <Skeleton animation={'wave'} />
                                </Typography>
                            </CardContent>
                        </Grid>
                        <Grid item xs={6} sm container sx={{ padding: '0rem 0.5rem 0rem 015rem' }}>
                            <CardContent sx={{
                                flex: '1 0 auto', justifyContent: 'center',
                                padding: '6px 6px !important'
                            }}>
                                <Typography component="div" variant="h3" >
                                    <Skeleton  animation={'wave'}/>
                                </Typography>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Grid>
                    <Grid container sx={{ padding: '0rem' }}>
                        <Grid item xs={12} sx={{
                            padding: '0rem',
                            ...(props.isMd && {
                                background: '#0c3376',
                                color: '#f9f9f9',
                                
                                
                                '& .schedule-datetime': {
                                    color: '#f9f9f9',
                                    fontWeight: '400',
                                  
                                }
                            })
                        }}>
                            <CardContent sx={{
                                flex: '1 0 auto',
                                textAlign: 'center',
                                padding: '6px 12px !important',
                                
                            }}>
                                <Typography component="div" variant="h3">
                                    <Skeleton />
                                </Typography>
                            </CardContent>
                        </Grid>
                       
                        {
                        [1,2,3].map(item=>{
                          return(
                            <Grid container  key={item}>
                            <Grid item xs={4} sm container sx={{ padding: '0rem' }}>
                                <CardContent sx={{
                                    flex: '1 0 auto', justifyContent: 'center',
                                    padding: '6px 12px !important'
                                }}>
                                    <Typography component="div" variant="h6" >
                                        <Skeleton />
                                    </Typography>
                                    <Typography sx={{ mt: 1.5 }} component="div" variant="subtitle1" >
                                        <Skeleton />
                                    </Typography>
                                </CardContent>
                            </Grid>
                            <Grid item xs={4} sm container sx={{ padding: '0rem' }}>
                                <CardContent sx={{
                                    flex: '1 0 auto', justifyContent: 'center',
                                    padding: '6px 15px !important'
                                }}>
                                    <Typography component="div" variant="h6" >
                                        <Skeleton />
                                    </Typography>
                                    <Typography sx={{ mt: 1.5 }} component="div" variant="subtitle1" >
                                        <Skeleton />
                                    </Typography>
                                </CardContent>
                            </Grid>
                            <Grid item xs={4} sm container sx={{ padding: '0rem' }}>
                                <CardContent sx={{
                                    flex: '1 0 auto', justifyContent: 'center',
                                    padding: '6px 15px !important'
                                }}>
                                    <Typography component="div" variant="h6" >
                                        <Skeleton animation={'wave'}/>
                                    </Typography>
                                    <Typography sx={{ mt: 1.5 }} component="div" variant="subtitle1" >
                                        <Skeleton animation={'wave'} />
                                    </Typography>
                                </CardContent>
                            </Grid>
                        </Grid>
                          )
                        })
                        }
                    </Grid>
                </Box>
            </Card>
                            
        </React.Fragment>
    );
}

export default ReportsSkeleton;