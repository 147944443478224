const common = {
    schedulaLegacyAppUrl: process.env.REACT_APP_SCHEDULA_LEGACY_APP_URL,
    schedulaDomainName: process.env.REACT_APP_SCHEDULA_DOMAIN_NAME,
    schedulaToken:  process.env.REACT_APP_SCHEDULA_TOKEN
}
const schedulaApi = {
    baseUrl: process.env.REACT_APP_SCHEDULA_API_URL
}

const developerIdPass = {
    user: process.env.REACT_APP_SCHEDULA_IVR_USERNAME,
}

const appointmentServiceApi = {
    baseUrl: process.env.REACT_APP_APPT_SERVICE_API_URL
}

const sessionServiceApi = {
    baseUrl: process.env.REACT_APP_SESSION_SERVICE_API_URL
}

const availabilityService = {
    baseUrl: process.env.REACT_APP_AVAIL_SERVICE_API_URL
}

const callLogsNewApiService = {
    baseUrl: process.env.REACT_APP_SCHEDULA_IVR_API_URL
}

export {
    schedulaApi,
    appointmentServiceApi,
    sessionServiceApi,
    availabilityService,
    developerIdPass,
    common,
    callLogsNewApiService
}