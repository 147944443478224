import { FormControl, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Component, ReactNode, useContext } from "react";
import withHooks from '../../../../utils/withHooks';
import { PracticeSesContext } from '../../../practice-session/contexts/practice-session.context';
import { ApptContext } from '../../contexts/appointment.context';
import { timeStrFormat } from '../../../../utils/common';

class AppointmentFilter extends Component<any, any> {

    state: any;

    constructor(props: any) {
        super(props);

        this.state = {
            open: props.open ?? false,
            practiceSessionError: false,
            apptStatusError: false,
        }
    }

    onChangeDate = async (e: any) => {
        this.apptContext.setFilterValues('scheduleOn', e);
        setTimeout(async () => {
            await this.practiceSesContext.loadPracticeSessions();
            this.props.onFilter()
        }, 10);
    }

    onChangePracticeSession = (e: React.ChangeEvent<any>) => {
        this.apptContext.setFilterValues('practiceSessionId', e.target.value);
        this.practiceSesContext.setSeletectedSession(e.target.value);
        this.props.onFilter()
    }

    get apptContext() {
        return this.props.apptContext;
    }

    get practiceSesContext() {
        return this.props.practiceSesContext;
    }

    render(): ReactNode {
        const { formError } = this.props;
        return <>
            <Stack spacing={3} sx={{ px: '1rem' }}>
                <MobileDatePicker
                    label="Date"
                    closeOnSelect={true}
                    value={this.apptContext.filter.scheduleOn}
                    onChange={this.onChangeDate}
                    format="dd-MMMM-yyyy"
                />
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120}}>
                    <TextField
                        label="Session"
                        id="practiceSessionId"
                        select
                        helperText={formError?.practiceSessionId ? 'Please select practice session' : ''}
                        onChange={this.onChangePracticeSession}
                        value={this.practiceSesContext.selectedPracticeSession?.practiceSessionId ?? ''}
                        error={formError?.practiceSessionId}
                        variant="standard"
                    >
                        <MenuItem value="">
                            <em>Select</em>
                        </MenuItem>
                        {
                            this.practiceSesContext.practiceSessions.map((pses: any, i: number) => (
                                <MenuItem value={pses.practiceSessionId} key={i}>
                                    <Stack>
                                        <Typography variant="subtitle2">
                                            {pses.orgName}, {pses.area}
                                        </Typography>
                                        <Typography variant="body2">
                                            {timeStrFormat(pses.consultingFromTime)} - {timeStrFormat(pses.consultingToTime)}
                                        </Typography>
                                    </Stack>
                                </MenuItem>
                            ))
                        }
                    </TextField>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <TextField
                        label="Appointment Status"
                        id="apptStatus"
                        select
                        onChange={(e: any) => {
                            this.setState({ apptStatusError: !e.target.value });
                            this.apptContext.setFilterValues('apptStatus', e.target.value);
                            setTimeout(() => {
                                this.apptContext.loadAppointments();
                            }, 10);
                        }}
                        value={this.apptContext.filter.apptStatus}
                        variant="standard"

                    >
                        <MenuItem value="all">
                            <em>All</em>
                        </MenuItem>
                        <MenuItem value="1">Confirmed</MenuItem>
                        <MenuItem value="2">Cancelled</MenuItem>

                    </TextField>
                </FormControl>
            </Stack>
        </>
    }
}

export default withHooks(AppointmentFilter, [
    ['apptContext', useContext, [ApptContext]],
    ['practiceSesContext', useContext, [PracticeSesContext]]
]);