
import React, { useContext } from 'react';
import { Box, Card, Grid, Typography, Stack, Button, Checkbox, useMediaQuery } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import appointmentService from '../../../services/http/appointments/appointment.service';
import { useLocation } from 'react-router-dom';
import { ApptCardViewContext } from '../contexts/card-view.context';
import { AppContext } from '../../../contexts/app.context';

export type TabType = 'no-shows' | 'appointments' | 'in-queue' | 'checked-out';

interface ListCardProps {
    tab: TabType,
    cardDetails: any[],
    getCardData: React.Dispatch<React.SetStateAction<any>>;
}

export enum CardStatusMapping {
    Appointments = 1,
    Queue = 4,
    No_Shows = 6,
    Check_Out = 5
}

export enum AppointmentsAttrEnum {
    'no-shows' = "NoShows",
    'appointments' = "Appointments",
    'in-queue' = "InQueue",
    'checked-out' = "CheckedOut"

}


export default function ListCard(props: ListCardProps) {
    const { tab, cardDetails } = props;
    const cardViewCnxt = useContext(ApptCardViewContext);
    const appContext: any = useContext(AppContext)
    const { setCardView, cardView }: any = cardViewCnxt;
    const isMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
    const { state } = useLocation();

    /**
     * Change the card view status
     * @param appt 
     */
    const changeStatus = async (appt: any, tab: any): Promise<void> => {
        try {
            const payload = {
                appointmentIds: [appt?.id],
                status: CardStatusMapping[tab]
            }
            // console.log("ind payload-->", payload);
            const { message } = await appointmentService.cardViewChangeStatus(payload);
            getCardViewDetails();
            appContext.toast({ open: true, message, severity: "success" });
        } catch (error) {
            console.error("Error in changeStatus", error);
        }
    }

    /**
     * Get the latest carview details & update the state
     */
    const getCardViewDetails = async (): Promise<void> => {
        try {
            const resp = await appointmentService.getCardViewDetails(state?.practiceSessionId);
            setCardView(resp);
        } catch (error) {
            console.error("Error in getCardViewDetails", error);
        }
    }


    /**
     * onChange the checkox
     * @param e 
     * @param indx 
     */
    const onClickCheckBox = (e: React.ChangeEvent<HTMLInputElement>, indx: number): void => {
        const checked = e?.target?.checked;
        const _cardDetails = JSON.parse(JSON.stringify(cardDetails));
        _cardDetails[indx]['checked'] = checked;

        cardView['appointments'][AppointmentsAttrEnum[tab]] = _cardDetails;
        setCardView(cardView);
    }

    return (
        <React.Fragment>
            <Stack spacing={2} sx={{
                mt: 2,
                '.MuiPaper-root': {
                    boxShadow: 'unset',
                },
            }}>
                {
                    cardDetails?.length ? (cardDetails.map((item: any, indx: number) => {
                        return (
                            <Card
                                sx={{
                                    padding: '0px',
                                    backgroundColor: '#f3f3f3',
                                    '.MuiPaper-root': {
                                        boxShadow: 'unset !important',
                                    },
                                    '.MuiPaper-elevation': {
                                        boxShadow: 'unset !important',
                                    }
                                }}
                                key={indx}>
                                <Grid
                                    container
                                    spacing={2} sx={{
                                        pt: '0px !important'
                                    }}>
                                    <Grid item xs={12} md={12}>
                                        <Box sx={{
                                            padding: '1rem !important'
                                        }}>
                                            <Stack direction="row" spacing={2}>
                                                <Box sx={{ width: `${isMd ? '15%' : '5%'}` }}>
                                                    <Typography variant="h3"
                                                        sx={{ color: "#ffffff", background: "#0c3376", width: "50px", textAlign: "center", borderRadius: "6px" }}
                                                    >
                                                        {item?.apptNumber}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: `${isMd ? '60%' : '70%'}` }}>
                                                    <Typography variant="h6" component="span">
                                                        {item?.name} {item?.gender}
                                                    </Typography>
                                                    <Typography variant="caption" component="div">
                                                        {item?.phoneNumber}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: '25%', textAlign: "end" }}>
                                                    <Checkbox sx={{ pt: 0 }} size={"small"} checked={item?.checked ?? false} onChange={(e) => onClickCheckBox(e, indx)} />
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {
                                    (tab === 'appointments' || tab === 'in-queue') ? (
                                        <Grid container spacing={2} sx={{ pt: 0, '.MuiGrid-root': { paddingTop: "0px" } }}>
                                            <Grid item xs={6} md={6}>
                                                <Box sx={{
                                                    padding: '1rem '
                                                }}>
                                                    <Button
                                                        sx={{ width: "100%" }}
                                                        size="small"
                                                        variant="outlined"
                                                        startIcon={<ArrowBackIcon />}
                                                        disabled={item?.checked}
                                                        onClick={() => changeStatus(item, tab === 'appointments' ? 'No_Shows' : "Appointments")}
                                                    >
                                                        {tab === 'appointments' ? 'No Shows' : "Appointments"}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <Box sx={{
                                                    padding: '1rem '
                                                }}>
                                                    <Button
                                                        sx={{ width: "100%" }}
                                                        size="small"
                                                        variant="outlined"
                                                        endIcon={<ArrowForwardIcon />}
                                                        disabled={item?.checked}
                                                        onClick={() => changeStatus(item, tab === 'appointments' ? 'Queue' : "Check_Out")}
                                                    >
                                                        {tab === 'appointments' ? 'Queue' : "Check Out"}
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid container spacing={2} sx={{ pt: 0, '.MuiGrid-root': { paddingTop: "0px" } }}>
                                            <Grid item xs={12} md={12}>
                                                <Box sx={{
                                                    padding: '1rem '
                                                }}>
                                                    {
                                                        tab === 'no-shows' && <Button
                                                            sx={{ width: "100%" }}
                                                            size="small"
                                                            variant="outlined"
                                                            endIcon={<ArrowForwardIcon />}
                                                            disabled={item?.checked}
                                                            onClick={() => changeStatus(item, "Appointments")}
                                                        >
                                                            {"Appointments"}
                                                        </Button>
                                                    }
                                                    {
                                                        tab === 'checked-out' && <Button
                                                            sx={{ width: "100%" }}
                                                            size="small"
                                                            variant="outlined"
                                                            startIcon={<ArrowBackIcon />}
                                                            disabled={item?.checked}
                                                            onClick={() => changeStatus(item, "Queue")}
                                                        >
                                                            {"Queue"}
                                                        </Button>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Card>)
                    })) : (<Typography variant="caption" component="div" textAlign="center">
                        No Data
                    </Typography>)
                }
            </Stack>
        </React.Fragment>
    )
}
