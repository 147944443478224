import React, { useContext } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { PracticeSesContext } from '../contexts/practice-session.context';
import withHooks from '../../../utils/withHooks';
import { weekDaysMapping } from '../../leave/components/ApplyLeave';
import { timeStrFormat } from '../../../utils/common';
import { format } from 'date-fns';

class ListSessionCard extends React.Component<any, any> {

    state: any;

    constructor(props: any) {
        super(props);

        this.state = {
            selectedId: null
        }
    }

    get practiceSesContext() {
        return this.props.practiceSesContext;
    }

    render(): React.ReactNode {
        let { totalSlots, ivrNum, psDate } = this.props;
        psDate = psDate ? format(new Date(psDate), 'dd-MM-yyyy') : '';

        return (
            <React.Fragment>
                {/**Session details card */}
                <Card sx={{ padding: '0px' }} >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid container spacing={2} sx={{ pt: '0px !important' }}>
                            <Grid item xs={12} md={12}>
                                <Box sx={{
                                    padding: '1rem !important'
                                }}>
                                    <Typography variant="h6" component="span">
                                        {this.props.orgName}, {this.props.area}
                                    </Typography>
                                    <Typography variant="caption" component="div">
                                        {
                                            this.props?.day ? weekDaysMapping(this.props.day.split(',')) : `Custom (${psDate})`
                                        }
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid container sx={{ pl: '1rem' }}>
                                <Grid item xs={6} md={5} lg={3} sm container sx={{ pt: '0px !important' }}>
                                    <CardContent sx={{
                                        flex: '1 0 auto',
                                        justifyContent: 'center',
                                        padding: '0px 0px 0rem 1rem !important',
                                        lineHeight: 0
                                    }}>
                                        <Typography variant="caption" color="text.secondary" component="div">
                                            Consulting Time
                                        </Typography>
                                        <Typography variant="subtitle1" display="block" sx={{ margin: 0 }}>
                                            {timeStrFormat(this.props.consultingFromTime)} -  {timeStrFormat(this.props.consultingToTime)}                                        </Typography>
                                    </CardContent>
                                </Grid>
                                <Grid item xs={6} md={5} lg={3} sm container sx={{ pt: '0px !important' }}>
                                    <CardContent sx={{
                                        flex: '1 0 auto',
                                        justifyContent: 'center',
                                        padding: '0px 0px 1rem 1rem !important',
                                        lineHeight: 0
                                    }}>
                                        <Typography variant="caption" color="text.secondary" component="div">
                                            Booking Time
                                        </Typography>
                                        <Typography variant="subtitle1" display="block" sx={{ margin: 0 }}>
                                            {this.props.apptBookingFromTimeFrmt} -  {this.props.apptBookingToTimeFrmt}                                        </Typography>
                                    </CardContent>
                                </Grid>
                                <Grid item xs={6} md={5} lg={3} sm container sx={{ pt: '0px !important' }}>
                                    <CardContent sx={{
                                        flex: '1 0 auto',
                                        justifyContent: 'center',
                                        padding: '0px 0px 1rem 1rem !important',
                                        lineHeight: 0
                                    }}>
                                        <Typography variant="caption" color="text.secondary" component="div">
                                            Phone
                                        </Typography>
                                        <Typography variant="subtitle1" display="block" sx={{ margin: 0 }}>
                                            {ivrNum}
                                        </Typography>
                                    </CardContent>
                                </Grid>
                                <Grid item xs={6} md={5} lg={3} sm container sx={{ pt: '0px !important' }}>
                                    <CardContent sx={{
                                        flex: '1 0 auto',
                                        justifyContent: 'center',
                                        padding: '0px 0px 1rem 1rem !important',
                                        lineHeight: 0
                                    }}>
                                        <Typography variant="caption" color="text.secondary" component="div">
                                            Total Appts
                                        </Typography>
                                        <Typography variant="subtitle1" display="block" sx={{ margin: 0 }}>
                                            {totalSlots}
                                        </Typography>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </React.Fragment>
        )
    }
}

export default withHooks(ListSessionCard, [
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
    ['practiceSesContext', useContext, [PracticeSesContext]],
    ['theme', useTheme]
]);