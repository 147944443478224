import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog, DialogContent, useMediaQuery,
    useTheme, TextField, Stack, Chip, InputAdornment, DialogActions, Button
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import withHooks from '../../../../utils/withHooks';
import { ApptContext } from '../../contexts/appointment.context';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import { format, parse } from 'date-fns';
import { calculateAge } from "../../../../utils/common";
import { AppContext } from '../../../../contexts/app.context';
import appointmentService from '../../../../services/http/appointments/appointment.service';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class EditAppointmentModal extends React.Component<any, any, {}> {

    state: any;


    constructor(props: any) {
        super(props);

        this.state = {
            open: props.open ?? false,
            addDetailsForm: {
                firstname: "",
                lastname: "",
                dob: "",
                age: "",
                height: "",
                weight: "",
                gender: "",
            }
        }
    }


    componentWillReceiveProps(nextProps: any) {
        const { name, gender, weight, height, age, lastName, dob, userProfileId } = nextProps.apptContext.editSelectedAppt;
        const userDetails = {
            firstname: name ?? "",
            lastname: lastName,
            dob: dob,
            age: age ?? "",
            height: height ?? "",
            weight: weight ?? "",
            gender: gender ?? ""
        }

        this.setState({ addDetailsForm: userDetails, userProfileId: userProfileId ?? "" })
    }


    onFormInputChange = (e: any, attr: string) => {
        let value;
        if (attr !== "gender") {
            value = e.target.value;
            if (attr === "weight" || attr === "height") value = parseInt(e.target.value);
        }

        const { addDetailsForm } = this.state;
        this.setState({
            addDetailsForm: { ...addDetailsForm, [attr]: attr !== "gender" ? value : e }
        })
    }

    onChangeDate = async (date: any) => {
        const parsedDob = parse(date, "dd-MM-yyyy", new Date()) //format -> yyyy:mm:dd
        const dob = format(parsedDob, 'yyyy-MM-dd')//format -> yyyy:mm:dd
        const { addDetailsForm } = this.state;
        this.setState({
            addDetailsForm: { ...addDetailsForm, dob, age: calculateAge(dob) }
        })
    }

    validateAddDetailsForm = () => {
        const { addDetailsForm } = this.state;
        if (!addDetailsForm?.firstname) return false;
        return true;
    }


    genderIcon = (gender: string) => {
        if (gender === "male") return <ManIcon />
        if (gender === "female") return <WomanIcon />
    }

    resetForm = (): void => {
        const { addDetailsForm } = this.state;
        Object.keys(addDetailsForm).forEach(function (key) {
            if (key !== "gender") addDetailsForm[key] = "";
        });
        this.setState({ addDetailsForm });
    }

    /**
     * Update user details
     */
    updateApptDetails = async (): Promise<void> => {
        const { setEditApptModal, loadAppointments } = this.apptContext;
        try {
            const userDetails = JSON.parse(JSON.stringify(this.state.addDetailsForm));

            Object.keys(userDetails).forEach((key) => (userDetails[key] === null || !userDetails[key]) && delete userDetails[key]); //remove empty keys
            delete userDetails["age"];

            const { message } = await appointmentService.upateUserProfile(this.state.userProfileId, userDetails);

            this.appContext.toast({
                open: true,
                message,
                severity: 'success',
                duration: 2000
            });

            this.resetForm();
            loadAppointments();
        } catch (error: any) {
            console.log("Error in updateApptDetails", error);
            this.appContext.toast({
                open: true,
                message: error?.response?.data?.message,
                severity: 'warning',
                duration: 2000
            });
        } finally {
            setEditApptModal(false);
        }
    }

    get apptContext() {
        return this.props.apptContext;
    }

    get appContext() {
        return this.props.appContext;
    }


    render(): React.ReactNode {
        const { addDetailsForm } = this.state;
        const { editApptModal, setEditApptModal } = this.apptContext;

        return <>
            <Dialog
                fullScreen={this.props.isMd}
                open={editApptModal}
                TransitionComponent={Transition}
            >
                <AppBar sx={{
                    width: `${!this.props.isMd ? '500px' : 'auto'}`,
                    position: 'relative',
                    background: 'none',
                    boxShadow: 'none !important',
                    color: 'neutral.900',
                }}>
                    <Toolbar>
                        <Typography sx={{ flex: 1 }} variant="h6" component="div">
                            Edit Appointment
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            onClick={() => setEditApptModal(!editApptModal)}

                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <React.Fragment>
                        <TextField
                            sx={{
                                mt: 1,
                                '& .MuiInputBase-input': {
                                    padding: "4%",
                                }
                            }}
                            onChange={(e) => this.onFormInputChange(e, "firstname")}
                            value={addDetailsForm.firstname}
                            placeholder='First Name'
                            fullWidth
                        />
                        <TextField
                            sx={{
                                mt: 2,
                                '& .MuiInputBase-input': {
                                    padding: "4%",
                                }
                            }}
                            onChange={(e) => this.onFormInputChange(e, "lastname")}
                            value={addDetailsForm.lastname}
                            placeholder='Last name'
                            fullWidth
                        />
                        <Stack
                            direction="row"
                            spacing={2} sx={{ mt: 2 }}
                        >
                            {
                                [{ name: "male", key: "M" }, { name: "female", key: "F" }, { name: "others", key: "O" }].map((gndr: any) => {
                                    return <Chip
                                        onClick={() => this.onFormInputChange(gndr.key, "gender")}
                                        key={gndr.key}
                                        icon={this.genderIcon(gndr.name)}
                                        label={gndr.name}
                                        {...(addDetailsForm?.gender !== gndr.key ? { variant: 'outlined' } : {})}
                                    />
                                })
                            }
                        </Stack>

                        <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DateField
                                    disabled={false}
                                    format="DD-MM-YYYY"
                                    onBlur={(e) => this.onChangeDate(e.target.value)}
                                    // value={addDetailsForm?.dob}
                                    // defaultValue={dayjs('26-09-1996')}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            padding: "9%",
                                        },
                                        flex: '1 1 0',
                                        width: '0',
                                    }} />
                            </LocalizationProvider>
                            <TextField
                                sx={{
                                    '& .MuiInputBase-input': {
                                        padding: "9%",
                                    },
                                    flex: '1 1 0',
                                    width: '0',
                                }}
                                disabled
                                placeholder='Age'
                                type='number'
                                inputProps={{ maxLength: 3 }}
                                onChange={(e) => this.onFormInputChange(e, "age")}
                                value={addDetailsForm.age}
                            />
                        </Stack>
                        <Stack spacing={2} direction="row" sx={{ mt: 2 }}>
                            <TextField
                                className="user-wh-details"
                                sx={{
                                    '& .MuiInputBase-input': {
                                        padding: "9%",
                                    },
                                    flex: '1 1 0',
                                    width: '0',
                                }}
                                placeholder='Height'
                                type='number'
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Cm</InputAdornment>,
                                }}
                                onChange={(e) => this.onFormInputChange(e, "height")}
                                value={addDetailsForm.height}
                            />
                            <TextField
                                className="user-wh-details"
                                sx={{
                                    '& .MuiInputBase-input': {
                                        padding: "9%",
                                    },
                                    flex: '1 1 0',
                                    width: '0',
                                }}
                                placeholder='Weight'
                                type='number'
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                                }}
                                inputProps={{ maxLength: 3 }}
                                onChange={(e) => this.onFormInputChange(e, "weight")}
                                value={addDetailsForm.weight}
                            />
                        </Stack>

                        <DialogActions sx={{ mt: '1rem' }}>
                            <Button onClick={() => setEditApptModal(!editApptModal)}>Cancel</Button>
                            <Button
                                onClick={() => this.updateApptDetails()}
                                disabled={!(this.validateAddDetailsForm())}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </React.Fragment>
                </DialogContent>
            </Dialog>
        </>
    }
}

export default withHooks(EditAppointmentModal, [
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
    ['apptContext', React.useContext, [ApptContext]],
    ['appContext', React.useContext, [AppContext]],
    ['theme', useTheme]
]);
