import axios from 'axios';
// import CallLogsMock from "../../../mock/call-logs.json";
import { appointmentServiceApi } from "../../../config/config";
import { setupInterceptorsTo } from "../interceptors/axios.interceptos";
import { UpateUserProfile } from '../appointments/appointment.service';
const axiosInstance = setupInterceptorsTo(axios.create());

class EditProfileService {

    /**
     * Get account details
     * @param partyId 
     * @returns 
     */
    async getAccountProfile(partyId: number) {
        try {
            const { data } = await axiosInstance.get(`${appointmentServiceApi.baseUrl}/api/v1/user/profile/${partyId}`);
            return data?.data;
        } catch (error) {
            console.error("Error in getAccountProfile", error);
        }
    }

    /**
     * Get signed url for s3 upload 
     * @param partyId 
     * @param fileName 
     * @returns 
     */
    async getSignedUrl(partyId: number, fileName: string) {
        try {
            const { data } = await axiosInstance.get(`${appointmentServiceApi.baseUrl}/api/v1/settings/profile/image/get-signed-url?partyId=${partyId}&fileName=${fileName}`);
            return data;
        } catch (error) {
            console.error("Error in getSignedUrl", error);
        }
    }

    /**
     * Update user profile details in db
     * @param id 
     * @param params 
     */
    async updateAccountProfile(id: number, params: UpateUserProfile) {
        try {
            return (await axiosInstance.put(`${appointmentServiceApi.baseUrl}/api/v1/user/profile/${id}`, params)).data;
        } catch (error) {
            console.error("Error in updateAccountProfile", error);
        }
    }

    /**
     * Upload image using s3 signed URL
     * @param url 
     * @param file 
     * @param contetType 
     */
    async uploadProfileImg(url: string, file: any, contetType: string) {
        try {
            const params = {
                headers: {
                    'Content-Type': `image/${contetType}`,
                }
            }
            const resp = await axiosInstance.put(url, file, params);
            console.log("S3 Upload Resp", resp)
        } catch (error) {
            console.error("Error in uploadProfileImg", error);
        }
    }

}

const editProfileService = new EditProfileService();
export default editProfileService;