import axios from 'axios';
import { appointmentServiceApi } from '../../../config/config';
import { setupInterceptorsTo } from '../interceptors/axios.interceptos';
const axiosInstance = setupInterceptorsTo(axios.create());

class SettingService {

    async getPreferedUi(fromPartyId: number) {
        const { data } = await axiosInstance.get(`${appointmentServiceApi.baseUrl}/api/v1/settings/prefered-ui/${fromPartyId}`);
        return data;
    }

    async savePreferedUi(fromPartyId: number, preferedUi: 'legacy' | 'v2') {
        const { data } = await axiosInstance.post(`${appointmentServiceApi.baseUrl}/api/v1/settings/prefered-ui/${fromPartyId}`, {
            preferedUi
        });
        return data;
    }
}

const settingService = new SettingService()
export default settingService;