import { useTheme } from '@emotion/react';
// import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from '@mui/icons-material/Tune';
import { AppBar, Box, Checkbox, Collapse, Container, Grid, InputAdornment, Stack, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import React, { RefObject, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';

import {
    LeadingActions,
    SwipeAction,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import HoldableSelect from '../../components/HoldableSelect';
import withHooks from '../../utils/withHooks';
import PracticeSessionCard from '../practice-session/components/PracticeSessionCard';
import { PracticeSesContext } from '../practice-session/contexts/practice-session.context';
import ManageSlotModal from '../slots/components/manage-slots/ManageSlotModal';
import AddAppointmentModal from "./components/add-appointment/AddAppointmentModal";
import AppointmentFilterDrawer from './components/appointment-filter/AppointmentFilterDrawer';
import AppointmentFilterModal from './components/appointment-filter/AppointmentFilterModal';
import AppointmentListCard from './components/appointment-list-card/AppointmentListCard';
import AppointmentListCardSkeleton from './components/appointment-list-card/AppointmentListCardSkeleton';
import AppointmentReschedule from './components/appointment-reschedule';
import { ApptContext, ApptStatusEnum } from './contexts/appointment.context';
import EditAppointmentModal from './components/add-appointment/EditAppointmentModal';
import WhatsappNotifyModal from './components/whatsapp-notify-pateint/WhatsappNotifyModal';
import PrintIcon from '@mui/icons-material/Print';
class Appointment extends React.Component<any, any> {

    state!: any;

    apptFilterModalRef: RefObject<any>;
    addApptModalRef: RefObject<any>;

    refreshDataInterval: any;

    refreshRateMs = 60000;

    constructor(props: any) {
        super(props);

        this.state = {
            isApptRescheduleDrawerOpen: false,
            isFilterDrawerOpen: true,
            openFilterModal: false,
            openAddApptModal: false,
            expandSearchBar: false,
            showPageHeader: true,
            searchStr: '',
            rescheduleAnchorEl: null,
            apptRequest: {
                scheduleOn: '2023-03-31',
                practiceSessionId: '',
                apptStatus: '1',
            },
            showBoxComponent: false
        }

        this.apptFilterModalRef = React.createRef();
        this.addApptModalRef = React.createRef();
        this.setApptRescheduleDrawer = this.setApptRescheduleDrawer.bind(this);

    }

    async componentDidMount(): Promise<void> {
        const { location, apptContext, practiceSesContext } = this.props;

        if (!Object.keys(this.practiceSesContext.selectedPracticeSession).length) {
            await this.practiceSesContext.loadPracticeSessions();
        }

        apptContext.setFilterValues('practiceSessionId', location?.state?.practiceSessionId);
        practiceSesContext.setSeletectedSession(location?.state?.practiceSessionId);

        await this.apptContext.loadAppointments();
        this.setState({ openFilterModal: !this.props.isMd });
        // this.refreshDataInterval = setInterval(this.apptContext.refreshAppointments, this.refreshRateMs);
    }

    get apptContext() {
        return this.props.apptContext;
    }

    get practiceSesContext() {
        return this.props.practiceSesContext;
    }

    async toggleFilterDrawer() {
        this.setState({ ...this.state, isFilterDrawerOpen: !this.state.isFilterDrawerOpen });
    }

    fetchMoreData = () => {
        this.setState({ hasMore: false });
    };

    //isSelectAllAppts = () => this.state.selectedAppointments.length === this.apptContext.appointments.length;

    leadingActions = (index: number, dir: string) => {
        return (
            <LeadingActions>
                <SwipeAction onClick={() => {
                    this.apptContext.selectAppointment(index)
                }}>

                </SwipeAction>
            </LeadingActions>
        )
    };

    setFilterValues = (event: any) => {
        setTimeout(async () => await this.apptContext.loadAppointments(), 1);
    }

    get isClinicOnLeave() {
        return this.apptContext.selectedPracticeSession?.isClinicLeave;
    }

    componentWillUnmount(): void {
        clearInterval(this.refreshDataInterval)
    }


    getAppointments(): any {
        return this.apptContext.appointments.filter((appt: any) => appt?.isFilter === undefined || appt.isFilter === true)
    }

    onClickSelectAllAppt(): any {
        this.apptContext.selectAllAppointments(false);
    }


    setApptRescheduleDrawer(event: React.MouseEvent<HTMLElement>) {
        this.setState({ rescheduleAnchorEl: event ? event.currentTarget : null })
    }
    fetchAppointment=async()=>{
        
        await this.apptContext.loadAppointments();
        
        this.openPrintPage()
        
        
      }
      handleClick = () => {
        this.setState({ showBoxComponent: true });
    }
    handleCancel = () => {
        this.setState({ showBoxComponent: false }); // Close the box
    }
    
     
      openPrintPage = async() => {
     
        const {
            drName,
            AREA,
            orgName,
            consultingFromTimeFrmt,
            consultingToTimeFrmt,
          } = this.props.practiceSesContext.selectedPracticeSession;
          console.log(this.props.practiceSesContext.selectedPracticeSession)
          const { filter } = this.props.apptContext;  
          const date = new Date(filter.scheduleOn);
         const localDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));

          const options: Intl.DateTimeFormatOptions = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: 'UTC', 
          };
          
          const formattedDate = localDate.toLocaleDateString('en-US', options)
        const { appointments } = this.apptContext;
       
      
        const serializedData = JSON.stringify(appointments);
        const blob = new Blob([serializedData], { type: 'text/html' });
        const dataUrl = URL.createObjectURL(blob);
        const htmlContent = `
        <html>
          <head>
          <title>Appointments Print Page</title>
          <style>
          body {
              text-align: center;
            }
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
            }
            th {
              background-color: #f2f2f2;
            }
            
            td:nth-child(2) {
              color: #4682b4;  
            }
          </style>
          </head>
          <body>
          <h1>Dr. ${drName}, ${AREA}</h1>
          <p>
            Showing appointments of ${formattedDate} at ${orgName}, ${AREA} ${consultingFromTimeFrmt} - ${consultingToTimeFrmt}
          </p>
            <table>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Phone</th>
                  <th>Name</th>
                  <th>Reporting Time</th>
                  <th>Gender</th>
                  <th>Ht</th>
                  <th>Wt</th>
                 <th>Requested Time</th>
                </tr>
              </thead>
              <tbody>
                ${appointments.map(
                  (appointment:any,index:any) =>
                    `<tr>
                       <td>${index + 1}</td>
                       <td>${appointment.phoneNumber !== null ? appointment.phoneNumber : ''}</td>
                       <td>${appointment.name !== null ? appointment.name : ''}</td>
                       <td>${appointment.scheduleTime !== null ? appointment.scheduleTime : ''}</td>
                       <td>${appointment.gender !== null ? appointment.gender : ''}</td>
                       <td>${appointment.height !== null ? appointment.height : ''}</td>
                       <td>${appointment.weight !== null ? appointment.weight : ''}</td> 
                       <td>${appointment.requestedOn.split(' ')[1] !== null ? (appointment.requestedOn.split(' ')[1]+' '+ appointment.requestedOn.split(' ')[2]): ''}</td>                 
                     </tr>`
                ).join('')}
              </tbody>
            </table>
            <script>
            window.onload = function() {
              window.print();
            };
          </script>
          </body>
        </html>
      `;
    
   
      const newWindow = window.open(dataUrl, '_blank');
      if (newWindow) {
        newWindow.onload = function () {
            newWindow.document.write(htmlContent);
            newWindow.document.close(); 
            newWindow.print();
        };

      }
      
        URL.revokeObjectURL(dataUrl);
    
      };
     

    render(): React.ReactNode {

        console.log("props",this.props.practiceSesContext.selectedPracticeSession)
        const {isClinicLeave}=this.props.practiceSesContext.selectedPracticeSession;
        const {appointments}=this.props.apptContext;
        const showIconButton=appointments && appointments.length >= 1;

        return <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    pb: 2
                }}
            >

                <AppBar position="sticky" sx={{
                    background: this.props.theme.palette.background.default,
                    boxShadow: 'none !important',
                    color: 'neutral.900',
                }}>
                    <Toolbar>
                        <Grid container>
                            <Grid item xs={12} md={8}>
                                <Stack direction="row" spacing={2}>

                                    <Typography variant={this.props.isMd ? 'h6' : 'h5'} hidden={!this.state.showPageHeader} component="div" sx={{ flexGrow: 1, mt: 'auto', mb: 'auto' }} >
                                        {
                                            this.apptContext.selectMode && <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={this?.apptContext?.selectAllAppts}
                                                onChange={() => this.onClickSelectAllAppt()} />
                                        }
                                        {
                                            !this.apptContext.selectMode && <IconButton
                                                edge="start"
                                                color="inherit"
                                                aria-label="close"
                                                onClick={() => this.setState({ expandSearchBar: !this.state.expandSearchBar })}
                                            >
                                                <Tooltip title="Search by phone number or name">
                                                    <SearchIcon />
                                                </Tooltip>
                                            </IconButton>
                                        }
                                        {
                                            !this.apptContext.selectMode && "Appointments"
                                        }
                                    </Typography>

                                    {/**Header filter section */}
                                    {

                                        <React.Fragment>
                                            <Box component="div" sx={{ flexGrow: 1, ml: '5px !important' }} >
                                                <Collapse orientation={'horizontal'} hidden={!this.state.expandSearchBar} in={this.state.expandSearchBar}
                                                    sx={{
                                                        flexGrow: 1,
                                                        '& .MuiCollapse-wrapper': {
                                                            display: 'block'
                                                        }
                                                    }} timeout="auto"
                                                    addEndListener={() => {
                                                        this.setState({ showPageHeader: !this.state.showPageHeader })
                                                        this.apptContext.setSearchStr('');
                                                        setTimeout(() => {
                                                            this.apptContext.searchAppointment(this.apptContext.searchStr);
                                                        }, 1);
                                                    }}>
                                                    <TextField
                                                        sx={{
                                                            flexGrow: 1,
                                                            width: '100%',
                                                            '& .MuiInputBase-input': {
                                                                height: '1.6rem !important'
                                                            }
                                                        }}
                                                        id="search-by-phonenumber"
                                                        variant="standard"
                                                        value={this.apptContext.searchStr}
                                                        inputRef={input => input && input.focus()}
                                                        placeholder='Search Phone Number or Name'
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            ),

                                                            endAdornment: (
                                                                <IconButton
                                                                    aria-label="search appointment"
                                                                    onClick={() => {
                                                                        this.apptContext.setSearchStr('');
                                                                        this.setState({ expandSearchBar: false })
                                                                    }}
                                                                >
                                                                    <CancelRoundedIcon />
                                                                </IconButton>)
                                                        }}
                                                        onInput={(e: any) => {
                                                            this.apptContext.setSearchStr(e.target.value)
                                                            setTimeout(() => {
                                                                this.apptContext.searchAppointment(this.apptContext.searchStr);
                                                            }, 1);
                                                        }}
                                                    />
                                                </Collapse>
                                            </Box>
                                            {
                                                !this.state.expandSearchBar ? <Box>
                                                    {/*  */}
                                                    {showIconButton &&
                                                        <IconButton
                                                            edge="start"
                                                            color="inherit"
                                                            aria-label="close"
                                                            sx={{ mr: '1rem' }}
                                                            onClick={() =>
                                                                this.apptContext.setNotiModal(true)
                                                            }
                                                        >
                                                            <Tooltip title="WhatsApp">
                                                                <WhatsAppIcon />
                                                            </Tooltip>
                                                        </IconButton>
                                                    }

                                                    {isClinicLeave==="No" &&
                                                   
                                                    <IconButton

                                                        edge="start"
                                                        color="inherit"
                                                        aria-label="close"
                                                        sx={{ mr: '1rem' }}
                                                        onClick={this.fetchAppointment}
                                                    >
                                                        <Tooltip title="Print Appointment">
                                                        <PrintIcon />
                                                        </Tooltip>
                                                    </IconButton>

                                                    }
                                                    {isClinicLeave==="No"&&
                                                    <IconButton

                                                        edge="start"
                                                        color="inherit"
                                                        aria-label="close"
                                                        sx={{ mr: '1rem' }}
                                                        onClick={this.setApptRescheduleDrawer}
                                                    >
                                                        <Tooltip title="Select reschedule option">
                                                            <TuneIcon />
                                                        </Tooltip>
                                                    </IconButton>

                                                 }
    

                                                    <IconButton
                                                        edge="start"
                                                        color="inherit"
                                                        aria-label="close"
                                                        sx={{ mr: '1rem' }}
                                                        onClick={() =>
                                                            this.apptContext.setApptModal(true)
                                                        }
                                                    >
                                                        <Tooltip title="Add appointment">
                                                            <AddCircleOutlineIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <IconButton
                                                        edge="start"
                                                        color="inherit"
                                                        aria-label="close"
                                                        onClick={() => this.setState({ openFilterModal: !this.state.openFilterModal })}
                                                    >
                                                        <FilterListIcon />
                                                    </IconButton>
                                                </Box> : ''
                                            }

                                        </React.Fragment>
                                    }
                                </Stack>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Container sx={{
                    width: '100%',
                    maxWidth: '100% !important'
                }}>
                    <Stack
                        spacing={3}>
                        <Grid container sx={{
                            '& .infinite-scroll-component__outerdiv': {
                                width: '100%'
                            }
                        }}>

                            {
                                this.apptContext.isLoading === false ?
                                    <InfiniteScroll
                                        style={{
                                            width: '100%'
                                        }}
                                        dataLength={this.apptContext.appointments.length}
                                        next={this.fetchMoreData}
                                        hasMore={this.apptContext.hasMore}
                                        loader={<h4 hidden>Loading...</h4>}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b></b>
                                            </p>
                                        }
                                        refreshFunction={() => {
                                            this.apptContext.loadAppointments()
                                        }}
                                        pullDownToRefresh={false}
                                        pullDownToRefreshThreshold={50}
                                        pullDownToRefreshContent={
                                            <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                                        }
                                        releaseToRefreshContent={
                                            <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                                        }
                                    >
                                        {
                                            this.practiceSesContext?.selectedPracticeSession && Object.keys(this.practiceSesContext.selectedPracticeSession).length ?
                                                <Grid item xs={12} md={8}>
                                                    <PracticeSessionCard {...this.practiceSesContext.selectedPracticeSession}
                                                        scheduleOn={this.apptContext.filter.scheduleOn}
                                                        apptContext={this.apptContext}
                                                        practiceSesContext={this.practiceSesContext}
                                                        showCardViewBtn={isClinicLeave !== "Yes"} />
                                                </Grid> : ''
                                        }

                                        {this.apptContext.getAppointments().map((appt: any, index: number) => (
                                            <Grid item xs={12} md={8} key={index}
                                                sx={{
                                                    ...(appt.isSelect && {
                                                        pb: '1rem',
                                                        ...(this.props.isMd && {
                                                            '& .appt-number': {
                                                                background: '#4a689c'
                                                            },
                                                        }),
                                                        '& .MuiPaper-root': {
                                                            background: '#dbdbdb'
                                                        }
                                                    }),
                                                    ...(!appt.isSelect && {
                                                        pb: '1rem',
                                                        ...(appt.status === ApptStatusEnum.CANCELLED && {
                                                            ...(this.props.isMd && {
                                                                '& .appt-number': {
                                                                    background: '#4a689c'
                                                                },
                                                            }),
                                                            '& .MuiPaper-root': {
                                                                background: '#f3b4bdc2'
                                                            }
                                                        })
                                                    })
                                                }}
                                            >
                                                <HoldableSelect
                                                    key={index}
                                                    id={'appointment-card'}
                                                    holdTimeMs={1000}
                                                    onHold={() => {
                                                        console.log('On hold')
                                                        this.apptContext.setIsHoldable(true);
                                                        // this.apptContext.selectAppointment(index)
                                                    }}
                                                >
                                                    {/* <SwipeableList threshold={0.10}>
                                                        <SwipeableListItem
                                                            leadingActions={this.leadingActions(index, '')}
                                                            onSwipeEnd={(dir: string) => this.leadingActions(index, dir)}
                                                        >

                                                            <AppointmentListCard    
                                                                {...appt}
                                                                isMd={this.props.isMd}
                                                                onSelect={(event: any) => {
                                                                    console.log('event-->',event)
                                                                    if (this.apptContext.isHoldable) {
                                                                        this.apptContext.selectAppointment(index)
                                                                    }
                                                                }}
                                                            />
                                                        </SwipeableListItem>
                                                    </SwipeableList> */}
                                                    <AppointmentListCard
                                                        {...appt}
                                                        sessionType={this.props.practiceSesContext.selectedPracticeSession.sessionType}
                                                        apptDetails={appt}
                                                        isMd={this.props.isMd}
                                                        apptContext={this.apptContext}
                                                        item={index}
                                                        onSelect={(event: any) => {
                                                            if (this.apptContext.isHoldable) {
                                                                this.apptContext.selectAppointment(index)
                                                            }
                                                        }}
                                                    />
                                                </HoldableSelect>
                                            </Grid>
                                        ))}
                                    </InfiniteScroll> : ''
                            }

                            {
                                this.apptContext.isLoading ?
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item: any) => (
                                        <Grid item xs={12} md={8} key={item} sx={{ pb: '1rem' }}>
                                            <AppointmentListCardSkeleton />
                                        </Grid>
                                    ))
                                    : ''
                            }
                            {
                                this.apptContext.appointments.length === 0
                                    && !this.apptContext.isLoading
                                    && (this.isClinicOnLeave === 'No' || !this.isClinicOnLeave) && this.practiceSesContext.selectedPracticeSession ?
                                    <Grid item xs={12} md={8} sx={{ pt: '120px', display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            No Data Found
                                        </Typography>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Adjust your filter
                                        </Typography>
                                    </Grid> : ''
                            }
                            {
                                !this.practiceSesContext.practiceSessions.length && !this.practiceSesContext.selectedPracticeSession && <Grid item xs={12}>
                                    <CardContent sx={{
                                        flex: '1 0 auto',
                                        justifyContent: 'center',
                                        padding: '0px 0px 1rem 1rem !important'
                                    }}>
                                        <Typography variant="h6" color="text.secondary" component="div" sx={{ textAlign: 'center' }}>
                                            Clinic is on leave
                                        </Typography>
                                    </CardContent>
                                </Grid>
                            }
                        </Grid>
                        {
                            !this.props.isMd ? <Grid item xs={12} md={8} sx={{ pb: '2rem' }}>
                                <AppointmentFilterDrawer
                                    open={this.state.openFilterModal}
                                    onClose={() => this.setState({ openFilterModal: !this.state.openFilterModal })}
                                    filterValues={this.state.apptRequest}
                                    onFilter={async () => {
                                        await this.apptContext.loadAppointments()
                                    }}
                                />
                            </Grid> : ''
                        }
                    </Stack>
                    {
                        this.props.isMd ? <AppointmentFilterModal
                            // ref={this.apptFilterModalRef}
                            open={this.state.openFilterModal}
                            onClose={() => this.setState({ openFilterModal: !this.state.openFilterModal })}
                            filterValues={this.state.apptRequest}
                            onFilter={async () => {
                                await this.apptContext.loadAppointments()
                            }}
                        /> : ''
                    }

                    <ManageSlotModal
                        open={this.practiceSesContext.isOpenManageSlotModal}
                    />

                    {/**Appt reschedule comp */}
                    <AppointmentReschedule
                        isOpen={Boolean(this.state.rescheduleAnchorEl)}
                        rescheduleAnchorEl={this.state.rescheduleAnchorEl}
                        setApptRescheduleDrawer={this.setApptRescheduleDrawer}
                    />

                    {/**Add appointment comp */}
                    {
                        <AddAppointmentModal
                            // ref={this.addApptModalRef}
                            filterValues={this.state.apptRequest}
                            onFilter={async () => {
                                await this.apptContext.loadAppointments()
                            }}
                        />
                    }

                    {/**Edit appointment user details*/}
                    {
                        <EditAppointmentModal
                            filterValues={this.state.apptRequest}
                            onFilter={async () => {
                                await this.apptContext.loadAppointments()
                            }}
                        />
                    }

                    {/**Whatsapp notify comp */}
                    {

                        <WhatsappNotifyModal
                            // ref={this.addApptModalRef}
                            filterValues={this.state.apptRequest}
                            onFilter={async () => {
                                await this.apptContext.loadAppointments()
                            }}
                        />
                    }


                </Container>
            </Box >
        </>
    }
}


export default withHooks(Appointment, [
    ['theme', useTheme],
    ['isMd', (() => useMediaQuery((theme: any) => theme.breakpoints.down('md')))],
    ['apptContext', useContext, [ApptContext]],
    ['practiceSesContext', useContext, [PracticeSesContext]],
    ['location', useLocation]
])
